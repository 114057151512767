import { hasRole, Role } from '@shared/auth';
import { Box } from 'grommet';
import React, { useMemo } from 'react';

import { useFilters } from '@/components/filters';
import { StateDataTab, StateDataTabs, StateFilterData, useStatesProvider } from '@/features/state-reports/components';
import { ActiveStateView } from '@/features/state-reports/components/active-state-view';
import { ClientInternalViewToggle } from '@/features/state-reports/components/client-internal-view-toggle';
import {
  ClassCarveOutProperty,
  ClassPdlExemptionProperty,
  DrugCarveOutProperty,
  DrugPdlExemptionProperty
} from '@/features/state-reports/components/external-data-providers/mco-carve-outs';
import { ColumnConfigurationGroup } from '@/features/state-reports/types';
import { useCurrentUser } from '@/hooks/use-current-user';
import { useSessionStorage } from '@/hooks/use-session-storage';

const StateReportsPage = () => {
  const { columnGroups } = useStatesProvider();
  const { user } = useCurrentUser();
  const isAdmin = hasRole(user, Role.Administrator);

  const allTabs = useMemo(() => {
    if (Object.keys(columnGroups.systemReports).length === 0) {
      return [{ key: 'Vendor', name: 'Vendor' }];
    }

    return [
      ...columnGroups.customReports.map(customGroup => ({
        key: `${customGroup.id}`,
        name: customGroup.name,
        userCreated: true
      })),
      ...Object.keys(columnGroups.systemReports).map((group) => ({
        key: group as ColumnConfigurationGroup,
        name: columnGroups.systemReports[group as ColumnConfigurationGroup].description
      }))
    ];
  }, [columnGroups]);

  const vendorTab = allTabs.find((tab) => tab.key === 'Vendor');
  // @ts-expect-error TS(2322): Type '{ active: true; key?: string | undefined; na... Remove this comment to see the full error message
  const [tabs, setTabs] = useSessionStorage<StateDataTab[]>('tabs:state-reports', [{ ...vendorTab, active: true }]);

  const [filterData, setFilterData] = useFilters<StateFilterData>('states', {
    statesAndPools: [],
    carvedOutClinicalsProducts: [],
    carvedOutClassifications: [],
    exemptClinicalsProducts: [],
    exemptClassifications: []
  });

  return (
    <Box direction="column" pad={{ bottom: 'medium' }}>
      {isAdmin && (
        <div className="mb-3">
          <ClientInternalViewToggle />
        </div>
      )}
      <StateDataTabs
        tabs={tabs}
        onTabsChange={(tabs) => {
          const openedColumns = new Set<string>();

          for (const tab of tabs) {
            const columns = tab.userCreated
              ? columnGroups.customReports.flatMap(group => `${group.id}` === tab.key ? group.columns.map(column => column.id) : [])
              : columnGroups.systemReports[tab.key].columnConfigurations.map(column => column.propertyName);

            for (const column of columns) {
              openedColumns.add(column);
            }
          }

          const hasMcoCarveOutColumns = openedColumns.has(DrugCarveOutProperty) || openedColumns.has(ClassCarveOutProperty);
          const hasPdlExemptionColumns = openedColumns.has(DrugPdlExemptionProperty) || openedColumns.has(ClassPdlExemptionProperty);

          setTabs(tabs);

          setFilterData((current) => {
            if (hasMcoCarveOutColumns && hasPdlExemptionColumns) {
              return current;
            }

            const newValue = { ...current };
            if (!hasMcoCarveOutColumns) {
              newValue.carvedOutClinicalsProducts = [];
              newValue.carvedOutClassifications = [];
            }
            if (!hasPdlExemptionColumns) {
              newValue.exemptClinicalsProducts = [];
              newValue.exemptClassifications = [];
            }

            return newValue;
          });
        }}
      >
        {(tab) => (
          <ActiveStateView
            allGroups={columnGroups.systemReports}
            allCustomReports={columnGroups.customReports}
            allTabs={allTabs}
            filterData={filterData}
            onFilterChange={setFilterData}
            activeTab={tab}
            openedTabs={tabs}
            setOpenedTabs={setTabs}
          />
        )}
      </StateDataTabs>
    </Box>
  );
};

export default StateReportsPage;
