import { queryOptions, useQuery } from '@tanstack/react-query';
import { isNil } from 'lodash';

import { artiaProtectedApi } from '@/lib/api-client';
import { CoverageTagLookup } from '@/types/coverage-tag-lookup';

export const makeCoverageTagQueryKey = (id?: number | string) => ['coverage-tag', `${id}`];

const getCoverageTag = async (id: number | string): Promise<CoverageTagLookup> => {
  return await artiaProtectedApi.get(`coverage-tags/${id}`);
};

export const getCoverageTagOptions = (id?: number | string) => {
  return queryOptions({
    queryKey: makeCoverageTagQueryKey(id),
    queryFn: () => getCoverageTag(id!),
    enabled: !isNil(id)
  });
};

type UseGetCoverageTagOptions = {
  id?: number | string;
  queryConfig?: Parameters<typeof getCoverageTagOptions>;
};

export const useGetCoverageTag = ({ queryConfig, id }: UseGetCoverageTagOptions = {}) => {
  return useQuery({
    ...getCoverageTagOptions(id),
    ...queryConfig
  });
};

const getCoverageTagLookups = async ({ drugId, defaultOnly = false }: { drugId: number, defaultOnly?: boolean }): Promise<CoverageTagLookup[]> => {
  const queryParams = new URLSearchParams();

  if (!isNil(drugId)) {
    queryParams.set('drugId', drugId.toString());
  }

  if (!isNil(defaultOnly)) {
    queryParams.set('defaultOnly', defaultOnly.toString());
  }

  const HACK_ALL_RPP = 1000000;

  queryParams.set('rpp', HACK_ALL_RPP.toString());
  const { items } = await artiaProtectedApi.get('/lookups/coverage-tags', {
    params: queryParams
  }) as any;

  return items;
};

type UseGetCoverageTagLookupsOptions = {
  drugId: number;
  queryConfig?: Parameters<typeof getCoverageTagLookups>;
}

export const getCoverageTagLookupQueryOptions = ({ drugId }: { drugId: number }) => {
  return queryOptions({
    queryKey: ['coverage-tag-lookups', drugId],
    queryFn: () => getCoverageTagLookups({ drugId })
  });
};

export const useGetCoverageTagLookups = ({ drugId, queryConfig }: UseGetCoverageTagLookupsOptions) => {
  return useQuery({
    ...getCoverageTagLookupQueryOptions({ drugId }),
    ...queryConfig
  });
};


