import { hasRole, ProtectedRoute, Role } from '@shared/auth';
import { useFeatures } from '@shared/feature-flags';
import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { StatesProvider } from '@/features/state-reports/components';
import StateReportsPage from '@/features/state-reports/new/components/state-reports-page';
import { useCurrentUser } from '@/hooks/use-current-user';

const StateReportsLayout = () => {
  const { hasPolicies, user } = useCurrentUser();
  const isClientUser = hasPolicies(['isClientUser']);
  const isArtiaUser = hasPolicies(['isAnyArtiaUser']);
  const isAdmin = hasRole(user,Role.Administrator);

  const { getFeature } = useFeatures();
  const isClientStateReportsEnabled = getFeature('clientStateReports') || isAdmin;

  const [searchParams] = useSearchParams();
  const view = searchParams.get('view');

  const showClientView = view === 'client' || (isClientUser && !isArtiaUser);

  if (showClientView && !isClientStateReportsEnabled) return <Navigate to="/404" />;

  return showClientView ?
    <StateReportsPage /> :
    <ProtectedRoute component={StatesProvider} policies={['isAnyArtiaUser']} />;
};

export default StateReportsLayout;
