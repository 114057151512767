import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

export const Progress = ({
  value,
  max = 1,
}: { value: number; max?: number; } & React.ComponentPropsWithoutRef<'div'>) => {
  const percentage = (value / max) * 100;

  return (
    <div className="h-2 w-full rounded-full bg-gray-200" title={`Progress: ${percentage}%`}>
      <div className="h-2 rounded-full bg-secondary-600 transition-[width] duration-300"
           style={{ width: `${percentage}%` }}></div>
    </div>
  );
};
