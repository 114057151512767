import { useEffect } from 'react';

import { Button } from '@/components-new/button';
import { Dialog, DialogActions, DialogDescription, DialogTitle } from '@/components-new/dialog';
import { useDelayedClose } from '@/hooks/use-delayed-close';
import { StateRef } from '@/types/state';

import { useDeleteDraftCoverageState } from '../api/queries';
import { DraftCoverageSetState } from '../api/types';

/**
 * Prompts the user to confirm the removal of a state from a draft coverage set.
 */
export const RemoveStateDialog = ({
  stateToRemove,
  draftId,
  onClose
}: {
  stateToRemove: StateRef | undefined;
  draftId: number;
  draftStates: DraftCoverageSetState[],
  onClose: () => void;
}) => {
  const { isOpen, handleOpen, handleClose } = useDelayedClose(!!stateToRemove, onClose);

  const { mutate: removeDraftCoverageState, isPending: isRemovingDraftCoverageState } = useDeleteDraftCoverageState();


  useEffect(() => {
    if (stateToRemove) {
      handleOpen();
    }
  }, [stateToRemove, handleOpen]);

  const closeDialog = () => {
    handleClose();
  };

  const handleConfirmRemoveState = () => {
    if (!stateToRemove) return;

    const data = {
      draftCoverageSetId: draftId,
      stateCode: stateToRemove.code
    };

    removeDraftCoverageState(
      data,
      {
        onSuccess: () => {
          // TODO: find correct state tab to focus on
          handleClose();
        }
      }
    );
  };

  return (
    <Dialog onClose={closeDialog} open={isOpen}>
      <DialogTitle>Remove State</DialogTitle>
      <DialogDescription>Are you sure you want to remove this state from the draft? All coverage information for this state will be removed.</DialogDescription>
      <DialogActions>
        <Button outline onClick={closeDialog} disabled={isRemovingDraftCoverageState}>Cancel</Button>
        <Button color="red" onClick={handleConfirmRemoveState} disabled={isRemovingDraftCoverageState}>Remove</Button>
      </DialogActions>
    </Dialog>
  );
};
