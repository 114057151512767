import { closestCenter, DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import * as Headless from '@headlessui/react';
import * as reactTable from '@tanstack/react-table';
import { ColumnOrderState } from '@tanstack/react-table';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { HiBars2 } from 'react-icons/hi2';
import { undefined } from 'zod';

import { Button } from '@/components-new/button';
import { Card } from '@/components-new/card';
import { DataTableInstance } from '@/components-new/data-table';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '@/components-new/dialog';
import { Label } from '@/components-new/fieldset';
import { Switch } from '@/components-new/switch';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components-new/tooltip';
import { parseCssVarName } from '@/utils/parsing';

type ColumnConfigDialogProps<TData> = {
  /**
   * Whether the dialog is open for the user to edit configurations.
   */
  open: boolean;
  /**
   * Instance of the table the columns are configured in.
   */
  table: DataTableInstance<TData>
  /**
   * Callback when the dialog is closed.
   */
  onClose: (value: boolean) => void;
}

/**
 * A dialog for configuring column order and visibility in a table.
 *
 * Allows users to drag and drop columns to reorder them, toggle their visibility on or off,
 * and reset columns to their original configuration defined in the initial state.
 *
 * This dialog provides controls for saving changes or canceling the operation,
 * and includes contextual actions such as hiding all columns, showing all columns, and resetting the order.
 */
export const ColumnConfigDialog = <TData,>({
  open,
  table,
  onClose
}: ColumnConfigDialogProps<TData>) => {
  const [workingColumnOrder, setWorkingColumnOrder] = useState<reactTable.ColumnOrderState>([]);
  const [workingColumnVisibility, setWorkingColumnVisibility] = useState<reactTable.VisibilityState>({});

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 5 } })
  );

  const {
    getAllColumns,
    getColumn,
    getState,
    setColumnVisibility,
    setColumnOrder,
    initialState,
  } = table;

  const {
    columnOrder,
    columnVisibility,
  } = getState();

  useEffect(() => {
    if (!open) return;

    resetColumnOrder(columnOrder);
    setWorkingColumnVisibility(columnVisibility);

  }, [columnOrder, columnVisibility, getAllColumns, open]);


  /**
   * Computes the initial column order by appending any missing column IDs to the existing column order.
   *
   * @param columnOrder - The current column order state.
   * If not provided, defaults to the column order defined in the initial state.
   * @returns A finalized array of column IDs where missing columns are appended to the existing order.
   */
  const getInitialColumnOrder = (columnOrder: ColumnOrderState = initialState.columnOrder) => {
    const columnOrderSet = new Set(columnOrder);
    const remainingColumns = getAllColumns().map(column => column.id)
      .filter(columnId => !columnOrderSet.has(columnId));

    return [...columnOrder, ...remainingColumns];
  };

  /**
   * Whether all columns can be hidden based on their visibility status.
   */
  const canHideAll = Object.values(workingColumnVisibility).every(isVisible => isVisible);

  /**
   * Whether all columns can be shown based on their visibility status.
   */
  const canShowAll = Object.values(workingColumnVisibility).some(isVisible => !isVisible);

  /**
   * Whether all columns can be reset to their original order.
   */
  const canResetColumnOrder = !isEqual(getInitialColumnOrder(), workingColumnOrder);


  /**
   * Handles the drag-and-drop end event for reordering items in a list.
   *
   * If the identifier of the dragged item (`active.id`) is different from the identifier
   * of the drop target (`over.id`), the function calculates the old and new indices of the
   * items within `workingColumnOrder`.
   * It then reorders `workingColumnOrder` by moving
   * the dragged item to its new position.
   */
  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      // Reorder items
      const oldIndex = workingColumnOrder.findIndex((id) => id === active.id);
      const newIndex = workingColumnOrder.findIndex((id) => id === over.id);
      setWorkingColumnOrder((items: any[]) => arrayMove(items, oldIndex, newIndex));
    }
  };

  /**
   * Handles the save operation for column configurations and closes the associated modal or interface.
   *
   * Updates the column order and visibility based on the current working configurations,
   * and invokes the closure function to finalize the save operation.
   */
  const handleSave = () => {
    setColumnOrder(workingColumnOrder);
    setColumnVisibility(workingColumnVisibility);
    onClose(false);
  };

  /**
   * Handles the click event to hide all columns that can have their visibility changed.
   */
  const handleHideAllClick = () => {
    setWorkingColumnVisibility(workingColumnOrder.reduce((acc, id) => {
      const canChangeVisibility = !getColumn(id)?.getIsPinned();

      if (!canChangeVisibility) return acc;

      return { ...acc, [id]: false };
    }, {}));
  };

  /**
   * Handles the click event to show all columns that can have their visibility changed.
   */
  const handleShowAllClick = () => {
    setWorkingColumnVisibility(workingColumnOrder.reduce((acc, id) => {
      const canChangeVisibility = !getColumn(id)?.getIsPinned();

      if (!canChangeVisibility) return acc;

      return { ...acc, [id]: true };
    }, {}));
  };

  /**
   * Resets the working column order to its initial state.
   *
   * @param {ColumnOrderState} initialOrder - The initial configuration of column order to reset to.
   */
  const resetColumnOrder = (initialOrder: ColumnOrderState) => {
    setWorkingColumnOrder(getInitialColumnOrder(initialOrder));
  };

  /**
   * Handles the click event to reset columns to their original state.
   */
  const handleResetOrderClick = () => {
    const { columnOrder } = initialState;
    resetColumnOrder(columnOrder);
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Column configurations</DialogTitle>
      <DialogDescription>
        Toggle columns that are shown in the table or drag and drop columns in the order you want to see them.
      </DialogDescription>
      <DialogBody>
        <div className="mb-2 flex justify-end gap-2">
          <Button plain onClick={handleHideAllClick} disabled={!canHideAll}>
            Hide all
          </Button>
          <Button plain onClick={handleResetOrderClick} disabled={!canResetColumnOrder}>
            Reset order
          </Button>
          <Button plain onClick={handleShowAllClick} disabled={!canShowAll}>
            Show all
          </Button>
        </div>
        <div className="flex flex-col gap-2">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={workingColumnOrder} strategy={verticalListSortingStrategy}>
              {workingColumnOrder.map(id => (
                <ColumnConfigCard
                  isVisible={workingColumnVisibility[id] ?? true}
                  onVisibilityChange={(key, value) => setWorkingColumnVisibility({ ...workingColumnVisibility, [key]: value })}
                  column={getColumn(id)!}
                  key={id} />
              ))}
            </SortableContext>
          </DndContext>
        </div>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={() => onClose(false)}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

/**
 * Card for configuring a column's display properties within a table,
 * including visibility toggling and reordering if applicable.
 */
const ColumnConfigCard = <TData,>({
  column,
  onVisibilityChange,
  isVisible,
}: { onVisibilityChange: (key: string, value: boolean) => void, isVisible: boolean, column: reactTable.Column<TData, unknown> }) => {
  const { columnDef } = column;
  const isReorderDisabled = Boolean(column.getIsPinned());

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: column.id, disabled: isReorderDisabled });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const fieldName = parseCssVarName(column.id);

  return (
    <div
      ref={isReorderDisabled ? undefined as any : setNodeRef}
      style={style}
    >
      <Card outlined dense>
        <div className="flex justify-between">
          <Headless.Field className="flex items-center gap-4" disabled={isReorderDisabled}>
            <Switch
              name={fieldName}
              value="visible"
              checked={isVisible}
              color="secondary"
              onChange={(value) => onVisibilityChange(fieldName, value)}
            />
            <Label>{columnDef.header as any ?? ''}</Label>
          </Headless.Field>
          <Tooltip disabled={isReorderDisabled}>
            <TooltipTrigger asChild>
              <Button
                {...attributes}
                {...listeners}
                plain
                className={clsx(!isReorderDisabled && 'cursor-grab active:cursor-grabbing')}
                disabled={isReorderDisabled}
              >
                <HiBars2 />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Drag to reorder</TooltipContent>
          </Tooltip>
        </div>
      </Card>
    </div>
  );
};
