import { useQuery } from '@tanstack/react-query';

import { useLookupsService } from '@/hooks/use-lookups-service';
import { Lookup } from '@/types/lookup';

export const useGetPaTypeLookups = () => {
  const { getPaTypeLookups } = useLookupsService();

  return useQuery({
    queryKey: ['paTypeLookups'],
    queryFn: async () => await getPaTypeLookups() as unknown as Promise<Lookup[]>,
  });
};
