import { parse } from '@tinyhttp/content-disposition';
import Axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';

import { env } from '@/config/env';
import { deleteUserFromLocalStorage, getUserFromLocalStorage } from '@/utils/auth';

/**
 * Applies an authentication interceptor to the Axios request configuration.
 */
const authRequestInterceptor = (config: InternalAxiosRequestConfig) => {
  const user = getUserFromLocalStorage();
  const token = user?.access_token;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

export const artiaProtectedApi = Axios.create({
  baseURL: env.BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

artiaProtectedApi.interceptors.request.use(authRequestInterceptor);
artiaProtectedApi.interceptors.response.use(
  (response) => {
    if (response.headers['content-disposition']) {
      const cd = parse(response.headers['content-disposition']);
      const filename = cd.parameters['filename'];
      return { filename, data: response.data };
    }

    return response.data;
  },
  error => {
    if (error.response?.status === 401) {
      deleteUserFromLocalStorage();
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

