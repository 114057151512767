import { zodResolver } from '@hookform/resolvers/zod';
import { sortBy } from 'lodash';
import { useEffect, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { useGetStateLookups } from '@/api/use-states';
import { Button } from '@/components-new/button';
import { Dialog, DialogActions, DialogDescription, DialogTitle } from '@/components-new/dialog';
import { Field, Label } from '@/components-new/fieldset';
import { Listbox, ListboxOption } from '@/components-new/listbox';
import { useDelayedClose } from '@/hooks/use-delayed-close';
import { CodeBasedLookup } from '@/types/lookup';

import { AddDraftCoverageStateInput, addDraftCoverageStateInputSchema } from '../api/api';
import { useAddDraftCoverageState } from '../api/queries';
import { DraftCoverageSetState } from '../api/types';

/**
 * Allows the user to add additional states to a draft coverage set.
 *
 * If all states are already added, the dialog will display a message indicating that all states are already added.
 */
export const AddStateDialog = ({
  isAddingState,
  draftId,
  draftStates,
  onClose
}: {
  isAddingState: boolean;
  draftId: number;
  draftStates: DraftCoverageSetState[],
  onClose: () => void;
}) => {
  const { isOpen, handleOpen, handleClose } = useDelayedClose(isAddingState, onClose);

  const { data: statesLookups, isLoading: isLoadingStates } = useGetStateLookups();

  const { mutate: addState, isPending: isAddStatePending } = useAddDraftCoverageState();

  const availableStateOptions = useMemo(() => {
    return sortBy(
      statesLookups?.filter(state => !draftStates.find(({ state: draftState }) => draftState.code === state.code)) ?? [],
      (state: CodeBasedLookup) => state.label
    );
  }, [statesLookups, draftStates]);

  const allStatesAdded = availableStateOptions.length === 0 && ! isLoadingStates;

  const { handleSubmit, control, reset } = useForm<AddDraftCoverageStateInput>({
    resolver: zodResolver(addDraftCoverageStateInputSchema),
    defaultValues: {
      state: '',
      draftCoverageSetId: draftId,
    }
  });

  useEffect(() => {
    if (isAddingState) {
      handleOpen();
    }
  }, [isAddingState, handleOpen]);

  const handleAddState: SubmitHandler<AddDraftCoverageStateInput> = async (data) => {
    addState(
      data,
      {
        onSuccess: () => {
          closeDialog();
        }
      }
    );
  };

  const closeDialog = () => {
    reset();
    handleClose();
  };

  return (
    <Dialog onClose={closeDialog} open={isOpen}>
        <form onSubmit={handleSubmit(handleAddState)}>
          <DialogTitle>Add State</DialogTitle>
          <DialogDescription>
            {allStatesAdded
              ? 'The draft already contains all available states.'
              : 'Select a state to add coverage to the draft. If the state is not listed, it is likely already added to the draft.'
            }
          </DialogDescription>
          {!allStatesAdded && (
            <Controller
              control={control}
              name="state"
              render={({ field: { onChange, value } }) => (
                <Field disabled={isLoadingStates}>
                  <Label>State</Label>
                  <Listbox value={value} onChange={onChange}>
                    {availableStateOptions.map((state) => (
                      <ListboxOption key={state.code} value={state.code}>
                        {state.label}
                      </ListboxOption>
                    ))}
                  </Listbox>
                </Field>
              )}
            />
          )}
          <DialogActions>
            <Button outline onClick={closeDialog}>Cancel</Button>
            {allStatesAdded
              ? (<Button disabled={isAddStatePending} onClick={closeDialog}>Ok</Button>)
              : (<Button type="submit" disabled={isLoadingStates || isAddStatePending}>Add</Button>)
            }
          </DialogActions>
        </form>
      </Dialog>
  );
};
