import { useFeatures } from '@shared/feature-flags';
import { LiaHighlighterSolid, LiaPencilAltSolid, LiaStampSolid } from 'react-icons/lia';
import { Outlet } from 'react-router';

import { Breadcrumbs } from '@/components-new/breadcrumbs';
import { Page } from '@/components-new/page';
import { PageTitleRow } from '@/components-new/page-title-row';
import { Tab, TabGroup, TabList, TabPanels } from '@/components-new/tab';
import { useCurrentUser } from '@/hooks/use-current-user';
import { useTabs } from '@/hooks/use-tabs';
import { insertIf } from '@/utils/arrays';

const DrugCoverageIndexLayout = () => {
  const { hasPolicies } = useCurrentUser();
  const canManageDrugs = hasPolicies(['canManageDrugs']);
  const { getFeature } = useFeatures();
  const isDraftCoverageSupported = getFeature('draftCoverageWorkflow');
  const isCoverageNeedsReviewSupported = getFeature('coverageNeedsReview');

  const canManageDrafts = canManageDrugs && isDraftCoverageSupported;

  const {
    tabs,
    activeTabIndex,
    onChange
  } = useTabs([
    {
      label: (
        <>
          <LiaStampSolid/>
          Active
        </>
      ),
      href: '/drug-coverage'
    },
    ...insertIf(canManageDrafts,
      {
        label: (
          <>
            <LiaPencilAltSolid/>
            Drafts
          </>
        ),
        href: '/drug-coverage/drafts'
      }
    ),
    ...insertIf(isCoverageNeedsReviewSupported,
      {
        label: (
          <>
            <LiaHighlighterSolid/>
            Needs Review
          </>
        ),
        href: '/drug-coverage/needs-review',
        disabled: true
      }
    )
  ]);

  return (
    <Page
      title="Drug Coverage"
      header={({ title }) => (
        <>
          <Breadcrumbs
            breadcrumbs={[
              { label: 'Drug Coverage', url: '/drug-coverage' },
            ]}
          />
          <PageTitleRow
            title={title}
            subtitle="States drug coverage management."
          />
        </>
      )}
    >
      <TabGroup selectedIndex={activeTabIndex} onChange={onChange}>
        <TabList>
          {tabs.map((tab) => (
            <Tab key={tab.href} disabled={tab.disabled}>
              {tab.label}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          <div className="mt-2">
            <Outlet/>
          </div>
        </TabPanels>
      </TabGroup>
    </Page>
  );
};

export default DrugCoverageIndexLayout;
