import { isEmpty, isNil } from 'lodash';
import { useCallback, useMemo } from 'react';

import { useControllable } from '@/hooks/use-controllable';

export type FilterSchema = { [key: string]: unknown };
export type FilterSectionKey<TFilterSchema extends FilterSchema> = keyof TFilterSchema;
export type FilterValue<TFilterSchema extends FilterSchema> = Partial<TFilterSchema> | undefined;
export type FilterItemValue<TFilterSchema extends FilterSchema> = {
  key: FilterSectionKey<TFilterSchema>;
  value: TFilterSchema[FilterSectionKey<TFilterSchema>];
}
export type FilterSectionTemplate<TProps extends object> =
  | string
  | ((props: TProps) => any)

export type FilterSectionDefinition<TFilterSchema extends FilterSchema, TKey extends FilterSectionKey<TFilterSchema>> = {
  id: TKey;
  /**
   * Renders the section header.
   */
  sectionLabel: FilterSectionTemplate<FilterSectionContext<TFilterSchema, TKey>>;
  /**
   * Renders active filter value display.
   */
  filterLabel: FilterSectionTemplate<FilterSectionContext<TFilterSchema, TKey>>;
  /**
   * Renders the filter's input interface.
   */
  sectionContent: FilterSectionTemplate<FilterSectionContext<TFilterSchema, TKey>>;
};

export type FilterSectionDefinitions<TFilterSchema extends FilterSchema> = {
  [TKey in FilterSectionKey<TFilterSchema>]: FilterSectionDefinition<TFilterSchema, TKey>
}[FilterSectionKey<TFilterSchema>]


export type UseFilterOptions<TFilterSchema extends FilterSchema> = {
  /**
   * The array of filter definitions for the filter.
   */
  filterSections: FilterSectionDefinitions<TFilterSchema>[],
  /**
   * The key of the currently selected section.
   */
  selectedSection?: keyof TFilterSchema;
  /**
   * The key of the section that should be selected initially.
   * Used when the selected section is uncontrolled, but you need to set the initial value.
   *
   * Don't use if you are using the controlled `selectedSection`.
   */
  initialSelectedSection?: keyof TFilterSchema;
  /**
   * Callback function triggered when the selected section changes.
   *
   * @param value - The key of the new section selected, corresponding to the FilterSchema.
   */
  onSelectedSectionChange?: (value?: keyof TFilterSchema) => void;
  /**
   * The current filter value.
   */
  value?: Partial<TFilterSchema>;
  /**
   * Callback function triggered when the filter value changes.
   *
   * @param value - The new value of the filter.
   */
  onValueChange?: (value?: TFilterSchema | Partial<TFilterSchema>) => void;
  /**
   * The initial value of the filter.
   * Used when the value is uncontrolled, but you need to set the initial value.
   *
   * Don't use if you are using the controlled `value`.
   */
  initialValue?: Partial<TFilterSchema>;
}

type ResolvedFilterSection<TFilterSchema extends FilterSchema, TKey extends FilterSectionKey<TFilterSchema>> = {
  id: TKey;
  sectionLabel: FilterSectionTemplate<FilterSectionContext<TFilterSchema, TKey>>;
  filterLabel: FilterSectionTemplate<FilterSectionContext<TFilterSchema, TKey>>;
  sectionContent: FilterSectionTemplate<FilterSectionContext<TFilterSchema, TKey>>;
  getContext: () => FilterSectionContext<TFilterSchema, TKey>;
}

export type FilterSectionContext<TFilterSchema extends FilterSchema, TKey extends keyof TFilterSchema> = {
  /**
   * Reference to the original section definition.
   */
  section: FilterSectionDefinitions<TFilterSchema>;
  /**
   * Current value of the section.
   */
  value: TFilterSchema[TKey] | undefined;
  /**
   * Method for updating the value of the section.
   *
   * @param value - New value to set.
   */
  setValue: (value: TFilterSchema[keyof TFilterSchema]) => void;
  /**
   * Removes the value of the section.
   */
  removeValue: () => void;
}

export type FilterSectionHelper<TFilterSchema extends FilterSchema> = {
  defineSection: <TKey extends keyof TFilterSchema>(
    key: TKey,
    definition: Omit<FilterSectionDefinition<TFilterSchema, TKey>, 'id'>
  ) => FilterSectionDefinition<TFilterSchema, TKey>;
}

export const createFilterSectionHelper = <TFilterSchema extends FilterSchema>(): FilterSectionHelper<TFilterSchema> => {
  return {
    defineSection: (key, definition) => {
      return {
        id: key,
        ...definition
      };
    }
  };
};

/**
 * A hook that manages filtering logic for a given filter schema.
 *
 * @example
 * ```tsx
 * const filter = useFilter({
 *   filterSections: [dateSection, statusSection],
 *   initialValue: {
 *     date: new Date(),
 *     status: 'active'
 *   }
 * });
 * ```
 */
export const useFilter = <TFilterSchema extends FilterSchema>({
  filterSections,
  selectedSection: controlledSelectedSection,
  initialSelectedSection: controlledInitialSelectedSection,
  onSelectedSectionChange: controlledOnSelectedSectionChange,
  value: controlledValue,
  onValueChange: controlledOnValueChange,
  initialValue: controlledInitialValue,
}: UseFilterOptions<TFilterSchema>) => {
  const [selectedSection, setSelectedSection] = useControllable<FilterSectionKey<TFilterSchema> | undefined>(
    controlledSelectedSection,
    controlledOnSelectedSectionChange,
    controlledInitialSelectedSection,
  );

  const [value, setValue] = useControllable<FilterValue<TFilterSchema>>(
    controlledValue,
    controlledOnValueChange,
    controlledInitialValue
  );

  const getFilterValue = useCallback(
    <TKey extends keyof TFilterSchema>(sectionKey: TKey): TFilterSchema[TKey] | undefined => {
      return value?.[sectionKey] as TFilterSchema[TKey] | undefined;
    }, [value]);

  const setFilterValue = useCallback(
    <TKey extends FilterSectionKey<TFilterSchema>>(sectionKey: TKey, newValue: TFilterSchema[TKey]) => {
      setValue({
        ...value,
        [sectionKey]: newValue
      } as Partial<TFilterSchema>);
    },
    [value, setValue]
  );

  const removeFilterValue = useCallback(
    <TKey extends keyof TFilterSchema>(sectionKey: TKey) => {
      if (!value || !(sectionKey in value)) return;

      const { [sectionKey]: _, ...rest } = value;
      setValue(rest as Partial<TFilterSchema>);
    }, [value, setValue]);

  const values = useMemo(() => {
    return Object.keys(value || {})
      .flatMap(key => ({ key: key, value: value?.[key] as TFilterSchema[keyof TFilterSchema] } as FilterItemValue<TFilterSchema>))
      .filter(item => {
        const empty = isNil(item.value) || isEmpty(item.value);
        return !empty;
      });
  }, [value]);

  const makeSectionContext = useCallback(
    <TKey extends keyof TFilterSchema>(
      key: TKey,
      section: FilterSectionDefinition<TFilterSchema, TKey>
    ) => {
      return {
        section,
        value: getFilterValue(key),
        removeValue: () => removeFilterValue(key),
        setValue: (value: TFilterSchema[TKey]) => setFilterValue(key, value)
      };
    }, [getFilterValue, removeFilterValue, setFilterValue]
  );

  const getSectionContext = useCallback(
    <TKey extends keyof TFilterSchema>(sectionKey: TKey) => {
      const section = filterSections.find(section => section.id === sectionKey);

      if (!section) {
        throw new Error(`${String(sectionKey)} not defined in filter sections.`);
      }

      return {
        section,
        value: getFilterValue(sectionKey),
        removeValue: () => removeFilterValue(sectionKey),
        setValue: (value: TFilterSchema[keyof TFilterSchema]) => setFilterValue(sectionKey, value)
      };
    }, [filterSections, getFilterValue, removeFilterValue, setFilterValue]);

  const resolvedFilterSections = useMemo(() => {
    return filterSections.map(section => {
      const key = section.id;
      return {
        id: key,
        sectionLabel: section.sectionLabel,
        sectionContent: section.sectionContent,
        filterLabel: section.filterLabel,
        getContext: () => makeSectionContext(key, section),
      };
    });
  }, [filterSections, makeSectionContext]);

  const getSection = useCallback(
    <TKey extends keyof TFilterSchema>(sectionKey: TKey) => {
      return resolvedFilterSections.find(section => section.id === sectionKey) as ResolvedFilterSection<TFilterSchema, TKey> | undefined;
    }, [resolvedFilterSections]);

  const clearFilterValue = useCallback(() => {
    setValue(undefined);
  }, [setValue]);

  return {
    resolvedFilterSections,
    filterSections,
    selectedSection,
    setSelectedSection,
    getFilterValue,
    setFilterValue,
    removeFilterValue,
    getSectionContext,
    getSection,
    value,
    values,
    clearFilterValue
  };
};
