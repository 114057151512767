import * as Headless from '@headlessui/react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';

import { Button } from '@/components-new/button';

import { DraftCoverageSet, DraftCoverageSetState } from '../api/types';
import { AddStateDialog } from './add-state-dialog';

/**
 * Navigation component for the state coverage wizard.
 */
export const StateCoverageWizardNav = ({ draft, states }: { draft: DraftCoverageSet; states: DraftCoverageSetState[] }) => {
  const [isAddingState, setIsAddingState] = useState(false);

  const handleAddStateClick = () => {
    setIsAddingState(true);
  };

  const handleCloseIsAddingState = () => {
    setIsAddingState(false);
  };

  return (
    <>
      <Headless.TabList className="flex flex-col space-y-2">
        <div className="flex items-center justify-between gap-4">
          <span className="font-bold">States</span>
          <Button
            plain
            color="secondary"
            onClick={handleAddStateClick}
            className="whitespace-nowrap"
          >
            Add State
          </Button>
        </div>
        <div>
          <AnimatePresence>
            {states.map(({ state, isComplete }) => (
              <Headless.Tab
                as={motion.button}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3, type: 'tween', ease: 'easeInOut' }}
                exit={{ opacity: 0 }}
                key={state.code}
                className={clsx(
                  'group flex w-full cursor-pointer items-center justify-between gap-4 whitespace-nowrap rounded-md px-3 py-2',
                  'hover:bg-gray-50',
                  'data-[selected]:bg-secondary-50 data-[selected]:text-secondary-700',
                  'outline-secondary-600'
                )}
              >
                {state.name}
                {isComplete && <FaCheckCircle className="text-green-500"/>}
              </Headless.Tab>
            ))}
          </AnimatePresence>
          {!isEmpty(states) && (
            <Button
              disabled={isEmpty(states)}
              outline
              className="mt-4"
              href={`/drug-coverage/drafts/${draft.id}/review`}>
              Review and Finalize
            </Button>
          )}
        </div>
      </Headless.TabList>
      <AddStateDialog
        draftId={draft.id}
        draftStates={states}
        isAddingState={isAddingState}
        onClose={handleCloseIsAddingState}
      />
    </>
  );
};
