import { isNil } from 'lodash';

import { Button } from '@/components-new/button';
import { Dialog, DialogActions, DialogDescription, DialogTitle } from '@/components-new/dialog';

/**
 * Prompts the user to confirm the removal of a coverage.
 */
export const RemoveCoverageDialog = ({
  coverageToRemove,
  onConfirm
}: {
  coverageToRemove: number| undefined;
  onConfirm: (confirmed: boolean) => void;
}) => {
  const isOpen = !isNil(coverageToRemove);

  const handleDecline = () => {
    onConfirm(false);
  };

  const handleConfirm = () => {
    onConfirm(true);
  };

  return (
    <Dialog onClose={handleDecline} open={isOpen}>
        <DialogTitle>Remove Coverage</DialogTitle>
        <DialogDescription>Are you sure you want to remove this coverage?</DialogDescription>
        <DialogActions>
          <Button outline onClick={handleDecline}>Cancel</Button>
          <Button color="red" onClick={handleConfirm}>Remove</Button>
        </DialogActions>
      </Dialog>
  );
};
