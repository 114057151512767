import { FormField } from 'grommet';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import styled from 'styled-components';

import { DateTextInput, LookupSelect, TimeTextInput } from '@/components/form-controls';
import { getMinutesFromTime } from '@/utils/time';

import { useBoardMeetingsProvider } from './board-meetings-provider';
import { BoardMeetingFormData } from './types';

type MeetingTimeSectionProps = {
  id: string;
  control: Control<BoardMeetingFormData>
};

/**
 * Contains the fields that indicate when a Board Meeting occurs.
 */
export const MeetingTimeSection = ({ id, control }: MeetingTimeSectionProps) => {
  const { supportedTimezones } = useBoardMeetingsProvider();

  return (
    <SecondSection>
      <Controller
        name="startDate"
        control={control}
        rules={{
          required: 'Start Date is a required field',
        }}
        render={({ field: { ref, value, onChange, onBlur, name }, fieldState: { error } }) => (
          <FormField
            name={name}
            htmlFor={`${id}-${name}`}
            label="Date"
            error={error?.message}
            margin="none"
          >
            <DateTextInput
              id={`${id}-${name}`}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
            />
          </FormField>
        )}
      />
      <Controller
        name="startTime"
        rules={{
          required: 'Start Time is a required field',
          deps: ['endTime'],
          validate: {
            beforeEndDate: (startTime, { endTime }) => {
              if (!startTime && !endTime) return true;

              const startTimeMinutes = getMinutesFromTime(startTime);
              const endTimeMinutes = getMinutesFromTime(endTime);

              if (startTimeMinutes && endTimeMinutes && startTimeMinutes > endTimeMinutes) {
                return 'Start Time must be before End Time.';
              }

              return true;
            }
          }
        }}
        control={control}
        render={({ field: { ref, value, onChange, onBlur, name }, fieldState: { error } }) => (
          <FormField
            name={name}
            htmlFor={`${id}-${name}`}
            label="Start Time"
            error={error?.message}
            margin="none"
          >
            <TimeTextInput
              id={`${id}-${name}`}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
            />
          </FormField>
        )}
      />
      <Controller
        name="endTime"
        rules={{
          deps: ['startTime'],
          validate: {
            beforeEndDate: (endTime, { startTime }) => {
              if (!startTime && !endTime) return true;

              const startTimeMinutes = getMinutesFromTime(startTime);
              const endTimeMinutes = getMinutesFromTime(endTime);

              if (endTime && startTimeMinutes && endTimeMinutes && startTimeMinutes > endTimeMinutes) {
                return 'End Time must be after Start Time.';
              }

              return true;
            }
          }
        }}
        control={control}
        render={({ field: { ref, value, onChange, onBlur, name }, fieldState: { error } }) => (
          <FormField
            name={name}
            htmlFor={`${id}-${name}`}
            label="End Time"
            error={error?.message}
            margin="none"
          >
            <TimeTextInput
              id={`${id}-${name}`}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
            />
          </FormField>
        )}
      />
      <Controller
        name="ianaTimeZone"
        rules={{
          required: 'Time Zone is a required field'
        }}
        control={control}
        render={({ field: { ref, value, onChange, onBlur, name }, fieldState: { error } }) => (
          <LookupSelect
            multiple={false}
            options={supportedTimezones}
            required
            name={name}
            placeholder="Select a Time Zone"
            label="Time Zone"
            // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
            value={value}
            error={error?.message}
            onBlur={onBlur}
            onChange={onChange}
            ref={ref}
          />
        )}
      />
    </SecondSection>
  );
};

const SecondSection = styled.div`
  display: grid;
  grid-template-columns: 3fr 1.5fr 1.5fr 2fr;
  grid-template-rows: max-content;
  gap: 1rem;
  background: ${({ theme }) => theme.global.colors['light-3']};
  padding: 1.5rem;
`;
