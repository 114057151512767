import React from 'react';

/**
 * A utility function to merge multiple React refs into a single ref callback.
 * It ensures that the value of the referenced element or object is synchronized
 * across all provided refs, whether they are callback refs or mutable ref objects.
 */
export const mergeRefs = <T = any>(...refs: Array<React.Ref<T> | undefined>) => {
  return (value: T | null) => {
    refs.forEach(ref => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(value);
      } else {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
};

/**
 * Merges multiple React refs into a single ref, allowing multiple references to the same node or component.
 */
export const useMergeRefs = <T = any>(...refs: Array<React.Ref<T> | undefined>) => {
  return React.useMemo(() => mergeRefs(...refs), [refs]);
};
