export enum PdlStatus {
  BLANK = 'Blank',
  PREFERRED = 'Preferred',
  NON_PREFERRED = 'NonPreferred',
  NON_PDL = 'NonPdl',
  NO_STATE_PDL = 'NoStatePdl',
  PREFERRED_NOT_LISTED = 'PreferredNotListed',
  NON_PREFERRED_NOT_LISTED = 'NonPreferredNotListed'
}

export type PdlStatusAppliesTo = {
  pdlStatus: PdlStatus,
  appliesTo: string
};

export const displayPdlStatus = (pdlStatus?: PdlStatus): string => {
    switch (pdlStatus) {
      case PdlStatus.BLANK:
        return 'Blank';
      case PdlStatus.PREFERRED:
        return 'Preferred';
      case PdlStatus.NON_PREFERRED:
        return 'Non-Preferred';
      case PdlStatus.NON_PDL:
        return 'Non-PDL';
      case PdlStatus.NO_STATE_PDL:
        return 'No State PDL';
      case PdlStatus.PREFERRED_NOT_LISTED:
        return 'Preferred (Not Listed)';
      case PdlStatus.NON_PREFERRED_NOT_LISTED:
        return 'Non-Preferred (Not Listed)';
     default:
       return 'Blank';
    }
};

export const pdlStatusToCode = (pdlStatus?: string | null) => {
  switch (pdlStatus) {
    case 'Blank':
      return PdlStatus.BLANK;
    case 'Preferred':
      return PdlStatus.PREFERRED;
    case 'Non-Preferred':
      return PdlStatus.NON_PREFERRED;
    case 'Non-PDL':
      return PdlStatus.NON_PDL;
    case 'No State PDL':
      return PdlStatus.NO_STATE_PDL;
    case 'Preferred (Not Listed)':
      return PdlStatus.PREFERRED_NOT_LISTED;
    case 'Non-Preferred (Not Listed)':
      return PdlStatus.NON_PREFERRED_NOT_LISTED;
    default:
      return PdlStatus.BLANK;
  }
};

export const pdlStatusOptions = [
  PdlStatus.BLANK,
  PdlStatus.PREFERRED,
  PdlStatus.NON_PREFERRED,
  PdlStatus.NON_PDL,
  PdlStatus.NO_STATE_PDL,
  PdlStatus.PREFERRED_NOT_LISTED,
  PdlStatus.NON_PREFERRED_NOT_LISTED,
].map((pdlStatus) => ({ id: pdlStatus, label: displayPdlStatus(pdlStatus) }));
