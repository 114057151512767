import * as Headless from '@headlessui/react';
import { isEmpty, sortBy } from 'lodash';
import { FaFlagUsa } from 'react-icons/fa6';

import { EmptyState, EmptyStateBody, EmptyStateHeading } from '@/components-new/empty-state';

import { DraftCoverageSet } from '../api/types';
import { useQuerySyncedTabs } from '../hooks/use-query-synced-tabs';
import { StateCoverageStep } from './state-coverage-step';
import { StateCoverageWizardNav } from './state-coverage-wizard-nav';

/**
 * Wizard for managing state coverage for a draft coverage set.
 */
export const StateCoverageWizard = ({ draft }: { draft: DraftCoverageSet  }) => {
  const orderedStates = sortBy(draft.states, 'state.name');

  const {
    selectedTabIndex,
    setSelectedTabIndex,
  } = useQuerySyncedTabs({ states: orderedStates });

  const resetScroll = () => {
    document.documentElement.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSelectedTabChange = async (selectedTabIndex: number) => {
    setSelectedTabIndex(selectedTabIndex);
    resetScroll();
  };

  return (
    <>
      <Headless.TabGroup
        className="gap-8 lg:flex lg:min-h-full lg:flex-row-reverse"
        vertical
        selectedIndex={selectedTabIndex}
        onChange={handleSelectedTabChange}
      >
        <StateCoverageWizardNav draft={draft} states={orderedStates}/>
        <Headless.TabPanels className="flex-auto">
          {orderedStates.map((state) => (
            <Headless.TabPanel
              className="flex flex-col gap-4"
              key={state.state.code}
              >
              <StateCoverageStep
                draft={draft}
                state={state}
                states={orderedStates}
                onNextState={handleSelectedTabChange}
                onPreviousState={handleSelectedTabChange}
                onResetScroll={resetScroll}
              />
            </Headless.TabPanel>
          ))}
          {isEmpty(orderedStates) && (
            <EmptyState>
              <EmptyStateHeading className="flex flex-col items-center gap-4">
                <FaFlagUsa className="size-12 text-gray-400"/>
                No States.
                </EmptyStateHeading>
              <EmptyStateBody className="max-w-lg text-center">You must have at least on state with coverage to finalize a draft.</EmptyStateBody>
            </EmptyState>
          )}
        </Headless.TabPanels>
      </Headless.TabGroup>
    </>
  );
};
