import * as Headless from '@headlessui/react';
import clsx from 'clsx';
import { isNil } from 'lodash';
import React, { ElementType, forwardRef, useCallback, useMemo } from 'react';
import { HiChevronRight, HiXMark } from 'react-icons/hi2';

import { BadgeButton } from '@/components-new/badge';
import { Button } from '@/components-new/button';
import { FilterSchema, useFilter } from '@/components-new/filter/use-filter';
import { useMergeRefs } from '@/hooks/use-merge-refs';
import { createContextHook } from '@/utils/hooks';

/**
 * Context for managing the state and behavior certain filter component functionality.
 */
type FilterContextType = {

  /**
   * A React ref object used to reference the filter button element within the component.
   * It provides a way to directly interact with the DOM node or pass the reference.
   */
  addFilterButtonRef: React.RefObject<HTMLButtonElement>;

  /**
   * A function that opens the filter panel programmatically.
   */
  openFilterPanel: () => void;

  /**
   * Currently selected tab index.
   */
  selectedTabIndex: number;

  /**
   * Callback function when the tab index is changed.
   *
   * @param value - Updated tab index.
   */
  onSelectedTabIndexChange: (value: number) => void;
};

export const FilterContext = React.createContext<FilterContextType | null>(null);

const useFilterContext = createContextHook(FilterContext);

/**
 * Button for toggling a filter panel.
 */
export const AddFilterButton = forwardRef<
  HTMLButtonElement,
  { as?: ElementType; children?: React.ReactNode } & React.ComponentPropsWithoutRef<ElementType>
>((
  { as: Component = 'button', className, ...props },
  ref
) => {
  const { addFilterButtonRef } = useFilterContext();
  const mergedRef = useMergeRefs(ref, addFilterButtonRef);

  return (
    <Headless.PopoverButton
      as={Component}
      ref={mergedRef}
      className={clsx(className, 'whitespace-nowrap')}
      {...props}
    />
  );
});

/**
 * Container for managing the filter panels and active filters.
 * Uses the useFilter hook to manage the filters.
 */
export const Filter = <TFilterSchema extends FilterSchema>({
  children,
  className,
  filter
}: { children?: React.ReactNode; className?: string; filter: ReturnType<typeof useFilter<TFilterSchema>> }) => {
  const addFilterButtonRef = React.useRef<HTMLButtonElement>(null);

  // Locates the index of the section based on the id of the section.
  const getIndexOfSection = useCallback((id?: string) => {
    if (isNil(id)) return 0;

    const indexOfTab = filter.filterSections.map(section => section.id).indexOf(id);

    if (indexOfTab === -1) return 0;

    return indexOfTab;
  }, [filter.filterSections]);

  const handleSelectedTabChange = (index?: number) => {
    const foundTab = !isNil(index) ? filter.filterSections.at(index)?.id : undefined;
    filter.setSelectedSection(foundTab);
  };

  const selectedTabIndex = useMemo(() => {
    return getIndexOfSection(String(filter.selectedSection));
  }, [filter.selectedSection, getIndexOfSection]);

  const openFilterPanel = () => {
    addFilterButtonRef.current?.click();
  };

  // When the tab is closed, we reset the selected section back to nothing.
  const handleTransitionEnd = (event: React.TransitionEvent<HTMLDivElement>) => {
    const isOpen = event.currentTarget.hasAttribute('data-open');

    if (isOpen) return;

    filter.setSelectedSection(undefined);
  };

  return (
    <FilterContext.Provider
      value={{
        addFilterButtonRef,
        selectedTabIndex,
        onSelectedTabIndexChange: handleSelectedTabChange,
        openFilterPanel
      }}
    >
      <Headless.Popover
        onTransitionEnd={handleTransitionEnd}
        className={clsx('flex flex-col gap-2 sm:flex-row', className)}
      >
        {children}
      </Headless.Popover>
    </FilterContext.Provider>
  );
};

/**
 * Popover panel that manages the filter sections and the content within them.
 */
export const FilterPanel = ({
  children,
  className
}: React.ComponentPropsWithoutRef<'div'>) => {
  const { selectedTabIndex, onSelectedTabIndexChange } = useFilterContext();

  return (
    <Headless.PopoverPanel
      transition
      anchor={{ to: 'bottom start', gap: 8 }}
      className={clsx(
        'origin-top flex-row rounded-xl border border-gray-200 bg-white shadow-md transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0',
        className
      )}
    >
      <Headless.TabGroup
        selectedIndex={selectedTabIndex}
        onChange={onSelectedTabIndexChange}
        vertical
        className="flex max-h-[408px] min-h-[408px]"
      >
        {children}
      </Headless.TabGroup>
    </Headless.PopoverPanel>
  );
};

/**
 * Bar that displays the currently active filters and controls for dealing with the active filters state.
 */
export const FiltersBar = ({
  children,
  className
} : React.ComponentPropsWithoutRef<'div'>) => {
  return (
    <div className={clsx(
      'flex w-full flex-row items-center gap-2 border-t pt-2 sm:border-l sm:border-t-0 sm:pl-2 sm:pt-0',
      className
    )}>
      {children}
    </div>
  );
};

/**
 * Contains the list of FilterPanelSection items that allow the user to navigate between filter sections.
 */
export const FilterPanelSections = ({
  children,
  className
}: React.ComponentPropsWithoutRef<'div'>) => {
  return (
    <Headless.TabList
      className={clsx(
        'w-56 border-r border-solid border-gray-200 bg-gray-50',
        'flex flex-col items-center px-4 py-2.5',
        className
      )}>
      {children}
    </Headless.TabList>
  );
};

/**
 * A filter section selection control, allows the user to select what section they want to filter from.
 */
export const FilterPanelSection = ({
  children,
  className
}: React.ComponentPropsWithoutRef<'div'>) => {
  return (
    <Headless.Tab
      className={clsx(
        'group/item mb-0.5 flex h-9 w-full flex-row items-center justify-between gap-2 rounded-lg p-2 text-gray-800 hover:bg-gray-100 data-[selected]:bg-gray-200',
        className
      )}
    >
      <div className="flex items-center gap-2">
        {children}
      </div>
      <HiChevronRight className="hidden group-hover/item:inline group-data-[selected]/item:inline"/>
    </Headless.Tab>
  );
};

/**
 * Contains the list of filter section contents for displaying the filter management controls.
 */
export const FilterPanelContents = ({
  children,
  className,
}: React.ComponentPropsWithoutRef<'div'>) => {
  return (
    <Headless.TabPanels
      className={clsx(
        'min-h-[400px] w-96 max-w-sm overflow-y-auto overflow-x-hidden bg-white px-6 pb-5 pt-6',
        className
      )}
    >
      {children}
    </Headless.TabPanels>
  );
};

/**
 * Renders an individual filter sections filter controls.
 */
export const FilterPanelContent = ({
  children,
  className,
}: React.ComponentPropsWithoutRef<'div'>) => {
  return (
    <Headless.TabPanel className={className}>
      {children}
    </Headless.TabPanel>
  );
};

/**
 * Displays the filter value of a filter section as a dismissible badge.
 * @param children
 * @param className
 * @param onClick - Callback function called when the badge is clicked.
 * When this is called, the panel will also open.
 * @param onDismiss - Callback function called when the badges dismiss button is clicked.
 */
export const ActiveFilter = ({
  children,
  className,
  onClick,
  onDismiss,
}: { onClick?: () => void; onDismiss?: () => void; } & React.ComponentPropsWithoutRef<'button'>) => {
  const { openFilterPanel } =  useFilterContext();

  const handleValueClick = () => {
    onClick?.();
    openFilterPanel();
  };

  const handleRemoveClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onDismiss?.();
  };

  return (
    <BadgeButton
      onClick={handleValueClick}
      className={clsx(
        'flex items-center justify-between gap-2 whitespace-nowrap',
        className
      )}
    >
      {children}
      {/*@ts-expect-error TODO: fix typing to allow this it works fine and is the correct structure for HTML*/}
      <Button plain as="span" onClick={handleRemoveClick} className="-my-0.5 -mr-1.5">
        <HiXMark/>
      </Button>
    </BadgeButton>
  );
};

/**
 * Displays additional number of filters active, and opens the filter panel when clicked.
 */
export const FilterBarMore = ({
  children,
  className,
  onClick,
}: React.ComponentPropsWithoutRef<'button'>) => {
  const { openFilterPanel } =  useFilterContext();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    openFilterPanel();
    onClick?.(event as any);
  };

  return (
    <Button
      className={className}
      plain
      color="secondary"
      onClick={handleClick}
    >
      {children}
    </Button>
  );
};

/**
 * Button that can be used to reset the filters.
 */
export const ResetFiltersButton = ({
  children,
  className,
  onClick
}: React.ComponentPropsWithoutRef<'button'>) => {

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    onClick?.(event as any);
  };
  return (
    <Button
      onClick={handleClick}
      className={className}
      plain
      color="secondary"
    >
      {children}
    </Button>
  );
};
