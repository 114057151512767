import { useQuery } from '@tanstack/react-query';

import { artiaProtectedApi } from '@/lib/api-client';
import { Pool } from '@/types/pool';

const getPools = async (): Promise<Pool[]> => {
  return await artiaProtectedApi.get('states/pools');
};

export const useGetPools = () => {
  return useQuery({
    queryKey: ['pools'],
    queryFn: async () => getPools()
  });
};

export const useGetPoolsLookups = () => {
  const query = useGetPools();

  const data = query.data?.map(pool => ({
    code: pool.code,
    label: pool.name,
    state:pool.states.map(({code,name})=>({code,label:name})),

  }));

  return {
    ...query,
    data,
  };
};

