import { useAsync } from '@shared/async';
import { PaginationResult } from '@shared/pagination';
import * as DateValidators from '@shared/validators/date-time-validators';
import { Box, CheckBox, FormField, Text } from 'grommet';
import React, { ReactNode, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Chip } from '@/components/chip';
import { ClassificationFilter } from '@/components/classification-filter';
import { ClinicalsProductFilter } from '@/components/clinicals-product-filter';
import { RhfFilterForm } from '@/components/filters';
import { DateTextInput, InMemoryMultiSelectList, LazyLoadMultiSelectList } from '@/components/form-controls';
import { MultiSelectOption } from '@/components/form-controls/multi-select-list';
import { StateAndPoolFilter } from '@/components/state-and-pool-filter';
import { PdlStatus, pdlStatusOptions } from '@/features/coverage/types/pdl-status';
import { useDebounce } from '@/hooks/use-debounce';
import { useLookupsService } from '@/hooks/use-lookups-service';
import { Lookup } from '@/types/lookup';

export type FilterInputs = {
  clients?: MultiSelectOption[];
  states?: MultiSelectOption<string>[];
  products?: MultiSelectOption[];
  classifications?: MultiSelectOption[];
  paTypes?: MultiSelectOption[];
  pdlStatuses?: MultiSelectOption<PdlStatus>[];
  pdlStatusBeforeDate?: string;
  excludeNonTrackedDrugs?: boolean;
};

type CoverageFiltersProps = {
  disabled: boolean;
  defaultFilters: FilterInputs;
  onApplyFilters: (filters: FilterInputs) => void;
  children?: ReactNode;
};

export const CoverageFilters = (props: CoverageFiltersProps) => {
  const {
    defaultFilters,
    onApplyFilters,
    children
  } = props;

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset
  } = useForm<FilterInputs>({
    mode: 'all',
    defaultValues: {
      clients: defaultFilters.clients ?? [],
      states: defaultFilters.states ?? [],
      products: defaultFilters.products ?? [],
      classifications: defaultFilters.classifications ?? [],
      paTypes: defaultFilters.paTypes ?? [],
      pdlStatuses: defaultFilters.pdlStatuses ?? [],
      pdlStatusBeforeDate: defaultFilters.pdlStatusBeforeDate ?? '',
      excludeNonTrackedDrugs: true
    }
  });

  const handleFilterSubmit = async (filters: FilterInputs) => {
    onApplyFilters(filters);
  };

  const filters = watch();
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const anySelectedFilters = filters.clients.length > 0 ||
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    filters.states.length > 0 ||
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    filters.products.length > 0 ||
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    filters.classifications.length > 0 ||
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    filters.paTypes.length > 0 ||
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    filters.pdlStatuses.length > 0;

  const { getStates, getPools, getClientLookups, getPaTypeLookups } = useLookupsService();

  const statesAsync = useAsync(getStates);
  const poolsAsync = useAsync(getPools);
  const paTypesAsync = useAsync(getPaTypeLookups);

  useEffect(() => {
    void statesAsync.execute();
    void poolsAsync.execute();
    void paTypesAsync.execute();
  }, []);

  const [classifications, clients] = watch(['classifications', 'clients']);
  const selectedClassifications = useDebounce(classifications);
  const selectedClients = useDebounce(clients);

  return (
    <RhfFilterForm
      variant="table"
      actions={children}
      watch={watch}
      onReset={() => reset({
        clients: [],
        states: [],
        products: [],
        classifications: [],
        paTypes: [],
        pdlStatuses: [],
        pdlStatusBeforeDate: '',
      })}
      onSubmit={handleSubmit(handleFilterSubmit)}
      chips={anySelectedFilters ? (
        <>
          {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
          {filters.states.map(({ id, label }) => (
            <Chip
              key={`states-${id}`}
              chip={{
                label: (
                  <>
                    <strong>State/Pool:</strong> {label}
                  </>
                ),
                value: id,
                dismissible: true
              }}
              // @ts-expect-error TS(2532): Object is possibly 'undefined'.
              onDismiss={() => setValue('states', filters.states.filter(option => option.id !== id))}
            />
          ))}
          {/*@ts-expect-error TS(2532): Object is possibly 'undefined'.*/}
          {filters.clients.map(({ id, label }) => (
            <Chip
              key={`clients-${id}`}
              chip={{
                label: (
                  <>
                    <strong>Client:</strong> {label}
                  </>
                ),
                value: id,
                dismissible: true
              }}
              // @ts-expect-error TS(2532): Object is possibly 'undefined'.
              onDismiss={() => setValue('clients', filters.clients.filter(option => option.id !== id))}
            />
          ))}
          {/*@ts-expect-error TS(2532): Object is possibly 'undefined'.*/}
          {filters.products.map(({ id, label }) => (
            <Chip
              key={`products-${id}`}
              chip={{
                label: (
                  <>
                    <strong>Drug:</strong> {label}
                  </>
                ),
                value: id,
                dismissible: true
              }}
              // @ts-expect-error TS(2532): Object is possibly 'undefined'.
              onDismiss={() => setValue('products', filters.products.filter(option => option.id !== id))}
            />
          ))}
          {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
          {filters.classifications.map(({ id, label }) => (
            <Chip
              key={`classifications-${id}`}
              chip={{
                label: (
                  <>
                    <strong>Class:</strong> {label}
                  </>
                ),
                value: id,
                dismissible: true
              }}
              // @ts-expect-error TS(2532): Object is possibly 'undefined'.
              onDismiss={() => setValue('classifications', filters.classifications.filter(option => option.id !== id))}
            />
          ))}
          {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
          {filters.paTypes.map(({ id, label }) => (
            <Chip
              key={`paTypes-${id}`}
              chip={{
                label: (
                  <>
                    <strong>PA Type:</strong> {label}
                  </>
                ),
                value: id,
                dismissible: true
              }}
              // @ts-expect-error TS(2532): Object is possibly 'undefined'.
              onDismiss={() => setValue('paTypes', filters.paTypes.filter(option => option.id !== id))}
            />
          ))}
          {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
          {filters.pdlStatuses.map(({ id, label }) => (
            <Chip
              key={`pdlStatuses-${id}`}
              chip={{
                label: (
                  <>
                    <strong>PDL Status:</strong> {label}
                  </>
                ),
                value: id,
                dismissible: true
              }}
              // @ts-expect-error TS(2532): Object is possibly 'undefined'.
              onDismiss={() => setValue('pdlStatuses', filters.pdlStatuses.filter(option => option.id !== id))}
            />
          ))}
        </>
      ) : null}
    >
      <Box width={{ min: '14rem' }}>
        <Controller
          control={control}
          name="excludeNonTrackedDrugs"
          render={({ field: { value, onChange } }) => (
            <Box margin={{ bottom: '1rem' }}>
              <CheckBox
                label="Include Only Monitoring Products"
                checked={value}
                onChange={onChange}
              />
            </Box>
          )}/>
      </Box>
      <Box width={{ min: '14rem' }}>
        <Controller
          control={control}
          name="states"
          render={({ field: { value, onChange } }) => (
            <StateAndPoolFilter
              states={statesAsync.value ?? []}
              pools={poolsAsync.value ?? []}
              // @ts-expect-error TS(2322): Type 'MultiSelectOption<string>[] | undefined' is ... Remove this comment to see the full error message
              selected={value}
              onSelectionChange={onChange}
            />
          )}
        />
      </Box>
      <Box width={{ min: '14rem' }}>
        <Controller
          control={control}
          name="clients"
          render={({ field }) => (
            // @ts-expect-error TS(2322): Type '{ lazyLoadRequest: (searchTerm: string, page... Remove this comment to see the full error message
            <LazyLoadMultiSelectList
              placeholder="Search by Clients Tracking"
              {...field}
              lazyLoadRequest={async (searchTerm, page, rpp) => {
                const result = await getClientLookups({ query: searchTerm, page, rpp });
                return result as PaginationResult<Lookup>;
              }}
            />
          )}
        />
      </Box>
      <Box width={{ min: '14rem' }}>
        <Controller
          control={control}
          name="classifications"
          render={({ field: { value, onChange } }) => (
            // @ts-expect-error TS(2322): Type 'MultiSelectOption<number>[] | undefined' is ... Remove this comment to see the full error message
            <ClassificationFilter clients={selectedClients} selected={value} onSelectionChange={onChange} />
          )}
        />
      </Box>
      <Box width={{ min: '14rem' }}>
        <Controller
          control={control}
          name="products"
          render={({ field: { value, onChange } }) => (
            <ClinicalsProductFilter
              clients={selectedClients}
              classifications={selectedClassifications}
              // @ts-expect-error TS(2322): Type 'MultiSelectOption<number>[] | undefined' is ... Remove this comment to see the full error message
              selected={value}
              onSelectionChange={onChange}
              dataSource="drugs"
            />
          )}
        />
      </Box>
      <Box width={{ min: '14rem' }}>
        <Controller
          control={control}
          name="paTypes"
          render={({ field: { value, onChange, name, onBlur, ref } }) => (
            <InMemoryMultiSelectList
              placeholder="Search by PA Types"
              options={paTypesAsync.value ?? []}
              // @ts-expect-error TS(2322): Type 'MultiSelectOption<number>[] | undefined' is ... Remove this comment to see the full error message
              value={value}
              onChange={onChange}
              name={name}
              onBlur={onBlur}
              ref={ref}
            />
          )}
        />
      </Box>
      <Box width={{ min: '14rem' }}>
        <Controller
          control={control}
          name="pdlStatuses"
          render={({ field: { value, onChange, name, onBlur, ref } }) => (
            <InMemoryMultiSelectList
              placeholder="Search by PDL Status"
              options={pdlStatusOptions}
              // @ts-expect-error TS(2322): Type 'MultiSelectOption<PdlStatus>[] | undefined' ... Remove this comment to see the full error message
              value={value}
              onChange={onChange}
              name={name}
              onBlur={onBlur}
              ref={ref}
            />
          )}
        />
      </Box>
      <Box width={{ min: '14rem' }}>
        <Controller
          control={control}
          name="pdlStatusBeforeDate"
          rules={{
            validate: {
              // @ts-expect-error TS(2322): Type '(value: string) => string | undefined' is no... Remove this comment to see the full error message
              valid: DateValidators.isValidDate,
            }
          }}
          render={({ field: { name, onChange, onBlur, value } }) => (
            <FormField
              required
              label={<Text color="white">PDL Status Date Until</Text>}
              name={name}
              htmlFor={`${name}`}
              margin="none"
            >
              <DateTextInput
                name={name}
                id={name}
                value={value}
                onChange={({ target }) => onChange((target as any).value)}
                onBlur={onBlur}
              />
            </FormField>
          )}
        />
      </Box>
    </RhfFilterForm>
  );
};
