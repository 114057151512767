import { useRef } from 'react';
import { FaTimes } from 'react-icons/fa';
import { LiaPillsSolid } from 'react-icons/lia';

import { Breadcrumbs } from '@/components-new/breadcrumbs';
import { Button } from '@/components-new/button';
import { DrugName } from '@/components-new/drugs';
import { Loader } from '@/components-new/loader';
import { MetaData, MetaDataItem } from '@/components-new/meta-data';
import { Overlay } from '@/components-new/overlay';
import { Page } from '@/components-new/page';
import { PageTitleRow } from '@/components-new/page-title-row';
import { useGetDraftCoverage } from '@/features/draft-coverage/api/queries';
import { DraftCoverageSet } from '@/features/draft-coverage/api/types';
import { StateCoverageWizard } from '@/features/draft-coverage/components/state-coverage-wizard';
import _404Page from '@/pages/404';
import { useParams } from '@/router';
import { ProtectedRoute } from '@/shared/auth';

const EditDraftPage = ({ draft }: { draft: DraftCoverageSet }) => {
  const scrollTargetRef = useRef<HTMLDivElement>(null);

  return (
    <Page
      title={`State Coverage for ${draft.drug.name}`}
      description={`Edit the state coverage for ${draft.drug.name}.`}
      header={({ title }) => (
        <div ref={scrollTargetRef}>
          <Breadcrumbs
            breadcrumbs={[
              { label: 'Drug Coverage', url: '/drug-coverage' },
              { label: 'Drafts', url: '/drug-coverage/drafts' },
              { label: title ?? '', url: `/drug-coverage/drafts/${draft.id}` }
            ]}
          />
          <PageTitleRow
            title={title}
            subtitle={
              <MetaData>
                <MetaDataItem>
                  <LiaPillsSolid className="mr-2"/>
                  <DrugName drug={draft.drug}/>
                </MetaDataItem>
              </MetaData>
            }
          >
            <Button plain href="/drug-coverage/drafts" title="Return to drafts">
              <FaTimes/>
            </Button>
          </PageTitleRow>
        </div>
      )}
    >
      <StateCoverageWizard draft={draft}/>
    </Page>
  );
};

const EditDraftCoverageBoundary = () => {
  const { id } = useParams('/drug-coverage/drafts/:id');
  const parsedId = parseInt(id);
  const { data: draft, isLoading: isLoadingDraft } = useGetDraftCoverage(parsedId);

  if (isLoadingDraft) {
    return (
      <div className="relative flex h-full items-center justify-center">
        <Overlay>
          <Loader message="Loading draft..."/>
        </Overlay>
      </div>
    );
  }

  if (!draft) return <_404Page/>;

  return (
    <ProtectedRoute policies={['canManageDrugs']}>
      <EditDraftPage draft={draft}/>
    </ProtectedRoute>
  );
};

export default EditDraftCoverageBoundary;
