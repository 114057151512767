import { ProtectedRoute } from '@shared/auth';
import { createColumnHelper } from '@tanstack/react-table';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { FaTrashCan } from 'react-icons/fa6';
import { HiPlus } from 'react-icons/hi2';

import { Button } from '@/components-new/button';
import { DataPaginator } from '@/components-new/data-paginator';
import { DataTable, useDataTable } from '@/components-new/data-table';
import { Dialog, DialogActions, DialogDescription, DialogTitle } from '@/components-new/dialog';
import { Loader } from '@/components-new/loader';
import { Overlay } from '@/components-new/overlay';
import { Progress } from '@/components-new/progress';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components-new/tooltip';
import { useDeleteDraftCoverageSet, useGetDrafts } from '@/features/draft-coverage/api/queries';
import { DraftCoverageSets } from '@/features/draft-coverage/api/types';
import { notifySuccess } from '@/lib/notification/notifications';

const columnHelper = createColumnHelper<DraftCoverageSets>();

const DraftsTable = () => {
  const { data: draftsData, isPending: draftsLoading } = useGetDrafts();
  const drafts = useMemo(() => draftsData ?? [], [draftsData]);
  const [idToDelete, setIdToDelete] = useState<number | null>(null);

  const { mutate: deleteDraft } = useDeleteDraftCoverageSet();

  const handleDeleteDraft = (id: number) => {
    setIdToDelete(id);
  };

  const handleConfirmDeleteDraft = () => {
    if (idToDelete) {
      deleteDraft(
        idToDelete,
        {
          onSuccess: () => {
            notifySuccess({ title: 'Draft deleted', message: 'The draft has been deleted.' });
          }
        }
      );
      setIdToDelete(null);
    }
  };

  const handleCancelDeleteDraft = () => {
    setIdToDelete(null);
  };

  const columns = useMemo(() => (
    [
      columnHelper.accessor(
        (row) => row.drug.name,
        {
          header: 'Drug'
        }
      ),
      columnHelper.accessor(
        (row) => row.percentComplete * 100,
        {
          id: 'progress',
          header: 'Progress',
          cell: ({ getValue }) => (
            <div className="flex flex-col">
              <span className="self-end">{getValue()}%</span>
              <Progress value={getValue()} max={100} />
            </div>
          ),
          enableSorting: false
        }
      ),
      columnHelper.accessor(
        ({ updatedAt }) => isEmpty(updatedAt)
          ? ''
          : DateTime.fromISO(updatedAt).toLocaleString(DateTime.DATETIME_SHORT),
        {
          id: 'lastModifiedAt',
          header: 'Last Modified',
          cell: ({ getValue, row: { original } }) => (
            <div className="flex flex-col">
              <span className="text-sm font-bold">{original.updatedBy.name}</span>
              <time className="text-sm text-gray-500" dateTime={getValue()}>
                {getValue()}
              </time>
            </div>
          )
        }
      ),
      columnHelper.accessor(
        ({ createdAt }) => isEmpty(createdAt)
          ? ''
          : DateTime.fromISO(createdAt).toLocaleString(DateTime.DATETIME_SHORT),
        {
          id: 'createdAt',
          header: 'Created',
          cell: ({ getValue, row: { original } }) => (
            <div className="flex flex-col">
              <span className="text-sm font-bold">{original.createdBy.name}</span>
              <time className="text-sm text-gray-500" dateTime={getValue()}>
                {getValue()}
              </time>
            </div>
          )
        }
      ),
      columnHelper.display({
        id: 'actions',
        header: () => (
          <span className="sr-only">Actions</span>
        ),
        cell: ({ row }) => (
          <div className="flex justify-end">
            <Tooltip>
              <TooltipTrigger>
                <Button plain href={`/drug-coverage/drafts/${row.original.id}`}>
                  <FaArrowRight />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                Continue draft coverage.
              </TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger>
                <Button plain onClick={() => handleDeleteDraft(row.original.id)}>
                  <FaTrashCan />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                Delete draft coverage.
              </TooltipContent>
            </Tooltip>
          </div>
        ),
        size: 60
      })
    ]
  ), []);

  const table = useDataTable({
    columns,
    enablePagination: true,
    enableSorting: true,
    initialState: {
      pagination: { pageSize: 10 },
      sorting: [{ id: 'lastModifiedAt', desc: true }]
    },
    data: drafts
  });

  return (
    <div className="relative flow-root space-y-2">
      <DataTable table={table} />
      {draftsLoading && (
        <div className="flex h-48 items-center justify-center">
          <Overlay>
            <Loader message="Loading drafts..." />
          </Overlay>
        </div>
      )}
      <DataPaginator table={table} />
      <Dialog onClose={handleCancelDeleteDraft} open={idToDelete !== null}>
        <DialogTitle>Delete Draft</DialogTitle>
        <DialogDescription>Are you sure you want to remove this draft?</DialogDescription>
        <DialogActions>
          <Button outline onClick={handleCancelDeleteDraft}>Cancel</Button>
          <Button color="red" onClick={handleConfirmDeleteDraft}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const DraftsPage = () => {
  return (
    <div className="space-y-2">
      <div className="flex justify-end">
        <Button href={{ to: '/drug-coverage/drafts/new' }}>
          <HiPlus />
          Add New
        </Button>
      </div>
      <DraftsTable />
    </div>
  );
};

const DraftsPageBoundary = () => {
  return (
    <ProtectedRoute policies={['canManageDrugs']}>
      <DraftsPage />
    </ProtectedRoute>
  );
};

export default DraftsPageBoundary;
