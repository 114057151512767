import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FaTimes } from 'react-icons/fa';
import { HiChevronRight } from 'react-icons/hi2';

import { useGetProductLookups } from '@/api/product-lookups';
import { useGetStateLookups } from '@/api/use-states';
import { Breadcrumbs } from '@/components-new/breadcrumbs';
import { Button } from '@/components-new/button';
import { Checkbox, CheckboxField, CheckboxGroup } from '@/components-new/checkbox';
import { Combobox } from '@/components-new/combobox';
import { ErrorMessage, Field, Label } from '@/components-new/fieldset';
import { Subheading } from '@/components-new/heading';
import { Loader } from '@/components-new/loader';
import { Overlay } from '@/components-new/overlay';
import { Page } from '@/components-new/page';
import { PageTitleRow } from '@/components-new/page-title-row';
import { Text } from '@/components-new/text';
import { NewDraftCoverageSetInput, newDraftCoverageSetInputSchema } from '@/features/draft-coverage/api/api';
import { useCreateDraftCoverageSet } from '@/features/draft-coverage/api/queries';
import { useNavigate } from '@/router';
import { ProtectedRoute } from '@/shared/auth';

const NewDraftCoveragePage = () => {
  const { control, handleSubmit } = useForm<NewDraftCoverageSetInput>({
    resolver: zodResolver(newDraftCoverageSetInputSchema),
    defaultValues: {
      drug: null as any,
      states: []
    }
  });

  const { mutate: createDraftCoverageSet, isPending: isCreatingDraftCoverageSet } = useCreateDraftCoverageSet();
  const navigate = useNavigate();
  const { data: stateLookups, isLoading: isLoadingStates } = useGetStateLookups();
  const { data: productLookups, isLoading: isLoadingProductLookups } = useGetProductLookups();
  const [productLookupQuery, setProductLookupQuery] = React.useState<string | undefined>('');

  const productOptions = productLookups
    ?.filter(it => it.label.toLowerCase().includes(productLookupQuery?.toLowerCase() ?? '')) ?? [];

  const saveDraftCoverageSet: SubmitHandler<NewDraftCoverageSetInput> = async (data) => {
    createDraftCoverageSet(
      data,
      {
        onSuccess: (id) => {
          navigate('/drug-coverage/drafts/:id', { params: { id: id.toString() } });
        }
      }
    );
  };

  return (
    <Page
      title="New State Coverage"
      header={({ title }) => (
        <>
          <Breadcrumbs
            breadcrumbs={[
              { label: 'Drug Coverage', url: '/drug-coverage' },
              { label: 'Drafts', url: '/drug-coverage/drafts' },
              { label: 'New State Coverage', url: '/drug-coverage/drafts/new' }
            ]}
          />
          <PageTitleRow
            title={title}
            subtitle="Create new coverage for a drug across states."
          >
            <Button plain href={{ to: '/drug-coverage/drafts' }} disabled={isCreatingDraftCoverageSet}>
              <FaTimes/>
            </Button>
          </PageTitleRow>
        </>
      )}
    >
      <form
        className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 xl:grid-cols-3"
        onSubmit={handleSubmit(saveDraftCoverageSet)}
      >
        <div>
          <Subheading>Drug Information</Subheading>
          <Text>Details required to establish the baseline coverage.</Text>
        </div>
        <div className="md:col-span-2">
          <Controller
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Field disabled={isLoadingProductLookups}>
                <Label>Drug</Label>
                <Combobox
                  placeholder="Search for drug"
                  valueKey="id"
                  labelKey="label"
                  onQueryChange={setProductLookupQuery}
                  options={productOptions}
                  onChange={onChange}
                  value={value}
                />
                {error?.message && <ErrorMessage>{error?.message}</ErrorMessage>}
              </Field>
            )}
            name="drug"
          />
        </div>
        <div>
          <Subheading>States</Subheading>
          <Text>Select the states you want to create coverage for. You can add more and remove states later if needed.</Text>
        </div>
        <div className="relative md:col-span-2">
          {isLoadingStates && (
            <Overlay>
              <Loader message="Loading state options..."/>
            </Overlay>
          )}
          {!isLoadingStates && (
            <Controller
              control={control}
              name="states"
              render={({ field: { value, onChange, name }, fieldState: { error } }) => (
                <Field>
                  <CheckboxField className="mb-4">
                    <Checkbox
                      indeterminate
                      checked={value.length === stateLookups?.length}
                      onChange={(checked) => onChange(checked ? stateLookups?.map(lookup => lookup.code) ?? [] : [])}
                    />
                    <Label>All States</Label>
                  </CheckboxField>
                  <CheckboxGroup className="columns-1 md:columns-3">
                    {stateLookups?.map((state) => (
                      <CheckboxField key={state.code}>
                        <Checkbox
                          name={name}
                          checked={value.includes(state.code)}
                          onChange={(checked) => {
                            if (checked) {
                              onChange([...value, state.code]);
                            } else {
                              onChange(value.filter((code: string) => code !== state.code));
                            }
                          }}/>
                        <Label>{state.label}</Label>
                      </CheckboxField>
                    ))}
                  </CheckboxGroup>
                  {error?.message && <ErrorMessage>{error?.message}</ErrorMessage>}
                </Field>
              )}
            />
          )}
        </div>
        <div className="col-span-full flex justify-end">
          <Button type="submit" disabled={isCreatingDraftCoverageSet}>
            State Coverage <HiChevronRight/>
          </Button>
        </div>
      </form>
    </Page>
  );
};

const NewDraftCoverageBoundary = () => {
  return (
    <ProtectedRoute policies={['canManageDrugs']}>
      <NewDraftCoveragePage/>
    </ProtectedRoute>
  );
};

export default NewDraftCoverageBoundary;
