import { ColumnFilter } from '@tanstack/react-table';
import { useMemo } from 'react';
import { FaFilePrescription, FaFlagUsa, FaSignature } from 'react-icons/fa6';
import { MdGroups } from "react-icons/md";

import { createFilterSectionHelper, FilterItemValue, useFilter } from '@/components-new/filter/use-filter';
import { PATypeFilter, PDLStatusFilter, PoolsFilter, StateFilter } from '@/features/drugs/components/drug-info-filters';
import { CodeBasedLookup } from '@/types/lookup';

export type DrugInfoFilterSchema = {
  state: CodeBasedLookup[];
  pdlStatus: CodeBasedLookup[];
  paType: CodeBasedLookup[];
  pool: CodeBasedLookup[];
}

const filterSectionHelper = createFilterSectionHelper<DrugInfoFilterSchema>();

const getMultiFilterLabel = <TItem, >(
  label: string,
  value: TItem[] | undefined,
  getLabel: (item?: TItem) => string
) => {
  const selectedCount = value?.length ?? 0;
  const multipleSelected = selectedCount > 1;
  return multipleSelected ? `${label} (${selectedCount})` : `${getLabel(value?.[0])}`;
};

const filterSections = [
  filterSectionHelper.defineSection(
    'state',
    {
      sectionLabel: () => (
        <>
          <FaFlagUsa/>
          State
        </>
      ),
      filterLabel: (ctx) => getMultiFilterLabel('States', ctx.value, (item) => item?.label ?? ''),
      sectionContent: (ctx) => <StateFilter {...ctx}/>
    }
  ),
  filterSectionHelper.defineSection(
    'pool',
    {
      sectionLabel:() => (
        <>
          <MdGroups/>
          Pool
        </>
      ),
      filterLabel: (ctx) => getMultiFilterLabel('Pool', ctx.value, (item) => item?.label ?? ''),
      sectionContent: (ctx) => <PoolsFilter {...ctx}/>
    }
  ),
  filterSectionHelper.defineSection(
    'pdlStatus',
    {
      sectionLabel:() => (
        <>
          <FaFilePrescription/>
          PDL Status
        </>
      ),
      filterLabel: (ctx) => getMultiFilterLabel('PDL Statuses', ctx.value, (item) => item?.label ?? ''),
      sectionContent: (ctx) => <PDLStatusFilter {...ctx}/>
    }
  ),
  filterSectionHelper.defineSection(
    'paType',
    {
      sectionLabel:() => (
        <>
          <FaSignature/>
          PA Type
        </>
      ),
      filterLabel: (ctx) => getMultiFilterLabel('PA Types', ctx.value, (item) => item?.label ?? ''),
      sectionContent: (ctx) => <PATypeFilter {...ctx}/>
    }
  )
];

const labelBasedSerializer = (filter: FilterItemValue<DrugInfoFilterSchema>): ColumnFilter => ({
  id: filter.key,
  value: filter.value.map(value => value.label)
});

const serializerMap = {
  state: labelBasedSerializer,
  pdlStatus: labelBasedSerializer,
  paType: labelBasedSerializer,
  pool: labelBasedSerializer
} as const;

const serializeTableFilters = (filters: FilterItemValue<DrugInfoFilterSchema>[]): ColumnFilter[] => {
  return filters.map(filter => serializerMap[filter.key](filter));
};

export const useDrugInfoFilters = () => {
  const filter = useFilter({ filterSections });

  const tableColumnFilters = useMemo(() => serializeTableFilters(filter.values), [filter.values]);

  return {
    filter,
    tableColumnFilters
  };
};
