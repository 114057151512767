import { ProtectedRoute } from '@shared/auth';
import { toString } from 'lodash';
import { Suspense } from 'react';
import {
  LiaBookMedicalSolid,
  LiaBoxesSolid,
  LiaCalendar,
  LiaEditSolid,
  LiaPillsSolid,
  LiaTagsSolid
} from 'react-icons/lia';
import { Outlet } from 'react-router-dom';

import { OverlayLoader } from '@/components/loading';
import { Breadcrumbs } from '@/components-new/breadcrumbs';
import { Button } from '@/components-new/button';
import { MetaData, MetaDataItem } from '@/components-new/meta-data';
import { Page } from '@/components-new/page';
import { PageTitleRow } from '@/components-new/page-title-row';
import { Tab, TabGroup, TabList, TabPanels } from '@/components-new/tab';
import { useDrug } from '@/features/drugs/api/use-drug';
import { useCurrentUser } from '@/hooks/use-current-user';
import { useTabs } from '@/hooks/use-tabs';
import { useParams } from '@/router';
import { insertIf } from '@/utils/arrays';
import { drugName } from '@/utils/drugs';

const DrugLayoutScreen = () => {
  const { id: drugId } = useParams('/drugs/:id');
  const { data: drug } = useDrug(drugId);
  const { hasPolicies } = useCurrentUser();
  const canManageDrugs = hasPolicies(['canManageDrugs']);
  const isAnyArtiaUser = hasPolicies(['isAnyArtiaUser']);
  const canManageCoverageTags = hasPolicies(['canManageCoverageTags']);
  const canViewCalendar = hasPolicies(['canViewCalendar']);

  const {
    tabs,
    activeTabIndex,
    onChange
  } = useTabs([
    {
      label: (
        <>
          <LiaBookMedicalSolid/>
          Coverage
        </>
      ),
      href: `/drugs/${drugId}`,
    },
    ...insertIf(canViewCalendar,
      {
        label: (
          <>
            <LiaCalendar/>
            Meetings
          </>
        ),
        href: `/drugs/${drugId}/meetings`,
      },
    ),
    ...insertIf(isAnyArtiaUser,
      {
        label: (
          <>
            <LiaBoxesSolid/>
            Packagings
          </>
        ),
        href: `/drugs/${drugId}/packagings`,
      },
    ),
    ...insertIf(canManageCoverageTags,
      {
        label: (
          <>
            <LiaTagsSolid/>
            Coverage Tags
          </>
        ),
        href: `/drugs/${drugId}/coverage-tags`,
      }
    )
  ]);

  const drugDisplayName = drugName(drug);

  return (
    <Page
      title={drugDisplayName}
      header={({ title }) => (
        <>
          <Breadcrumbs
            breadcrumbs={[
              { label: 'Drugs', url: '/drugs' },
              { label: drugDisplayName, url: `/drugs/${drug.id}` }
            ]}
          />
          <PageTitleRow
            title={title}
            subtitle={
              <MetaData>
                {drug.genericName && (
                  <MetaDataItem>
                    <LiaPillsSolid className="mr-2"/>
                    {drug.genericName}
                  </MetaDataItem>
                )}
                {drug.classification && (
                  <MetaDataItem>
                    <LiaTagsSolid className="mr-2"/>
                    {drug.classification.name}
                  </MetaDataItem>
                )}
              </MetaData>
            }
          >
            {canManageDrugs && (
              <Button
                href={{
                  to: '/drugs/:id/edit',
                  params: { id: toString(drugId) },
                state: { referrer: `/drugs/${drug.id}` }
              }}
              color="secondary"
              aria-label={`Edit ${drugDisplayName}`}
              title={`Edit ${drugDisplayName}`}
            >
              <LiaEditSolid/>
              Edit
            </Button>
          )}
          </PageTitleRow>
        </>
      )}
    >
      <TabGroup selectedIndex={activeTabIndex} onChange={onChange}>
        <TabList>
          {tabs.map((tab) => (
            <Tab key={tab.href}>
              {tab.label}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          <div className="mt-2">
            <Outlet/>
          </div>
        </TabPanels>
      </TabGroup>
    </Page>
  );
};

const DrugLayout = () => {
  return (
    <ProtectedRoute>
      <Suspense fallback={<OverlayLoader/>}>
        <DrugLayoutScreen/>
      </Suspense>
    </ProtectedRoute>
  );
};

export default DrugLayout;
