import { isEqual,toLower } from 'lodash';
import { useCallback,useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DraftCoverageSetState } from '../api/types';


/**
 * Synchronize list of states used to build tabs with the 'state' query parameter.
 * - If the query param doesn't match any tab, a fallback (closest previous or first) is selected.
 */
export const useQuerySyncedTabs = ({ states }: { states: DraftCoverageSetState[]; }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  // maintains a reference to the previous states, so that if the current 'tab' is removed we can look for where it was.
  const previousStatesRef = useRef<DraftCoverageSetState[]>(states);
  useEffect(() => {
    previousStatesRef.current = states;
  }, [states]);

  // the normalized state code from the query param
  const queryState = toLower(searchParams.get('state') ?? '');

  // finds the index of the query param in the current list of states, otherwise return -1
  const selectedTabIndex = useMemo(() => {
    return states.findIndex(state => toLower(state.state.code) === queryState);
  }, [queryState, states]);

  // resolves the final selected tab index
  const resolvedTabIndex = useMemo(() => {
    // not states to select from
    if (states.length === 0) return -1;

    // query param matches an existing state
    if (selectedTabIndex !== -1) return selectedTabIndex;

    // begin fallback matching
    const prevStates = previousStatesRef.current;
    const prevIndex = prevStates.findIndex(state => toLower(state.state.code) === queryState);

    // first try to select the previous state from the previous states list
    if (prevIndex > 0) {
      const previousTab = prevStates[prevIndex - 1];
      const fallbackIndex = states.findIndex(state => state.state.code === previousTab.state.code);
      if (fallbackIndex !== -1) return fallbackIndex;
    }

    // next try to select the next state from the previous states list
    if (prevIndex !== -1 && prevIndex < prevStates.length - 1) {
      const nextTab = prevStates[prevIndex + 1];
      const fallbackIndex = states.findIndex(state => state.state.code === nextTab.state.code);
      if (fallbackIndex !== -1) return fallbackIndex;
    }

    // finally, just select the first state
    return 0;
  }, [queryState, selectedTabIndex, states]);

  // the full state object of the selected state
  const selectedState = resolvedTabIndex !== -1 ? states[resolvedTabIndex] : undefined;

  // whenever the tabIndex is resolved and it differs from the query param, update the query param
  useEffect(() => {
    // no valid tab selection is possible
    if (resolvedTabIndex === -1) return;

    const resolvedStateCode = toLower(states[resolvedTabIndex].state.code);
    if (!isEqual(queryState, resolvedStateCode)) {
      const newParams = new URLSearchParams(searchParams);
      newParams.set('state', resolvedStateCode);
      setSearchParams(newParams, { replace: true });
    }
  }, [queryState, resolvedTabIndex, searchParams, setSearchParams, states]);

  // set the selected tab index manually, clamps the index to safe bounds and updates the query param
  const setSelectedTabIndex = useCallback(
    (index: number) => {
      const clampedIndex = Math.max(0, Math.min(index, states.length - 1));
      const targetState = states[clampedIndex];
      if (targetState) {
        const newParams = new URLSearchParams(searchParams);
        newParams.set('state', toLower(targetState.state.code));
        setSearchParams(newParams, { replace: false });
      }
    },
    [searchParams, setSearchParams, states]
  );

  // manually set the selected state by code
  const setSelectedState = useCallback(
    (stateCode: string) => {
      const index = states.findIndex(state => toLower(state.state.code) === toLower(stateCode));
      if (index !== -1) {
        const newParams = new URLSearchParams(searchParams);
        newParams.set('state', toLower(states[index].state.code));
        setSearchParams(newParams, { replace: false });
      }
    },
    [searchParams, setSearchParams, states]
  );

  return {
    selectedState,
    selectedTabIndex: resolvedTabIndex,
    setSelectedState,
    setSelectedTabIndex,
  };
};
