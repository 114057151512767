import { isEmpty, isNil, sortBy, toLower } from 'lodash';
import { FaEdit, FaFileMedical, FaTimes } from 'react-icons/fa';
import { HiChevronLeft } from 'react-icons/hi2';
import { LiaPillsSolid } from 'react-icons/lia';

import { useGetStateLookups } from '@/api/use-states';
import Nullish from '@/components/nullish';
import { Badge } from '@/components-new/badge';
import { Breadcrumbs } from '@/components-new/breadcrumbs';
import { Button } from '@/components-new/button';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from '@/components-new/description-list';
import { DrugName } from '@/components-new/drugs';
import { EmptyState, EmptyStateBody,EmptyStateHeading } from '@/components-new/empty-state';
import { Heading, Subheading } from '@/components-new/heading';
import { Loader } from '@/components-new/loader';
import { MetaData, MetaDataItem } from '@/components-new/meta-data';
import { Overlay } from '@/components-new/overlay';
import { Page } from '@/components-new/page';
import { PageTitleRow } from '@/components-new/page-title-row';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components-new/table';
import { Text } from '@/components-new/text';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components-new/tooltip';
import { PdlStatus } from '@/features/coverage/types/pdl-status';
import { useCompleteDraftCoverageSet, useGetDraftCoverage } from '@/features/draft-coverage/api/queries';
import { DraftCoverageSet } from '@/features/draft-coverage/api/types';
import { PdlStatusBadge } from '@/features/drugs/components/pdl-status-badge';
import { CoverageTag } from '@/features/drugs/types/coverage-tag';
import _404Page from '@/pages/404';
import { useNavigate, useParams } from '@/router';
import { ProtectedRoute } from '@/shared/auth';
import { formatDate } from '@/utils/date-time-functions';

const CoverageCell = ({ coverageTags }: { coverageTags?: CoverageTag[] }) => {
  if (isNil(coverageTags) || isEmpty(coverageTags)) return 'Default Coverage';

  return coverageTags.map(tag => tag.name).join(', ');
};

const ReviewDraftCoveragePage = ({ draft }: { draft: DraftCoverageSet }) => {
  const navigate = useNavigate();

  const { states } = draft;
  const orderedStates = sortBy(states, 'state.name');

  const { mutate: completeDraftCoverage, isPending: isCompletingDraftCoverage } = useCompleteDraftCoverageSet();

  const { data: stateLookups } = useGetStateLookups();

  const handleConfirmAndFinalizeClick = () => {
    completeDraftCoverage(draft.id, { onSuccess: () => navigate('/drug-coverage') });
  };

  return (
    <Page
      title={`Review Draft Coverage for ${draft.drug.name}`}
      description={`Review the draft coverage for ${draft.drug.name}.`}
      header={({ title }) => (
        <div>
        <Breadcrumbs
          breadcrumbs={[
            { label: 'Drug Coverage', url: '/drug-coverage' },
            { label: 'Drafts', url: '/drug-coverage/drafts' },
            { label: 'State Coverage', url: `/drug-coverage/drafts/${draft.id}` },
            { label: title ?? '', url: `/drug-coverage/drafts/${draft.id}/review` }
          ]}
        />
        <PageTitleRow
          title={title}
          subtitle={
            <MetaData>
              <MetaDataItem>
                <LiaPillsSolid className="mr-2"/>
                <DrugName drug={draft.drug}/>
              </MetaDataItem>
            </MetaData>
          }
        >
          <Button plain href="/drug-coverage/drafts" title="Return to drafts">
            <FaTimes/>
          </Button>
        </PageTitleRow>
      </div>
      )}
    >
      {isCompletingDraftCoverage && (
        <div className="fixed inset-0 z-20">
          <Overlay className="z-20 size-full">
            <Loader message="Creating coverage from draft..."/>
          </Overlay>
        </div>
      )}
      <div>
        <div className="sticky top-14 z-10 -mt-6 flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 bg-white py-6 dark:border-white/10 lg:top-0">
          <div>
            <Heading>Review and Finalize</Heading>
            <Text>Review the draft details below to ensure the are accurate before finalizing</Text>
          </div>
          <div className="flex gap-4">
            <Button outline href={`/drug-coverage/drafts/${draft.id}`} disabled={isCompletingDraftCoverage}>
            <HiChevronLeft/>
              Back to Coverage
            </Button>
            <Button disabled={isCompletingDraftCoverage} onClick={handleConfirmAndFinalizeClick}>
              Confirm and Finalize
            </Button>
          </div>
        </div>
        <DescriptionList>
          <DescriptionTerm>State count</DescriptionTerm>
          <DescriptionDetails>{orderedStates.length} of {stateLookups?.length ?? 0}</DescriptionDetails>
        </DescriptionList>
        <ul role="list">
          {orderedStates.map(({ state, coverages }) => (
            <li key={state.code} className="flex justify-between gap-x-6 py-5">
              <div className="flex w-full min-w-0 gap-x-4">
                <div className="min-w-0 flex-auto">
                  <div className="flex items-center justify-between gap-4">
                    <div className="flex gap-4">
                      <Subheading>{state.name}</Subheading>
                      <Badge>{coverages.length}</Badge>
                    </div>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          plain
                          href={`/drug-coverage/drafts/${draft.id}?state=${toLower(state.code)}`}
                        >
                        <FaEdit/>
                      </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        Edit coverage for {state.name}.
                      </TooltipContent>
                    </Tooltip>
                  </div>
                  {isEmpty(coverages) ? (
                    <EmptyState>
                      <EmptyStateHeading className="flex flex-col items-center gap-4">
                        <FaFileMedical className="size-8 text-gray-400"/>
                        No coverage for {state.name}.
                        </EmptyStateHeading>
                      <EmptyStateBody className="max-w-lg text-center">Nothing will be saved for this state.</EmptyStateBody>
                    </EmptyState>
                  ) : (
                    <Table>
                    <TableHead>
                      <TableRow>
                        <TableHeader>Coverage</TableHeader>
                        <TableHeader>PDL Status</TableHeader>
                        <TableHeader>PDL Status Date</TableHeader>
                        <TableHeader>PA Type</TableHeader>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortBy(coverages, coverage => coverage.coverageTags?.length).map(coverage => (
                        <TableRow key={coverage.id}>
                          <TableCell>
                            <CoverageCell coverageTags={coverage.coverageTags}/>
                          </TableCell>
                          <TableCell>
                            <PdlStatusBadge pdlStatus={coverage.pdlStatus as unknown as PdlStatus}/>
                          </TableCell>
                          <TableCell>
                            <Nullish value={coverage.pdlStatusDate ? formatDate(coverage.pdlStatusDate) : ''}/>
                          </TableCell>
                          <TableCell>
                            <Nullish value={coverage.paTypes?.map(paType => paType.label).join(', ')}/>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Page>
  );
};

const ReviewDraftCoverageBoundary = () => {
  const { id } = useParams('/drug-coverage/drafts/:id');
  const parsedId = parseInt(id);
  const { data: draft, isLoading: isLoadingDraft } = useGetDraftCoverage(parsedId);

  if (isLoadingDraft) {
    return (
      <div className="relative flex h-full items-center justify-center">
        <Overlay>
          <Loader message="Loading draft..."/>
        </Overlay>
      </div>
    );
  }

  if (!draft) return <_404Page/>;

  return (
    <ProtectedRoute policies={['canManageDrugs']}>
      <ReviewDraftCoveragePage draft={draft}/>
    </ProtectedRoute>
  );
};

export default ReviewDraftCoverageBoundary;
