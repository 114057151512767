import { safeJsonParse } from '@/utils/parsing';

type Features = {
  [key: string]: boolean;
};

type FeatureManager<T extends Features> = {
  getFeatures: () => T;
  getFeature: <K extends keyof T>(feature: K, defaultValue?: boolean) => boolean;
}

type FeatureManagerConfig<T extends Features> = {
  initialValue: T
  localStoragePrefix?: string;
}

const createFeatureManager = <T extends Features>({ initialValue, localStoragePrefix = 'features.' }: FeatureManagerConfig<T>): FeatureManager<T> => {
  const defaultFeatures = { ...initialValue };
  const features = { ...initialValue };

  const getFeature = <K extends keyof T>(feature: K, defaultValue = false): boolean => {
    const localStorageValue = window.localStorage.getItem(`${localStoragePrefix}${feature as string}`);
    features[feature] = localStorageValue ? JSON.parse(localStorageValue) : defaultFeatures[feature];
    return features[feature] ?? defaultValue;
  };

  const getFeatures = () => {
    return Object.keys(features).reduce((previousValue, key) => {
      return {
        ...previousValue,
        [key]: getFeature(key)
      };
    }, {} as T);
  };

  return {
    getFeatures,
    getFeature
  };
};

const FEATURES = {
  clientViewStepTherapy: safeJsonParse<boolean>(import.meta.env.VITE_FEATURES_CLIENT_VIEW_STEP_THERAPY) ?? false,
  clientStateReports: safeJsonParse<boolean>(import.meta.env.VITE_FEATURES_CLIENT_STATE_REPORTS) ?? false,
  draftCoverageWorkflow: safeJsonParse<boolean>(import.meta.env.VITE_FEATURES_DRAFT_COVERAGE_WORKFLOW) ?? false,
  coverageNeedsReview: safeJsonParse<boolean>(import.meta.env.VITE_FEATURES_COVERAGE_NEEDS_REVIEW) ?? false,
} as const;

export const featureManager = createFeatureManager({ initialValue: FEATURES });
