import { zodResolver } from '@hookform/resolvers/zod';
import { motion } from 'framer-motion';
import { has, isEmpty, sortBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Control, Controller, FieldArrayWithId, FieldErrors, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { FaTimesCircle } from 'react-icons/fa';
import { FaFileMedical } from 'react-icons/fa6';
import { HiDuplicate } from 'react-icons/hi';
import { HiChevronLeft, HiChevronRight, HiMinus } from 'react-icons/hi2';

import { useGetCoverageTagLookups } from '@/api/coverage-tags';
import { useGetPaTypeLookups } from '@/api/pa-types';
import { MultiSelectList } from '@/components/form-controls/selects/multi-select-list';
import { Badge } from '@/components-new/badge';
import { Button } from '@/components-new/button';
import { Checkbox, CheckboxField } from '@/components-new/checkbox';
import { EmptyState, EmptyStateBody, EmptyStateHeading } from '@/components-new/empty-state';
import { ErrorMessage, Field, Label } from '@/components-new/fieldset';
import { Heading, Subheading } from '@/components-new/heading';
import { Input } from '@/components-new/input';
import { Listbox, ListboxOption } from '@/components-new/listbox';
import { Loader } from '@/components-new/loader';
import { Overlay } from '@/components-new/overlay';
import { Progress } from '@/components-new/progress';
import { RichTextEditorInput } from '@/components-new/rich-text-editor';
import { Text } from '@/components-new/text';
import { pdlStatusOptions } from '@/features/coverage/types/pdl-status';
import { useNavigate } from '@/router';
import { useMarkdown } from '@/shared/markdown';
import { StateRef } from '@/types/state';

import { UpdateStateCoverageInput, updateStateCoverageInputSchema } from '../api/api';
import { useUpdateStateDraftCoverages } from '../api/queries';
import { DraftCoverageSet, DraftCoverageSetState } from '../api/types';
import { RemoveCoverageDialog } from './remove-coverage-dialog';
import { RemoveStateDialog } from './remove-state-dialog';

/**
 * Displays a validation error alert with a list of validation errors.
 */
const ValidationErrorAlert = ({ errors }: { errors: FieldErrors<UpdateStateCoverageInput> }) => {
  const coveragesError = has(errors.coverages?.root, 'message') ? errors.coverages?.root?.message : undefined;

  const refinedErrors = [
    coveragesError,
  ].filter((it) => it);

  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="shrink-0">
          <FaTimesCircle aria-hidden="true" className="size-5 text-red-400"/>
        </div>
        <div className="ml-3 flex flex-col gap-2">
          <h3 className="text-sm font-medium text-red-800">
            Please correct validation errors before continuing
          </h3>
          <div className="text-sm text-red-700">
            <ul role="list" className="list-disc space-y-1 pl-5">
              {refinedErrors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * An individual step in the state coverage process, allowing the user to manage coverage information for a specific state.
 */
export const StateCoverageStep = ({
    draft,
    state,
    states,
    onNextState,
    onPreviousState,
    onResetScroll,
  }: {
    draft: DraftCoverageSet;
    state: DraftCoverageSetState;
    states: DraftCoverageSetState[];
    onNextState: (index: number) => void;
    onPreviousState: (index: number) => void;
    onResetScroll: () => void;
  }) => {
    const navigate = useNavigate();

    const form = useForm<UpdateStateCoverageInput>({
      resolver: zodResolver(updateStateCoverageInputSchema, { async: false }),
      defaultValues: {
        draftSetId: draft.id,
        stateCode: state.state.code,
        isComplete: true,
        coverages: state.coverages.map(it => ({
          coverageTags: it.coverageTags?.map(it => it.id) ?? [],
          stepTherapyCount: it.stepTherapyCount?.toString() ?? '',
          hasAdditionalSteps: it.hasAdditionalSteps ?? false,
          pdlStatus: it.pdlStatus ?? '',
          pdlStatusDate: it.pdlStatusDate ?? '',
          pdlStatusEffectiveDate: it.pdlStatusEffectiveDate ?? '',
          paTypes: it.paTypes?.map(it => it.id) ?? [],
          paCriteriaSourceUrl: it.paCriteriaSourceUrl ?? '',
          paCriteriaDescription: it.paCriteriaDescription ?? '',
          hasAutoPa: it.hasAutoPa ?? false,
          notes: it.notes ?? ''
        })) ?? [],
      }
    }
  );

  const { formState, trigger, clearErrors, control, handleSubmit, watch } = form;
  const { fields, append, remove, insert } = useFieldArray({ control, name: 'coverages', shouldUnregister: true });
  const { isValid, isSubmitted, errors } = formState;

  const { mutate: updateStateDraftCoverages, isPending: savingDraftCoverages } = useUpdateStateDraftCoverages();

  const coverages = watch('coverages');

  useEffect(() => {
    if (isSubmitted) {
      trigger();
    }

    if (isValid) {
      clearErrors('coverages');
    }
  }, [coverages, isSubmitted, trigger, isValid, clearErrors]);

  const nextState = useMemo(() => {
    const currentIndex = states.findIndex(it => it.state.code === state.state.code);

    if (currentIndex === states.length - 1) {
      return undefined;
    }

    return states[currentIndex + 1];
  }, [state, states]);

  const previousState = useMemo(() => {
    const currentIndex = states.findIndex(it => it.state.code === state.state.code);

    if (currentIndex === 0) {
      return undefined;
    }

    return states[currentIndex - 1];
  }, [state, states]);

  const [coverageIndexToRemove, setCoverageIndexToRemove] = useState<number | undefined>();

  const handleAddCoverageClick = () => {
    append({
      coverageTags: [],
      stepTherapyCount: '',
      hasAdditionalSteps: false,
      pdlStatus: '',
      pdlStatusDate: '',
      pdlStatusEffectiveDate: '',
      paTypes: [],
      paCriteriaSourceUrl: '',
      hasAutoPa: false,
      paCriteriaDescription: '',
      notes: ''
    });
  };

  const handleRemoveCoverage = (index: number) => {
    setCoverageIndexToRemove(index);
  };

  const handleConfirmRemoveCoverage = (confirmed: boolean) => {
    if (!confirmed) {
      setCoverageIndexToRemove(undefined);
      return;
    }

    remove(coverageIndexToRemove!);
    setCoverageIndexToRemove(undefined);
  };

  const handleDuplicateCoverage = (index: number) => {
    const currentCoverages = form.getValues().coverages;
    const coverageToDuplicate = currentCoverages[index];
    insert(index + 1, coverageToDuplicate);
  };

  const triggerFormValidation = async () => {
    const isValid = await form.trigger();

    if (!isValid) onResetScroll();

    return isValid;
  };

  const handleNextClick: SubmitHandler<UpdateStateCoverageInput> = async (data) => {
    const isValid = await triggerFormValidation();
    if (!isValid) return;

    if (!nextState) {
      saveDraftCoverage(data, () => {
        navigate('/drug-coverage/drafts/:id/review', { params: { id: draft.id.toString() } });
      });
      return;
    }

    saveDraftCoverage(data, () => {
      onNextState(states.indexOf(nextState)!);
    });
  };

  const handlePreviousClick: SubmitHandler<UpdateStateCoverageInput> = async(data) => {
    const isValid = await triggerFormValidation();
    if (!isValid) return;

    if (!previousState) return;

    saveDraftCoverage(data, () => {
      onPreviousState(states.indexOf(previousState)!);
    });
  };

  const saveDraftCoverage = async (data: UpdateStateCoverageInput, onSuccess: () => void) => {
    updateStateDraftCoverages({
      draftSetId: draft.id,
      stateCode: state.state.code,
      coverages: data.coverages,
      isComplete: true
    }, {
      onSuccess: () => {
        onSuccess();
      }
    });
  };

  return (
    <>
      {savingDraftCoverages && (
        <div className="fixed inset-0 z-10">
          <Overlay className="z-20 size-full">
            <Loader message="Updating draft..."/>
          </Overlay>
        </div>
      )}
      <StateCoverageStepHeader draft={draft} state={state} coverageCount={fields.length}/>
      {!isEmpty(errors) && <ValidationErrorAlert errors={errors}/>}
      {isEmpty(fields) && (
        <EmptyState>
          <EmptyStateHeading className="flex flex-col items-center gap-4">
            <FaFileMedical className="size-12 text-gray-400"/>
            No coverage for {state.state.name}.
            </EmptyStateHeading>
          <EmptyStateBody className="max-w-lg text-center">If you don't need to add coverage for {state.state.name} you can either leave it blank or remove the state.</EmptyStateBody>
        </EmptyState>
      )}
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.3 }}>
        <form className="space-y-8">
            {fields.map((field, index) => (
              <StateCoverageFields
                key={field.id}
                index={index}
                field={field}
                draft={draft}
                control={control}
                state={state}
                onRemoveCoverage={handleRemoveCoverage}
                onDuplicateCoverage={handleDuplicateCoverage}
              />
            ))}
          <Button outline onClick={handleAddCoverageClick} className="w-full" type="button">
            Add Coverage
          </Button>
          <div className="flex justify-between">
            <Button outline disabled={!previousState} onClick={handleSubmit(handlePreviousClick, onResetScroll)}>
              <HiChevronLeft/>
              {!previousState ? 'Previous State' : previousState.state.name}
            </Button>
            <Button onClick={handleSubmit(handleNextClick, onResetScroll)}>
              {!nextState ? 'Review' : nextState.state.name}
              <HiChevronRight/>
            </Button>
          </div>
        </form>
        <RemoveCoverageDialog coverageToRemove={coverageIndexToRemove} onConfirm={handleConfirmRemoveCoverage}/>
      </motion.div>
    </>
  );
};

/**
 * Form fields for a single coverage entry.
 */
const StateCoverageFields = ({
  draft,
  state,
  field,
  index,
  onRemoveCoverage,
  onDuplicateCoverage,
  control
}: {
  draft: DraftCoverageSet;
  state: DraftCoverageSetState;
  field: FieldArrayWithId<UpdateStateCoverageInput, 'coverages', 'id'>;
  index: number;
  control: Control<UpdateStateCoverageInput>;
  onRemoveCoverage: (index: number) => void;
  onDuplicateCoverage: (index: number) => void;
}) => {
  const { data: coverageTagLookups } = useGetCoverageTagLookups({ drugId: draft.drug.id });
  const { data: paTypeLookups } = useGetPaTypeLookups();
  const { toHtml, fromHtml } = useMarkdown();

  const handleRemoveClick = () => {
    onRemoveCoverage(index);
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0 }}
      transition={{ ease: 'easeInOut', duration: 0.3 }}
      className="overflow-hidden rounded-lg bg-gray-50"
      key={field.id}
    >
      <div className="px-4 py-5 sm:p-6">
      <div className="flex justify-end gap-2">
          <Badge title={`Coverage ${index + 1}`}>
            <span className="font-bold">Coverage {index + 1}</span>
          </Badge>
        </div>
        <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 xl:grid-cols-3">

        {/* General Coverage Information section */}
        <div>
          <Subheading>General Coverage Information</Subheading>
          <Text>High level coverage information.</Text>
        </div>
        <div className="md:col-span-2">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 sm:gap-4">
              <Controller
                rules={{
                  required: 'Coverage Tags are required',
                }}
                control={control}
                name={`coverages.${index}.coverageTags` as const}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Field>
                    <Label>Coverage Tags</Label>
                    <MultiSelectList
                      showSelectedInline={true}
                      options={coverageTagLookups ?? []}
                      valueKey={{
                        key: 'coverageTagId',
                        reduce: true
                      }}
                      labelKey="coverageTagName"
                      value={value}
                      onChange={({ value }) => onChange(value)}
                    />
                    {error?.message && <ErrorMessage>{error.message}</ErrorMessage>}
                  </Field>
                )}
              />
            </div>
          </div>

          {/* PDL Information section */}
          <div>
            <Subheading>PDL Information</Subheading>
            <Text>Enter PDL details for {state.state.name}.</Text>
          </div>
          <div className="md:col-span-2">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 sm:gap-4">
              <Controller
                control={control}
                name={`coverages.${index}.pdlStatus` as const}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Field>
                    <Label>Status</Label>
                    <Listbox value={value} onChange={onChange}>
                      {pdlStatusOptions.map((option) => (
                        <ListboxOption key={option.id} value={option.id}>
                          {option.label}
                        </ListboxOption>
                      ))}
                    </Listbox>
                    {error?.message && <ErrorMessage>{error.message}</ErrorMessage>}
                  </Field>
                )}
              />
              <Controller
                control={control}
                name={`coverages.${index}.pdlStatusDate` as const}
                render={({ field, fieldState: { error } }) => (
                  <Field>
                    <Label>PDL Status Date</Label>
                    <Input type="date" {...field}/>
                    {error?.message && <ErrorMessage>{error.message}</ErrorMessage>}
                  </Field>
                )}
              />
              <Controller
                control={control}
                name={`coverages.${index}.pdlStatusEffectiveDate` as const}
                render={({ field, fieldState: { error } }) => (
                  <Field>
                    <Label>Status Effective Date</Label>
                    <Input type="date" {...field}/>
                    {error?.message && <ErrorMessage>{error.message}</ErrorMessage>}
                  </Field>
                )}
              />
            </div>
          </div>

          {/* PA Information section */}
          <div>
            <Subheading>PA Information</Subheading>
            <Text>Enter PA details for {state.state.name}.</Text>
          </div>
          <div className="md:col-span-2">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 sm:gap-4">
              <Controller
                name={`coverages.${index}.paTypes` as const}
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Field>
                    <Label>PA Types</Label>
                    <MultiSelectList
                      showSelectedInline={true}
                      options={paTypeLookups ?? []}
                      valueKey={{
                        key: 'id',
                        reduce: true
                      }}
                      labelKey="label"
                      value={value}
                      onChange={({ value }) => onChange(value)}
                    />
                    {error?.message && <ErrorMessage>{error.message}</ErrorMessage>}
                  </Field>
                )}
              />
              <div className="space-y-4">
                <Controller
                  control={control}
                  name={`coverages.${index}.stepTherapyCount` as const}
                  render={({ field, fieldState: { error } }) => (
                    <Field>
                      <Label>Step Therapy</Label>
                      <Input
                      {...field}
                      type="number"
                      min="0"
                      max="10"
                      />
                      {error?.message && <ErrorMessage>{error.message}</ErrorMessage>}
                    </Field>
                  )}
                />
                <Controller
                  control={control}
                  name={`coverages.${index}.hasAdditionalSteps` as const}
                  render={({ field: { onChange, value } }) => (
                    <CheckboxField>
                      <Checkbox checked={value} onChange={onChange}/>
                      <Label>Has additional steps</Label>
                    </CheckboxField>
                  )}
                />
              </div>
              <Controller
                control={control}
                name={`coverages.${index}.paCriteriaSourceUrl` as const}
                render={({ field, fieldState: { error } }) => (
                  <Field>
                    <Label>PA Criteria Link</Label>
                    <Input {...field}/>
                    {error?.message && <ErrorMessage>{error.message}</ErrorMessage>}
                  </Field>
                )}
              />
              <Controller
                control={control}
                name={`coverages.${index}.hasAutoPa`}
                render={({ field: { value, onChange } }) => (
                  <CheckboxField className="-mb-8">
                    <Label>Has Auto PA?</Label>
                    <Checkbox checked={value} onChange={onChange}/>
                  </CheckboxField>
                )}
              />
              <Controller
                control={control}
                name={`coverages.${index}.paCriteriaDescription` as const}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Field className="col-span-full">
                    <Label>PA Criteria Text</Label>
                    <RichTextEditorInput
                      value={toHtml(value ?? '')}
                      onChange={(value) => onChange(fromHtml(value))}
                      className="min-h-48"
                    />
                    {error?.message && <ErrorMessage>{error.message}</ErrorMessage>}
                  </Field>
                )}
              />
            </div>
          </div>

          {/* Supporting Information section */}
          <div>
            <Subheading>Supporting Information</Subheading>
            <Text>Enter supporting information for {state.state.name}.</Text>
          </div>
          <div className="md:col-span-2">
            <Controller
              control={control}
              defaultValue=""
              name={`coverages.${index}.notes` as const}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Field className="col-span-full">
                  <Label>Notes</Label>
                  <RichTextEditorInput
                      value={toHtml(value ?? '')}
                      onChange={(value) => onChange(fromHtml(value))}
                      className="min-h-48"
                    />
                  {error?.message && <ErrorMessage>{error.message}</ErrorMessage>}
                </Field>
              )}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-end gap-2 px-4 py-5 sm:p-6">
        <Button outline color="red" onClick={handleRemoveClick} type="button">
          <HiMinus/>
          Remove
        </Button>
        <Button outline onClick={() => onDuplicateCoverage(index)} type="button">
          <HiDuplicate/>
          Duplicate
        </Button>
      </div>
    </motion.div>
  );
};

/**
 * Displays a header for the state coverage step, showing the state name and the number of coverages for the state.
 */
const StateCoverageStepHeader = ({
  draft,
  state,
  coverageCount,
}: {
  draft: DraftCoverageSet;
  state: DraftCoverageSetState;
  coverageCount: number;
}) => {
  const completedStates = draft.states.filter(it => it.isComplete);
  const states = sortBy(draft.states, (state) => state.state.name);
  const stateCode = state.state.code;
  const stateName = state.state.name;

  const [stateToRemove, setStateToRemove] = useState<StateRef | undefined>();

  const handleRemoveStateClick = () => {
    setStateToRemove(state.state);
  };

  return (
    <div className="sticky top-0 z-10 -mt-4 flex flex-col gap-4 border-b border-gray-200 bg-white py-4">
      <div className="flex w-full items-center justify-between">
        <div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, type: 'tween', ease: 'easeInOut' }}
            key={stateCode}
            className="flex items-center gap-2"
          >
            <Heading>{stateName} State Coverage</Heading>
            <div>
              <Badge title={`${coverageCount} coverages for ${state}`}>
                <span className="font-bold">
                  {coverageCount}
                </span>
              </Badge>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, type: 'tween', ease: 'easeInOut' }}
          >
            <Text>Coverage information specific to the state of {stateName}.</Text>
          </motion.div>
        </div>
        <Button outline color="red" onClick={handleRemoveStateClick}>
          Remove State
        </Button>
      </div>
      <div className="w-full space-y-2">
        <div className="flex flex-row justify-between">
          <span>States completed</span>
          <span>{completedStates.length} of {states.length}</span>
        </div>
        <Progress value={completedStates.length} max={states.length}/>
      </div>
      <RemoveStateDialog
        stateToRemove={stateToRemove}
        draftId={draft.id}
        draftStates={states}
        onClose={() => setStateToRemove(undefined)}
      />
    </div>
  );
};
