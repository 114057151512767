import { FormField, TextArea, TextInput } from 'grommet';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import styled from 'styled-components';

import { ClassificationFilter } from '@/components/classification-filter';
import { ClinicalsProductFilter } from '@/components/clinicals-product-filter';
import { LookupSelect } from '@/components/form-controls';

import { useBoardMeetingsProvider } from './board-meetings-provider';
import { BoardMeetingFormData } from './types';

type MeetingPurposeSectionProps = {
  id: string;
  control: Control<BoardMeetingFormData>
};

/**
 * Contains the fields that indicate what a Board Meeting is _for_. This translates to things like:
 * - the state the meeting is for
 * - which drugs and/or classes are being discussed
 * - which board is meeting
 */
export const MeetingPurposeSection = ({ id, control }: MeetingPurposeSectionProps) => {
  const { states, meetingTypes } = useBoardMeetingsProvider();

  return (
    <FirstSection>
      <Controller
        name="state"
        control={control}
        rules={{
          required: 'State is a required field'
        }}
        render={({ field: { ref, value, onChange, onBlur, name }, fieldState: { error } }) => (
          <LookupSelect
            multiple={false}
            options={states.map((state) => ({ id: state.code, label: state.pool ? `${state.name} - ${state.pool.name}` : state.name }))}
            required
            name={name}
            placeholder="Select a State"
            label="State"
            value={value ?? ''}
            error={error?.message}
            onBlur={onBlur}
            onChange={onChange}
            ref={ref}
          />
        )}
      />
      <Controller
        name="meetingTypeId"
        control={control}
        rules={{
          required: 'Meeting Type is a required field'
        }}
        render={({ field: { ref, value, onChange, onBlur, name }, fieldState: { error } }) => (
          <LookupSelect
            multiple={false}
            options={meetingTypes}
            required
            name={name}
            placeholder="Select a Meeting Type"
            label="Meeting Type"
            value={value ?? 0}
            error={error?.message}
            onBlur={onBlur}
            onChange={onChange}
            ref={ref}
          />
        )}
      />
      <Controller
        control={control}
        name="discussedClassifications"
        render={({ field: { value, onChange } }) => (
          <ClassificationFilter
            label="Classes"
            placeholder="Select Classes"
            selected={value}
            onSelectionChange={onChange}
            showChips
          />
        )}
      />
      <Controller
        control={control}
        name="discussedDrugs"
        render={({ field: { value, onChange } }) => (
          <ClinicalsProductFilter
            label="Drugs"
            placeholder="Select Drugs"
            dataSource="drugs"
            selected={value}
            onSelectionChange={onChange}
            showChips
          />
        )}
      />
      <Controller
        name="recordingLink"
        control={control}
        render={({ field: { ref, value, onChange, onBlur, name }, fieldState: { error } }) => (
          <FormField
            name={name}
            htmlFor={`${id}-${name}`}
            label="Recording Link"
            error={error?.message}
            margin="none"
          >
            <TextInput
              id={`${id}-${name}`}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              value={value ?? ''}
              ref={ref}
            />
          </FormField>
        )}
      />
      <Controller
        name="speakerPoliciesLink"
        control={control}
        render={({ field: { ref, value, onChange, onBlur, name }, fieldState: { error } }) => (
          <FormField
            name={name}
            htmlFor={`${id}-${name}`}
            label="Speaker Policies Link"
            error={error?.message}
            margin="none"
          >
            <TextInput
              id={`${id}-${name}`}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              value={value ?? ''}
              ref={ref}
            />
          </FormField>
        )}
      />
      <Controller
        name="agendaLink"
        control={control}
        render={({ field: { ref, value, onChange, onBlur, name }, fieldState: { error } }) => (
          <FormField
            name={name}
            htmlFor={`${id}-${name}`}
            label="Agenda Link"
            error={error?.message}
            margin="none"
          >
            <TextInput
              id={`${id}-${name}`}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              value={value ?? ''}
              ref={ref}
            />
          </FormField>
        )}
      />
      <Controller
        name="meetingMinutesLink"
        control={control}
        render={({ field: { ref, value, onChange, onBlur, name }, fieldState: { error } }) => (
          <FormField
            name={name}
            htmlFor={`${id}-${name}`}
            label="Meeting Minutes Link"
            error={error?.message}
            margin="none"
          >
            <TextInput
              id={`${id}-${name}`}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              value={value ?? ''}
              ref={ref}
            />
          </FormField>
        )}
      />
      <Controller
        name="members"
        control={control}
        render={({ field: { ref, value, onChange, onBlur, name }, fieldState: { error } }) => (
          <FormField
            name={name}
            htmlFor={`${id}-${name}`}
            style={{ gridColumnStart: 'span 2' }}
            label="Board Members"
            error={error?.message}
            margin="none"
          >
            <TextArea
              id={`${id}-${name}`}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              value={value ?? ''}
              ref={ref}
              resize="vertical"
            />
          </FormField>
        )}
      />
      <Controller
        name="locationNotes"
        control={control}
        render={({ field: { ref, value, onChange, onBlur, name }, fieldState: { error } }) => (
          <FormField
            name={name}
            htmlFor={`${id}-${name}`}
            style={{ gridColumnStart: 'span 2' }}
            label="Notes - Client View"
            error={error?.message}
            margin="none"
          >
            <TextArea
              style={{ background: 'white' }}
              id={`${id}-${name}`}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              value={value ?? ''}
              ref={ref}
            />
          </FormField>
        )}
      />
      <Controller
        name="locationInternalNotes"
        control={control}
        render={({ field: { ref, value, onChange, onBlur, name }, fieldState: { error } }) => (
          <FormField
            name={name}
            htmlFor={`${id}-${name}`}
            style={{ gridColumnStart: 'span 2' }}
            label="Notes - Internal View"
            error={error?.message}
            margin="none"
          >
            <TextArea
              style={{ background: 'white' }}
              id={`${id}-${name}`}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              value={value ?? ''}
              ref={ref}
            />
          </FormField>
        )}
      />
    </FirstSection>
  );
};

const FirstSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content max-content max-content;
  gap: 1rem;
  background: ${({ theme }) => theme.global.colors['light-3']};
  padding: 1.5rem;
`;
