import { artiaProtectedApi } from "@/lib/api-client";
import { Lookup } from "@/types/lookup";
import { useQuery } from "@tanstack/react-query";

const getBoardMeetingTypes = async (): Promise<Lookup[]> => {
  return await artiaProtectedApi.get('lookups/board-meeting-types');
};

export const useGetBoardMeetingTypes = () => {
  return useQuery({
    queryKey: ['boardMeetingTypes'],
    queryFn: async () => getBoardMeetingTypes()
  });
};
