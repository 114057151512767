import { useQuery } from '@tanstack/react-query';

import { useLookupsService } from '@/hooks/use-lookups-service';
import { Lookup } from '@/types/lookup';

export const useGetProductLookups = () => {
  const { getProductLookups } = useLookupsService();

  return useQuery({
    queryKey: ['productLookups'],
    queryFn: async () => await getProductLookups() as unknown as Promise<Lookup[]>,
  });
};
