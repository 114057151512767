import { useAsync } from '@shared/async';
import { useMarkdown } from '@shared/markdown/use-markdown';
import { PaTypeCell } from '@shared/pa-info/pa-type-cell';
import { isValidDate } from '@shared/validators/date-time-validators';
import {
  Box,
  Button,
  CheckBox,
  FormField,
  MaskedInput,
  RadioButtonGroup,
  Select,
  SelectMultiple,
  Text,
  TextInput,
} from 'grommet';
import { Close, Edit } from 'grommet-icons';
import { isNil } from 'lodash';
import React, { FormEventHandler, KeyboardEvent, ReactNode, useEffect, useMemo, useRef } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import styled from 'styled-components';

import { Date } from '@/components/date-time';
import {
  BorderlessFormField,
  DateTextInput,
  InMemorySelectList,
  LazyLoadMultiSelectList,
  LazyLoadSelectList
} from '@/components/form-controls';
import { Field } from '@/components-new/fieldset';
import { RichTextEditorInput, RichTextPreview } from '@/components-new/rich-text-editor';
import { changeLogReasonOptions } from '@/features/changelog/types';
import {
  EditCoverageRequest,
  parseStepTherapyCount
} from '@/features/coverage/api/use-coverage-service';
import { PdlStatusCell } from '@/features/coverage/components/index/pdl-status-cell';
import { PdlStatus, pdlStatusOptions } from '@/features/coverage/types/pdl-status';
import { useLookupsService } from '@/hooks/use-lookups-service';
import { Lookup } from '@/types/lookup';

type FieldName = keyof EditCoverageRequest;

type ClinicalsProductEditFormProps = {

  /**
   * ID of the `form` element, required. Necessary to associate a submit button with the form.
   */
  formId: string;

  /**
   * Submit handler for the form, required. Usually wrapped by React Hook Form's `handleSubmit`.
   */
  onSubmit: FormEventHandler<HTMLDivElement>;

  /**
   * Indicates what the form is being used for, defaults to 'single-edit'. Primarily, this indicates which fields
   * should be shown to the user.
   *
   * The options are:
   * - 'bulk-edit': hides "Notes", "State", and "Drug" fields, since those don't make sense to allow bulk edits for
   * - 'single-edit': hides "State" and "Drug" fields, since those are only needed for initial creation
   * - 'add': shows all fields
   */
  mode?: 'bulk-edit' | 'single-edit' | 'add';

  /**
   * Dictates the content to show when a field isn't editable, optional.
   * The valid values are:
   * - show-default-value: shows the default value for the field
   * - show-no-change: shows the text "No Change"
   */
  fieldDisplayMode?: 'show-default-value' | 'show-no-change';

  /**
   * The fields that are editable by the user, optional. If not provided, all fields will be shown as editable.
   */
  visibleFields?: FieldName[];

  /**
   * Callback for when the user selects a field to edit.
   * @param fieldName the name of the field
   */
  onFieldShow?: (fieldName: FieldName) => void;

  /**
   * Callback for when the user hides a field
   * @param fieldName the name of the field
   */
  onFieldHide?: (fieldName: FieldName) => void;

  /**
   * Any content to display at the top of the form, optional.
   */
  children?: ReactNode;
};


/**
 * Represents a form for editing clinicals product.
 */
export const ClinicalsProductEditForm = (props: ClinicalsProductEditFormProps) => {
  const {
    formId,
    onSubmit,
    mode = 'single-edit',
    fieldDisplayMode = 'show-no-change',
    visibleFields,
    onFieldShow,
    onFieldHide,
    children
  } = props;

  const form = useFormContext<EditCoverageRequest>();

  const { toHtml, fromHtml } = useMarkdown();

  const { getPaTypeLookups } = useLookupsService();

  const paTypesAsync = useAsync(getPaTypeLookups);

  useEffect(() => {
    void paTypesAsync.execute();
    // TODO: update this to useQuery so we no longer need this effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      id={formId}
      as="form"
      fill="horizontal"
      onSubmit={onSubmit}
      gap="medium"
    >
      {children}
      {/* use the default for Change Reason on creation */}
      {mode !== 'add' ? (
        <Controller
          name="changeReason"
          control={form.control}
          render={({ field: { name, onChange, onBlur, value } }) => (
            <Box>
              <Text weight="bold">Reason for Change</Text>
              <BorderlessFormField
                name={name}
                htmlFor={`${formId}-${name}`}
              >
                <RadioButtonGroup
                  name={name}
                  id={`${formId}-${name}`}
                  value={value}
                  options={changeLogReasonOptions.map((reason) => ({ ...reason, value: reason.id }))}
                  onChange={onChange}
                  onBlur={onBlur}
                  direction="row"
                />
              </BorderlessFormField>
            </Box>
          )}
        />
      ) : null}
      <FieldsContainer>
        <AddProductFields {...props}/>
        <Controller
          name="pdlStatusDate"
          control={form.control}
          rules={{
            // @ts-expect-error TS(2322): Type '(value: string) => string | undefined' is no... Remove this comment to see the full error message
            validate: isValidDate
          }}
          render={({ field: { value, onChange, onBlur, name }, fieldState: { error } }) => (
            <VerticalFormField
              htmlFor={`${formId}-${name}`}
              label="PDL Status Date"
              actions={visibleFields && onFieldShow && onFieldHide ? (
                <FieldActions
                  name={name}
                  visibleFields={visibleFields}
                  onFieldShow={onFieldShow}
                  onFieldHide={onFieldHide}
                />
              ) : null}
              name={name}
              visibleFields={visibleFields}
              error={error?.message}
              // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
              nonEditableText={fieldDisplayMode === 'show-default-value' ? <Date value={value}/> : 'No Change'}
            >
              {!visibleFields || visibleFields.includes(name) ? (
                <DateTextInput
                  name={name}
                  id={`${formId}-${name}`}
                  value={value}
                  onChange={(event) => onChange((event.target as any).value)}
                  onBlur={onBlur}
                />
              ) : (
                <div>No Change</div>
              )}
            </VerticalFormField>
          )}
        />
        <Controller
          name="pdlStatusEffectiveDate"
          control={form.control}
          rules={{
            // @ts-expect-error TS(2322): Type '(value: string) => string | undefined' is no... Remove this comment to see the full error message
            validate: isValidDate
          }}
          render={({ field: { value, onChange, onBlur, name }, fieldState: { error } }) => (
            <VerticalFormField
              htmlFor={`${formId}-${name}`}
              label="Status Change Effective Date"
              actions={visibleFields && onFieldShow && onFieldHide ? (
                <FieldActions
                  name={name}
                  visibleFields={visibleFields}
                  onFieldShow={onFieldShow}
                  onFieldHide={onFieldHide}
                />
              ) : null}
              name={name}
              visibleFields={visibleFields}
              error={error?.message}
              // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
              nonEditableText={fieldDisplayMode === 'show-default-value' ? <Date value={value}/> : 'No Change'}
            >
              <DateTextInput
                name={name}
                id={`${formId}-pdlStatusEffectiveDate`}
                value={value}
                onChange={(event) => onChange((event.target as any).value)}
                onBlur={onBlur}
              />
            </VerticalFormField>
          )}
        />
        <Controller
          name="pdlStatus"
          control={form.control}
          rules={{
            validate: (value) => {
              if (value === PdlStatus.BLANK) {
                return 'Please select a valid PDL Status';
              }
              return true;
            }
          }}
          render={({ field: { value, onChange, onBlur, name, ref }, fieldState: { error } }) => (
            <VerticalFormField
              htmlFor={`${formId}-${name}`}
              label="PDL Status"
              actions={visibleFields && onFieldShow && onFieldHide ? (
                <FieldActions
                  name={name}
                  visibleFields={visibleFields}
                  onFieldShow={onFieldShow}
                  onFieldHide={onFieldHide}
                />
              ) : null}
              name={name}
              visibleFields={visibleFields}
              error={error?.message}
              nonEditableText={fieldDisplayMode === 'show-default-value' ? <PdlStatusCell pdlStatus={value}/> : 'No Change'}
            >
              <Select
                name={name}
                id={`${formId}-${name}`}
                placeholder="Select a PDL Status"
                options={pdlStatusOptions}
                // @ts-expect-error TS(2322): Type '{ id: PdlStatus; label: string | null; } | n... Remove this comment to see the full error message
                value={pdlStatusOptions.find(x => x.id === value) ?? null}
                disabledKey="disabled"
                labelKey="label"
                valueKey="id"
                onBlur={onBlur}
                onChange={({ value: nextValue }) => onChange(nextValue ? nextValue.id : null)}
                ref={ref}
                clear={{ label: 'Clear Selection' }}
              />
            </VerticalFormField>
          )}
        />
        <Controller
          name="paTypes"
          control={form.control}
          render={({ field: { value, onChange, onBlur, name, ref }, fieldState: { error } }) => (
            <VerticalFormField
              htmlFor={`${formId}-${name}`}
              label="PA Types"
              actions={visibleFields && onFieldShow && onFieldHide ? (
                <FieldActions
                  name={name}
                  visibleFields={visibleFields}
                  onFieldShow={onFieldShow}
                  onFieldHide={onFieldHide}
                />
              ) : null}
              name={name}
              visibleFields={visibleFields}
              error={error?.message}
              nonEditableText={fieldDisplayMode === 'show-default-value'
                ? <PaTypeCell format="pa-type" type="text" paTypes={value}/>
                : 'No Change'
              }
            >
              <SelectMultiple
                name={name}
                id={`${formId}-${name}`}
                placeholder="Select PA Types"
                options={paTypesAsync.value ?? []}
                value={value}
                labelKey="label"
                valueKey="id"
                onBlur={onBlur}
                onChange={({ value: nextValue }: { value: Lookup[] }) => onChange(nextValue)}
                ref={ref}
              />
            </VerticalFormField>
          )}
        />
        <Controller
          name="stepTherapyCount"
          control={form.control}
          rules={{
            validate: (value) => {
              if (!value) {
                return true;
              }
              const result = parseStepTherapyCount(value);
              if (typeof result === 'string') {
                return result;
              }
              return true;
            }
          }}
          render={({ field: { value, onChange, onBlur, name }, fieldState: { error } }) => (
            <VerticalFormField
              htmlFor={`${formId}-${name}`}
              label="Step Therapy"
              actions={visibleFields && onFieldShow && onFieldHide ? (
                <FieldActions
                  name={name}
                  visibleFields={visibleFields}
                  onFieldShow={onFieldShow}
                  onFieldHide={onFieldHide}
                />
              ) : null}
              name={name}
              visibleFields={visibleFields}
              error={error?.message}
              nonEditableText={fieldDisplayMode === 'show-default-value' ? value : 'No Change'}
            >
              <MaskedInput
                name={name}
                id={`${formId}-${name}`}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                mask={[{ regexp: /^[1-9]?[0-9]\+?$/ }]}
              />
            </VerticalFormField>
          )}
        />
        <Controller
          name="paCriteriaSourceUrl"
          control={form.control}
          rules={{
            validate: (value) => {
              // empty value is valid
              if (!value) {
                return true;
              }
              try {
                new URL(value);
                return true;
              } catch {
                return 'Must be a valid URL';
              }
            }
          }}
          render={({ field: { value, onChange, onBlur, name, ref }, fieldState: { error } }) => (
            <VerticalFormField
              htmlFor={`${formId}-${name}`}
              label="PA Criteria Link"
              actions={visibleFields && onFieldShow && onFieldHide ? (
                <FieldActions
                  name={name}
                  visibleFields={visibleFields}
                  onFieldShow={onFieldShow}
                  onFieldHide={onFieldHide}
                />
              ) : null}
              name={name}
              visibleFields={visibleFields}
              error={error?.message}
              nonEditableText={fieldDisplayMode === 'show-default-value'
                ? value
                  ? value
                  : null
                : 'No Change'
              }
            >
              <TextInput
                name={name}
                id={`${formId}-${name}`}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
              />
            </VerticalFormField>
          )}
        />
        <Controller
          name="hasAutoPa"
          control={form.control}
          render={({ field: { value, onChange, onBlur, name }, fieldState: { error } }) => (
            <VerticalFormField
              alreadyHasFormField
              htmlFor={`${formId}-${name}`}
              label="Has Auto PA?"
              actions={visibleFields && onFieldShow && onFieldHide ? (
                <FieldActions
                  name={name}
                  visibleFields={visibleFields}
                  onFieldShow={onFieldShow}
                  onFieldHide={onFieldHide}
                />
              ) : null}
              name={name}
              visibleFields={visibleFields}
              error={error?.message}
              nonEditableText={fieldDisplayMode === 'show-default-value' ? (value ? 'Yes' : 'No') : 'No Change'}
            >
              {!visibleFields || visibleFields.includes(name) ? (
                <CheckBox
                  name={name}
                  id={`${formId}-${name}`}
                  checked={value}
                  onChange={(event) => onChange(event.target.checked)}
                  onBlur={onBlur}
                />
              ) : (
                <div>No Change</div>
              )}
            </VerticalFormField>
          )}
        />
        <Controller
          name="paCriteriaDescription"
          control={form.control}
          render={({ field: { value, onChange, name, ref }, fieldState: { error } }) => (
            <VerticalFormField
              alreadyHasFormField
              htmlFor={`${formId}-${name}`}
              label="PA Criteria Text"
              actions={visibleFields && onFieldShow && onFieldHide ? (
                <FieldActions
                  name={name}
                  visibleFields={visibleFields}
                  onFieldShow={onFieldShow}
                  onFieldHide={onFieldHide}
                />
              ) : null}
              name={name}
              visibleFields={visibleFields}
              error={error?.message}
              nonEditableText={fieldDisplayMode === 'show-default-value'
                ? value
                  ? <RichTextPreview content={toHtml(value)}/>
                  : null
                : 'No Change'}
            >
              <Field>
                <RichTextEditorInput
                  className="max-h-96"
                  onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
                    if (event.key === 'Enter') {
                      event.stopPropagation();
                    }
                  }}
                  ref={ref}
                  value={toHtml(value ?? '')}
                  onChange={(html) => onChange(fromHtml(html))}
                  placeholder="Fill out PA Criteria..."
                />
              </Field>
            </VerticalFormField>
          )}
        />
        {/* Can't edit Notes in bulk edit mode */}
        {mode !== 'bulk-edit' ? (
          <Controller
            name="notes"
            control={form.control}
            render={({ field: { value, onChange, name, ref }, fieldState: { error } }) => (
              <VerticalFormField
                alreadyHasFormField
                htmlFor={`${formId}-${name}`}
                label="Notes"
                actions={visibleFields && onFieldShow && onFieldHide ? (
                  <FieldActions
                    name={name}
                    visibleFields={visibleFields}
                    onFieldShow={onFieldShow}
                    onFieldHide={onFieldHide}
                  />
                ) : null}
                name={name}
                visibleFields={visibleFields}
                error={error?.message}
                nonEditableText={fieldDisplayMode === 'show-default-value'
                  ? value
                    ? <RichTextPreview content={toHtml(value)}/>
                    : null
                  : 'No Change'}
              >
                <Field>
                  <RichTextEditorInput
                    className="max-h-96"
                    onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
                      if (event.key === 'Enter') {
                        event.stopPropagation();
                      }
                    }}
                    ref={ref}
                    value={toHtml(value ?? '')}
                    onChange={(html) => onChange(fromHtml(html))}
                    placeholder="Additional change notes..."
                  />
                </Field>
              </VerticalFormField>
            )}
          />
        ) : null}
      </FieldsContainer>
    </Box>
  );
};

const AddProductFields = ({ mode, formId, visibleFields, onFieldShow, onFieldHide, fieldDisplayMode }: ClinicalsProductEditFormProps) => {
  const form = useFormContext<EditCoverageRequest>();
  const { setValue } = form;
  const { getProductLookups, getStates, getCoverageTags } = useLookupsService();
  const drugSelection = useWatch({ control: form.control, name: 'drug' });

  const previousDrugSelectionRef = useRef<Lookup | undefined>();

  const statesAsync = useAsync(getStates);

  useEffect(() => {
    void statesAsync.execute();
    // TODO: update this to use react query, there are more idiomatic ways of handling this type of useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (previousDrugSelectionRef.current?.id !== drugSelection?.id) {
      setValue('coverageTags', []);
    }
    previousDrugSelectionRef.current = drugSelection;
  }, [drugSelection, setValue]);

  const stateOptions = useMemo(() => {
    if (!statesAsync.value) return [];

    return statesAsync.value.map(it => ({ id: it.id, label: it.name }));
  }, [statesAsync.value]);

  const drugField = (
    <Controller
      name="drug"
      control={form.control}
      rules={{
        required: !visibleFields || visibleFields.includes('drug') ? 'Drug is a required field' : false
      }}
      render={({ field: { value, onChange, onBlur, name, ref }, fieldState: { error } }) => (
        <VerticalFormField
          htmlFor={`${formId}-${name}`}
          label="Drug"
          actions={visibleFields && onFieldShow && onFieldHide ? (
            <FieldActions
              name={name}
              visibleFields={visibleFields}
              onFieldShow={onFieldShow}
              onFieldHide={onFieldHide}
            />
          ) : null}
          name={name}
          visibleFields={visibleFields}
          error={error?.message}
          nonEditableText={fieldDisplayMode === 'show-default-value' ? value?.label : 'No Change'}
          alreadyHasFormField
        >
          {!visibleFields || visibleFields.includes(name) ? (
            <LazyLoadSelectList
              value={value}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              ref={ref}
              placeholder="Select a Drug"
              lazyLoadRequest={(searchTerm, page, rpp) => {
                return getProductLookups({ query: searchTerm, page, rpp });
              }}
            />
          ) : (
            <div>No Change</div>
          )}
        </VerticalFormField>
      )}
    />
  );

  const additionalTagsField = (
    <Controller
      name="coverageTags"
      control={form.control}
      rules={{
        required: false
      }}
      render={({ field: { value, onChange, onBlur, name }, fieldState: { error } }) => (
        <VerticalFormField
          htmlFor={`${formId}-${name}`}
          label="Coverage Tags"
          actions={visibleFields && onFieldShow && onFieldHide ? (
            <FieldActions
              name={name}
              visibleFields={visibleFields}
              onFieldShow={onFieldShow}
              onFieldHide={onFieldHide}
            />
          ) : null}
          help={isNil(drugSelection) ? 'You must select a drug before adding tags.' : undefined}
          name={name}
          visibleFields={visibleFields}
          error={error?.message}
          nonEditableText={fieldDisplayMode === 'show-default-value' ? value?.map((lookup: Lookup) => lookup.label).join(', ') : 'No Change'}
          alreadyHasFormField
        >
          {!visibleFields || visibleFields.includes(name) ? (
              <LazyLoadMultiSelectList
                key={previousDrugSelectionRef?.current?.id}
                disabled={isNil(drugSelection?.id)}
                showSelectedInline
                placeholder="Select Coverage Tags (Optional)"
                value={value}
                error={error?.message}
                onChange={onChange}
                onBlur={onBlur}
                lazyLoadRequest={async (searchTerm, page, rpp) => {
                  const parameters = {
                    query: searchTerm,
                    page,
                    rpp,
                    drugId: drugSelection?.id
                  };

                  const result = await getCoverageTags(parameters);
                  result.items = result.items
                    .filter(tag => tag.nested)
                    .map(tag => {
                        return {
                          ...tag,
                          nested: false,
                          label: tag.label.replace(drugSelection?.label || '', '').trim()
                        };
                    });

                  return result;
                }}
              />
          ) : (
            <div>No Change</div>
          )}
        </VerticalFormField>
      )}
    />
  );

  const stateField = (
    <Controller
      name="state"
      control={form.control}
      rules={{
        required: !visibleFields || visibleFields.includes('state') ? 'State is a required field' : false
      }}
      render={({ field: { value, onChange, onBlur, name, ref }, fieldState: { error } }) => (
        <VerticalFormField
          htmlFor={`${formId}-${name}`}
          label="State"
          actions={visibleFields && onFieldShow && onFieldHide ? (
            <FieldActions
              name={name}
              visibleFields={visibleFields}
              onFieldShow={onFieldShow}
              onFieldHide={onFieldHide}
            />
          ) : null}
          name={name}
          visibleFields={visibleFields}
          error={error?.message}
          nonEditableText={fieldDisplayMode === 'show-default-value' ? value?.label : 'No Change'}
          alreadyHasFormField
        >
          {!visibleFields || visibleFields.includes(name) ? (
            <InMemorySelectList
              value={value}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              ref={ref}
              placeholder="Select a State"
              options={stateOptions as Lookup[]}
            />
          ) : (
            <div>No Change</div>
          )}
        </VerticalFormField>
      )}
    />
  );

  switch (mode) {
    case 'add':
      return (
        <>
          {drugField}
          {additionalTagsField}
          {stateField}
        </>
      );
    default:
      return null;
  }
};

type FieldActionsProps = {
  name: FieldName;
  visibleFields: FieldName[];
  onFieldShow: (field: FieldName) => void;
  onFieldHide: (field: FieldName) => void;
}

const FieldActions = ({ name, visibleFields, onFieldShow, onFieldHide }: FieldActionsProps) => {
  if (visibleFields.includes(name)) {
    return (
      <Button
        hoverIndicator
        icon={<Close/>}
        a11yTitle="Cancel"
        tip="Cancel"
        onClick={() => onFieldHide(name)}
      />
    );
  }

  return (
    <Button
      hoverIndicator
      icon={<Edit/>}
      a11yTitle="Edit"
      tip="Edit"
      onClick={() => onFieldShow(name)}
    />
  );
};

const FieldsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: max-content;
    grid-auto-flow: row;
    align-items: center;
    row-gap: 1.5rem;
    column-gap: 0.5rem;
`;

type VerticalFormFieldProps = {
  htmlFor: string;
  label: string;
  help?: string;
  actions?: ReactNode;
  children: ReactNode;
  error?: string;
  name: FieldName;
  visibleFields?: FieldName[];
  nonEditableText: ReactNode;
  alreadyHasFormField?: boolean;
};

const VerticalFormField = ({ htmlFor, label, actions, children, error, name, help, visibleFields, nonEditableText, alreadyHasFormField }: VerticalFormFieldProps) => {
  const content = alreadyHasFormField
    ? <>{children}</>
    : <FormField margin="none">{children}</FormField>;

  return (
    <>
      <LabelContainer>
        <label htmlFor={htmlFor}>
          <Text weight="bold">{label}</Text>
        </label>
        {actions}
      </LabelContainer>
      <FieldContainer>
        {!visibleFields || visibleFields.includes(name) ? content : (
          <Text>{nonEditableText ?? <>&mdash;</>}</Text>
        )}
        {error && <Text color="error" size="small">{error ? error : null}</Text>}
        {!error && (<Text color="placeholder" size="small">{help ? help : null}</Text>)}
      </FieldContainer>
    </>
  );
};

const LabelContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
`;

const FieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
`;
