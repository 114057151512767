import { useForm, Controller } from 'react-hook-form';
import { RhfFilterForm } from '@/components/filters';
import { InMemoryMultiSelectList } from '@/components/form-controls';
import { MultiSelectOption } from '@/components/form-controls/multi-select-list';
import { StateFilter } from '@/components/state-filter';
import { generateDefaultCalendarFilters } from '../api';
import { useGetStates } from '@/api/use-states';
import { useGetBoardMeetingTypes } from '@/api/use-board-meeting-types';

export type CalendarFilterData = {
  states: MultiSelectOption<string>[];
  meetingTypes: MultiSelectOption[];
};

type CalendarFiltersProps = {
  defaultValues: CalendarFilterData;
  onSubmit: (filterData: CalendarFilterData) => void;
};

const CalendarFilter = ({ defaultValues, onSubmit }: CalendarFiltersProps) => {
  const { control, handleSubmit, reset, watch } = useForm<CalendarFilterData>({ defaultValues });
  const { data: states = [] } = useGetStates();
  const { data: meetingTypes = [] } = useGetBoardMeetingTypes()
  return (
    <RhfFilterForm
      watch={watch}
      onReset={() => reset(generateDefaultCalendarFilters())}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        control={control}
        name="states"
        render={({ field: { value, onChange } }) => (
          <StateFilter
            states={states}
            selected={value}
            onSelectionChange={onChange}
          />
        )}
      />
      <Controller
        control={control}
        name="meetingTypes"
        render={({ field: { value, onChange } }) => (
          <InMemoryMultiSelectList
            placeholder="Search by Meeting Type"
            options={meetingTypes}
            value={value}
            onChange={onChange}
          />
        )}
      />
    </RhfFilterForm>
  );
};

export default CalendarFilter;
