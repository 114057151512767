import React from 'react';

import { Button } from '@/components-new/button';

type IndeterminateToggleButtonProps<TItem> = {
  /**
   * The currently selected items in the list.
   */
  selectedOptions?: TItem[];
  /**
   * Callback function called when selection changes.
   */
  onChange: (options: TItem[]) => void;
  /**
   * The complete list of available items to select from.
   */
  options: TItem[];
};

/**
 * A button that toggles the ability to select all items in a list if they are 
 * not already selected, or deselect all items if they are.
 * 
 * @param selectedOptions - The currently selected items in the list.
 * @param onChange - Callback function called when selection changes.
 * @param options - The complete list of available items to select from.
 */
export const IndeterminateToggleButton = <TItem, >({
  selectedOptions,
  onChange,
  options
}: IndeterminateToggleButtonProps<TItem>) => {
  const allSelected = selectedOptions?.length === options.length;

  const label = allSelected ? 'Deselect All' : 'Select All';

  const handleClick = () => {
    onChange(allSelected ? [] : options);
  };

  return (
    <Button
      plain
      color="secondary"
      onClick={handleClick}
      aria-label={`${label} items`}
      aria-pressed={allSelected}
    >
      {label}
    </Button>
  );
};
