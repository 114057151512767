import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';

import { notifySuccess } from '@/lib/notification/notifications';
import { MutationConfig, QueryConfig } from '@/lib/react-query';
import { useErrorHandler } from '@/shared/errors';

import {
  addDraftCoverageState,
  completeDraftCoverageSet,
  createDraftCoverageSet,
  deleteDraftCoverageSet,
  deleteDraftCoverageState,
  getDraftCoverageSet,
  getDrafts,
  updateStateDraftCoverages
} from './api';
import { DraftCoverageSet } from './types';
import { DraftCoverageSets } from './types';

export const draftCoverageQueryKeys = {
  all: ['draft-coverages'] as const,
  list: () => [...draftCoverageQueryKeys.all] as const,
  byId: (id: number) => [...draftCoverageQueryKeys.all, id] as const,
};

export const useAddDraftCoverageState = (options?: { mutationConfig?: MutationConfig<typeof addDraftCoverageState>; }) => {
  const queryClient = useQueryClient();
  const { handleError } = useErrorHandler();

  const { onError, onSuccess, ...restConfig } = options?.mutationConfig || {};

  return useMutation({
    onError: (error, ...args) => {
      handleError(error, { title: 'Add Failed', message: 'Unable to add draft coverage state.' });
      onError?.(error, ...args);
    },
    onSuccess: async (...args) => {
      const [_, variables] = args;

      notifySuccess({
        title: 'State Added',
        message: 'Draft coverage state was successfully added.',
      });

      await queryClient.invalidateQueries({ queryKey: draftCoverageQueryKeys.list() });
      await queryClient.invalidateQueries({ queryKey: draftCoverageQueryKeys.byId(variables.draftCoverageSetId) });

      onSuccess?.(...args);
    },
    ...restConfig,
    mutationFn: addDraftCoverageState
  });
};

export const useCreateDraftCoverageSet = (options?: { mutationConfig?: MutationConfig<typeof createDraftCoverageSet>; }) => {
  const queryClient = useQueryClient();
  const { handleError } = useErrorHandler();

  const { onError, onSuccess, ...restConfig } = options?.mutationConfig || {};

  return useMutation({
    onError: (error, ...args) => {
      handleError(error, { title: 'Create Failed', message: error.message });
      onError?.(error, ...args);
    },
    onSuccess: async (...args) => {
      notifySuccess({
        title: 'Created',
        message: 'Draft coverage was successfully created.',
      });

      await queryClient.invalidateQueries({ queryKey: draftCoverageQueryKeys.list() });

      onSuccess?.(...args);

    },
    ...restConfig,
    mutationFn: createDraftCoverageSet,
  });
};

export const useDeleteDraftCoverageSet = (options?: { mutationConfig?: MutationConfig<typeof deleteDraftCoverageSet>; }) => {
  const queryClient = useQueryClient();
  const { handleError } = useErrorHandler();

  const { onMutate, onError, onSuccess, ...restConfig } = options?.mutationConfig || {};

  return useMutation({
    onMutate: async (id: number, ...args) => {
      await queryClient.cancelQueries({ queryKey: draftCoverageQueryKeys.list() });
      const previousDrafts = queryClient.getQueryData<DraftCoverageSets[]>(draftCoverageQueryKeys.list());
      queryClient.setQueryData(draftCoverageQueryKeys.list(), (old: DraftCoverageSets[]) => old.filter((draft: DraftCoverageSets) => draft.id !== id));

      onMutate?.(id, ...args);
      return { previousDrafts };
    },
    onError: (error, id, context, ...args) => {
      handleError(error, { title: 'Delete Failed', message: 'Unable to delete draft coverage set.' });
      queryClient.setQueryData(draftCoverageQueryKeys.list(), context?.previousDrafts);
      onError?.(error, id, context, ...args);
    },
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries({ queryKey: draftCoverageQueryKeys.list() });

      onSuccess?.(...args);
    },
    ...restConfig,
    mutationFn: deleteDraftCoverageSet,
  });
};

export const useDeleteDraftCoverageState = (options?: { mutationConfig?: MutationConfig<typeof deleteDraftCoverageState>; }) => {
  const queryClient = useQueryClient();
  const { handleError } = useErrorHandler();

  const { onMutate, onError, onSuccess, ...restConfig } = options?.mutationConfig || {};

  return useMutation({
    onMutate: async (payload, ...args) => {
      await queryClient.cancelQueries({ queryKey: draftCoverageQueryKeys.list() });
      const previousDraftCoverage = queryClient.getQueryData<DraftCoverageSet>(draftCoverageQueryKeys.byId(payload.draftCoverageSetId));

      const optimisticDraft = cloneDeep(previousDraftCoverage);

      if (optimisticDraft) {
        optimisticDraft.states = optimisticDraft?.states.filter(state => state.state.code !== payload.stateCode);
      }

      queryClient.setQueryData(draftCoverageQueryKeys.byId(payload.draftCoverageSetId), optimisticDraft);

      onMutate?.(payload, ...args);
      return { previousDraftCoverage };
    },
    onError: (error, payload, context, ...args) => {
      handleError(error, { title: 'Delete Failed', message: 'Unable to delete draft coverage state.' });
      queryClient.setQueryData(draftCoverageQueryKeys.byId(payload.draftCoverageSetId), context?.previousDraftCoverage);
      onError?.(error, payload, context, ...args);
    },
    onSuccess: async (...args) => {
      const [_, variables] = args;
      notifySuccess({
        title: 'Deleted',
        message: 'Draft coverage state was successfully deleted.',
      });

      await queryClient.invalidateQueries({ queryKey: draftCoverageQueryKeys.list() });
      await queryClient.invalidateQueries({ queryKey: draftCoverageQueryKeys.byId(variables.draftCoverageSetId) });

      onSuccess?.(...args);
    },
    ...restConfig,
    mutationFn: deleteDraftCoverageState,
  });
};

export const useUpdateStateDraftCoverages = (options?: { mutationConfig?: MutationConfig<typeof updateStateDraftCoverages>; }) => {
  const queryClient = useQueryClient();
  const { handleError } = useErrorHandler();

  const { onError, onSuccess, ...restConfig } = options?.mutationConfig || {};

  return useMutation({
    onError: (error, ...args) => {
      handleError(error, { title: 'Update Failed', message: 'Unable to update draft coverage state.' });
      onError?.(error, ...args);
    },
    onSuccess: async (...args) => {
      const [_, variables] = args;

      await queryClient.invalidateQueries({ queryKey: draftCoverageQueryKeys.list() });
      await queryClient.invalidateQueries({ queryKey: draftCoverageQueryKeys.byId(variables.draftSetId) });

      notifySuccess({
        title: 'Updated',
        message: 'Draft coverage was successfully updated.',
      });

      onSuccess?.(...args);

    },
    ...restConfig,
    mutationFn: updateStateDraftCoverages,
  });
};

export const useGetDrafts = ({ queryConfig }: { queryConfig?: QueryConfig<typeof getDrafts>; } = {}) => {
  return useQuery({
    ...queryConfig,
    queryKey: draftCoverageQueryKeys.list(),
    queryFn: getDrafts,
  });
};

export const useGetDraftCoverage = (id: number) => {
  return useQuery({
    queryKey: draftCoverageQueryKeys.byId(id),
    queryFn: () => getDraftCoverageSet(id),
  });
};

export const  useCompleteDraftCoverageSet = (options?: { mutationConfig?: MutationConfig<typeof completeDraftCoverageSet>; }) => {
  const queryClient = useQueryClient();
  const { handleError } = useErrorHandler();

  const { onError, onMutate, onSuccess, ...restConfig } = options?.mutationConfig || {};

  return useMutation({
    onMutate: async (id, ...args) => {
      queryClient.cancelQueries({ queryKey: draftCoverageQueryKeys.list() });
      queryClient.cancelQueries({ queryKey: draftCoverageQueryKeys.byId(id) });

      onMutate?.(id, ...args);
    },
    onError: (error, ...args) => {
      handleError(error, { title: 'Complete Failed', message: 'Unable to complete draft coverage set.' });

      onError?.(error, ...args);
    },
    onSuccess: async (...args) => {
      notifySuccess({
        title: 'Completed',
        message: 'State coverage was successfully completed.',
      });

      await queryClient.invalidateQueries({ queryKey: draftCoverageQueryKeys.list() });

      onSuccess?.(...args);
    },
    ...restConfig,
    mutationFn: completeDraftCoverageSet
  });
};
