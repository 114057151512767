import { DateTime } from 'luxon';

import { Lookup } from './lookup';
import { State } from './state';

export enum MeetingFormat {
  VIRTUAL = 'Virtual',
  IN_PERSON = 'InPerson',
  HYBRID = 'Hybrid'
}

export const displayMeetingFormat = (format: MeetingFormat) => {
  switch (format) {
    case MeetingFormat.VIRTUAL:
      return 'Virtual';
    case MeetingFormat.IN_PERSON:
      return 'In-Person';
    case MeetingFormat.HYBRID:
      return 'Hybrid';
  }
};

/**
 * Board Meeting type straight from the API.
 */
export type RawBoardMeeting = {
  id: number;
  state: State;
  meetingType: Lookup;
  startTime: string;
  endTime?: string;
  ianaTimeZone: string;
  format: MeetingFormat;
  venueName: string | null;
  meetingRoom: string | null;
  locationStreetLine1: string | null;
  locationStreetLine2: string | null;
  locationCity: string | null;
  locationState: State;
  locationZipCode: string | null;
  locationNotes: string | null;
  locationInternalNotes: string | null;
  webinarLink: string | null;
  webinarPhone: string | null;
  webinarMeetingId: string | null;
  webinarPassword: string | null;
  agendaLink: string | null;
  meetingMinutesLink: string | null;
  members: string | null;
  recordingLink: string | null;
  speakerPoliciesLink: string | null;
  discussedClassifications: Lookup[];
  discussedDrugs: Lookup[];
};

/**
 * Parsed Board Meeting type. Mainly used to converting dates from ISO strings to a useful object
 */
export type BoardMeeting = Omit<RawBoardMeeting, 'startTime' | 'endTime'> & {
  startTime: DateTime;
  endTime: DateTime | null;
};
