import { useSearchParams } from 'react-router-dom';

import { ButtonGroup, ButtonGroupButton } from '@/components-new/button-group';

export const ClientInternalViewToggle = () => {
  const [ searchParams ] = useSearchParams();
  const isClientView = searchParams.get('view') === 'client';

  return (
    <ButtonGroup>
      <ButtonGroupButton
        solid={!isClientView}
        href="/state-reports"
      >
        Internal View
      </ButtonGroupButton>
      <ButtonGroupButton
        solid={isClientView}
        href="/state-reports?view=client"
      >
        Client View
      </ButtonGroupButton>
    </ButtonGroup>
  );
};
