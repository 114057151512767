import { isEmpty,isNil } from 'lodash';
import { DateTime } from 'luxon';
import { z } from 'zod';

import { artiaProtectedApi } from '@/lib/api-client';

import { DraftCoverageSet } from './types';
import { DraftCoverageSets } from './types';

const BASE_API_URL = 'draft-coverage-sets';

export const addDraftCoverageStateInputSchema = z.object({
  draftCoverageSetId: z.number(),
  state: z.string().min(1, { message: 'State is required' }),
});

export type AddDraftCoverageStateInput = z.infer<typeof addDraftCoverageStateInputSchema>;

/**
 * Add a state to an existing draft coverage set.
 */
export const addDraftCoverageState = async ({ draftCoverageSetId, state: stateCode }: AddDraftCoverageStateInput) => {
  return await artiaProtectedApi.post(`${BASE_API_URL}/${draftCoverageSetId}/states`, { stateCode });
};

export const newDraftCoverageSetInputSchema = z.object({
  drug: z.object({ id: z.number(), label: z.string() }, { message: 'A drug is required' }),
  states: z.array(z.string()).min(1, { message: 'At least one state is required.' }),
});

export type NewDraftCoverageSetInput = z.infer<typeof newDraftCoverageSetInputSchema>;

/**
 * Create a new draft coverage set.
 */
export const createDraftCoverageSet = async (data: NewDraftCoverageSetInput): Promise<number> => {
  const payload = {
    drugId: data.drug.id,
    states: data.states,
  };

  return await artiaProtectedApi.post(BASE_API_URL, payload);
};

/**
 * Delete a draft coverage set.
 */
export const deleteDraftCoverageSet = async (id: number) => {
  await artiaProtectedApi.delete(`draft-coverage-sets/${id}`);
};

/**
 * Delete a state from a draft coverage set.
 */
export const deleteDraftCoverageState = async ({
  draftCoverageSetId, stateCode,
}: { draftCoverageSetId: number; stateCode: string; }) => {
  return await artiaProtectedApi.delete(`${BASE_API_URL}/${draftCoverageSetId}/states/${stateCode}`);
};

/**
 * Get a draft coverage set by id.
 */
export const getDraftCoverageSet = async (id: number): Promise<DraftCoverageSet> => {
  return await artiaProtectedApi.get(`${BASE_API_URL}/${id}`);
};

/**
 * Get all draft coverage sets.
 */
export const getDrafts = async (): Promise<DraftCoverageSets[]> => {
  return await artiaProtectedApi.get(BASE_API_URL);
};

/**
 * Input schema for updating state coverages.
 */
export const updateStateCoverageInputSchema = z.object({
  draftSetId: z.number(),
  stateCode: z.string(),
  isComplete: z.boolean(),
  coverages: z.array(z.object({
    coverageTags: z.array(z.number()).optional(),
    stepTherapyCount: z.string()
      .refine(value => {
        if (isNil(value) || isEmpty(value)) return true;

        const parsedValue = parseInt(value, 10);

        return parsedValue >= 0 && parsedValue <= 10;
      }, { message: 'Must be a number between 0 and 10' }),
    hasAdditionalSteps: z.boolean().optional(),
    pdlStatus: z.string().optional(),
    pdlStatusDate: z.string().optional()
      .refine(value => {
        if (!value) return true;

        const parsedDate = DateTime.fromISO(value);

        return parsedDate.isValid;
      }, { message: 'Please enter a valid date.' }),
    pdlStatusEffectiveDate: z.string().optional()
      .refine(value => {
        if (!value) return true;

        const parsedDate = DateTime.fromISO(value);

        return parsedDate.isValid;
      }, { message: 'Please enter a valid date.' }),
    paTypes: z.array(z.number()).optional(),
    paCriteriaSourceUrl: z.string().optional(),
    hasAutoPa: z.boolean().optional(),
    paCriteriaDescription: z.string().optional(),
    notes: z.string().optional(),
  }))
  .superRefine((value, ctx) => {
    const coverageTags = value.map(coverage => JSON.stringify(coverage.coverageTags?.sort()));

    coverageTags.forEach((tag, index) => {
      if (coverageTags.indexOf(tag) !== index) {
        return ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Duplicate coverage tags are not allowed.',
          path: ['root']
        });
      }
    });
  })
});

export type UpdateStateCoverageInput = z.infer<typeof updateStateCoverageInputSchema>;

/**
 * Update state coverages for draft coverage sets state.
 */
export const updateStateDraftCoverages = async ({
  draftSetId, stateCode, coverages, isComplete
}: UpdateStateCoverageInput) => {
  const coveragesData = coverages.map(coverage => {
    // convert empty strings to null
    return Object.keys(coverage).reduce((acc, key) => {
      const value = coverage[key as keyof UpdateStateCoverageInput['coverages'][number]];
      if (typeof value === 'string' && isEmpty(value.trim())) {
        return { ...acc, [key]: null };
      }

      return { ...acc, [key]: value };
    }, {});
  });

  return await artiaProtectedApi.put(`${BASE_API_URL}/${draftSetId}/states/${stateCode}`, { coverages: coveragesData, isComplete });
};

/**
 * Finalize and complete the draft coverage set.
 */
export const completeDraftCoverageSet = async (id: number) => {
  return await artiaProtectedApi.put(`${BASE_API_URL}/${id}/complete`);
};

