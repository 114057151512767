import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { LiaCalendar } from 'react-icons/lia';
import { useSearchParams } from 'react-router-dom';

import { EmptyState, EmptyStateBody, EmptyStateHeading } from '@/components-new/empty-state';
import { generateDefaultCalendarFilters, useCalendarQuery } from '@/features/calendar/api';
import { Calendar } from '@/features/calendar/components/calendar';
import CalendarFilter, { CalendarFilterData } from '@/features/calendar/components/calendar-filter';
import { MeetingDetailsDrawer, MeetingsList } from '@/features/calendar/components/meetings';
import { Meeting } from '@/features/calendar/types/meeting';
import { useDebounce } from '@/hooks/use-debounce';

const getInitialMonth = (month: string | null): DateTime => {
  if (month === null) {
    return DateTime.now();
  }

  return DateTime.fromISO(month);
};

type CalendarMode = 'all' | 'tracked' | 'priority';

type CalendarPageContentsProps = {
  mode: CalendarMode;
  hint: string;
  emptyState?: React.ReactNode;
};

export const CalendarPageContents = ({ mode, hint, emptyState }: CalendarPageContentsProps) => {
  const [params, setParams] = useSearchParams();
  const initialMonth = getInitialMonth(params.get('month'));

  const [month, setMonth] = useState<DateTime>(initialMonth);
  const [selectedDay, setSelectedDay] = useState<DateTime>();

  const [selectedStates, setSelectedStates] = useState<string[]>([]);
  const [selectedMeetingTypes, setSelectedMeetingTypes] = useState<number[]>([]);

  // debounce the search of meetings to avoid spamming if the user navigates quickly
  const monthFilter = useDebounce(month);
  const dayFilter = useDebounce(selectedDay?.toISO() ?? '');
  const statesFilter = useDebounce(selectedStates);
  const meetingTypesFilter = useDebounce(selectedMeetingTypes);

  const [selectedMeeting, setSelectedMeeting] = useState<Meeting | undefined>();

  const { data: meetings, isLoading: isMeetingsLoading } = useCalendarQuery({
    filters: {
      month: monthFilter.toFormat('yyyy-MM'),
      day: dayFilter,
      tracked: mode === 'tracked' || mode === 'priority',
      priority: mode === 'priority',
      states: statesFilter,
      meetingTypes: meetingTypesFilter
    }
  });

  const daysWithEvents = useMemo(() => {
    return (
      meetings?.map((meeting) => {
        const startTime = DateTime.fromISO(meeting.startTime);
        startTime.setZone(meeting.timezone);
        return startTime.toFormat('yyyy-MM-dd');
      }) ?? []
    );
  }, [meetings]);

  const showMeetingDetails = (meeting: Meeting) => {
    setSelectedMeeting(meeting);
  };

  const onCalendarNavigate = (newMonth: DateTime) => {
    setSelectedMeeting(undefined);
    setParams(`month=${newMonth.toFormat('yyyy-MM')}`);
    setMonth(newMonth);
    setSelectedDay(undefined);
  };

  const onDayClick = (day?: DateTime) => { 
    setSelectedDay(day);
  };
  
  

  const handleApplyFilters = (filters: CalendarFilterData) => {
    setSelectedStates(filters.states.map(it => it.id));
    setSelectedMeetingTypes(filters.meetingTypes.map(it => it.id));
  };

  return (
    <div className="mt-4">
      <h2 className="text-lg font-semibold leading-6 text-zinc-950 dark:text-white">{`${monthFilter.monthLong} Meetings`}</h2>
      <p className="mt-1 text-sm text-gray-700">{hint}</p>
      <div className="lg:grid lg:grid-cols-12 lg:gap-x-16">
        <Calendar
          className="mt-10 text-center lg:col-start-1 lg:col-end-5 lg:row-start-1 lg:mt-9 xl:col-start-1"
          current={month}
          daysWithEvents={daysWithEvents}
          onChange={onCalendarNavigate}
          onDayClick={onDayClick}
        />

        <div className="relative mt-4 min-h-20 lg:col-span-7 xl:col-span-8">
          <CalendarFilter defaultValues={generateDefaultCalendarFilters()} onSubmit={handleApplyFilters} />
          <MeetingsList
            isLoading={isMeetingsLoading}
            meetings={meetings}
            emptyState={
              <EmptyState>
                <LiaCalendar className="size-12 text-gray-500" />
                <EmptyStateHeading>
                  {selectedDay
                    ? `No meetings on ${monthFilter.monthLong} ${selectedDay.toFormat('dd')}`
                    : `No meetings in ${monthFilter.monthLong}`}
                </EmptyStateHeading>
                <EmptyStateBody>
                  Use the calendar and filters to adjust your list view.
                </EmptyStateBody>
              </EmptyState>
            }
            onShowMeetingDetails={showMeetingDetails}
          />
        </div>
      </div>

      <MeetingDetailsDrawer
        meeting={selectedMeeting}
        onClose={() => setSelectedMeeting(undefined)}
      />
    </div>
  );
};
