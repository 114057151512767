import clsx from 'clsx';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi2';

import { buildMonth } from '@/features/calendar/utils/build-month';

import { CalendarDay } from '../types/calendar-day';

type CalendarProps = {
  current: DateTime;
  min?: string;
  max?: string;
  daysWithEvents: string[];
  onChange: (date: DateTime) => void;
  onDayClick: (date: DateTime | undefined) => void;
  className?: string;
};

export const Calendar = ({
  current,
  min,
  max,
  daysWithEvents = [],
  onChange,
  onDayClick,
  className,
}: CalendarProps) => {
  const [selectedDate, setSelectedDate] = useState<DateTime | undefined>(undefined);

  const minMonth = min ? DateTime.fromISO(min) : undefined;
  const maxMonth = max ? DateTime.fromISO(max).endOf('month') : undefined;

  const days = useMemo(() => {
    return buildMonth(current, daysWithEvents);
  }, [current, daysWithEvents]);

  const navigateMonth = (newMonth: DateTime) => () => {
    onChange(newMonth);
    setDaySelection(undefined);
  };

  const setDaySelection = (date: DateTime | undefined) => {
    setSelectedDate(date);
    onDayClick(date);
  };

  const handleDayClick = ({ date, isCurrentMonth }: CalendarDay) => () => {
    if (isCurrentMonth) {
      const selected = DateTime.fromISO(date);
      const isSelectedDate = selectedDate?.toISODate() === date;

      if (isSelectedDate) {
        setDaySelection(undefined);
      } else {
        setSelectedDate(selected);
        onDayClick(selected);
      }
    }
  };

  return (
    <div className={clsx('text-center', className)}>
      <div className="flex items-center text-gray-900">
        <CalendarNavButton
          direction="previous"
          currentMonth={current}
          navigateMonth={navigateMonth}
          edge={minMonth}
        />
        <div className="flex-auto text-base font-semibold">
          {current.toFormat('LLLL yyyy')}
        </div>
        <CalendarNavButton
          direction="next"
          currentMonth={current}
          navigateMonth={navigateMonth}
          edge={maxMonth}
        />
      </div>
      <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
        <div>SUN</div>
        <div>MON</div>
        <div>TUE</div>
        <div>WED</div>
        <div>THU</div>
        <div>FRI</div>
        <div>SAT</div>
      </div>
      <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
        {days.map((day, dayIdx) => (
          <div
            key={day.date}
            className={clsx(
              'py-1.5 hover:bg-gray-100 focus:z-10',
              day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
              (day.hasEvents || day.isToday) && 'font-semibold',
              day.hasEvents && 'text-white',
              !day.hasEvents && day.isCurrentMonth && !day.isToday && 'text-gray-900',
              !day.hasEvents && !day.isCurrentMonth && !day.isToday && 'text-gray-400',
              day.isToday && !day.hasEvents && !selectedDate && 'text-secondary-600',
              dayIdx === 0 && 'rounded-tl-lg',
              dayIdx === 6 && 'rounded-tr-lg',
              dayIdx === days.length - 7 && 'rounded-bl-lg',
              dayIdx === days.length - 1 && 'rounded-br-lg'
            )}
            onClick={handleDayClick(day)}
          >
            <time
              dateTime={day.date}
              className={clsx(
                'mx-auto flex size-7 items-center justify-center rounded-full',
                day.hasEvents && day.isToday && !selectedDate && 'bg-secondary-600',
                day.hasEvents && !day.isToday && !selectedDate && 'bg-primary-950',
                day.isCurrentMonth && selectedDate && selectedDate.toISODate() === day.date && 'bg-secondary-600 text-white'
              )}
            >
              {day.date.split('-').pop()!.replace(/^0/, '')}
            </time>
          </div>
        ))}
      </div>
    </div>
  );
};

type CalendarNavButtonProps = {
  direction: 'previous' | 'next';
  currentMonth: DateTime;
  navigateMonth: (month: DateTime) => () => void;
  edge?: DateTime;
};

const CalendarNavButton = ({
  direction,
  currentMonth,
  navigateMonth,
  edge,
}: CalendarNavButtonProps) => {
  const Icon =
    direction === 'previous' ? HiOutlineChevronLeft : HiOutlineChevronRight;
  const newMonth = currentMonth.plus({
    months: direction === 'previous' ? -1 : 1,
  });

  let canNavigate = true;
  if (edge) {
    if (direction === 'previous') {
      canNavigate = newMonth >= edge;
    } else {
      canNavigate = newMonth <= edge;
    }
  }

  return (
    <button
      type="button"
      className={clsx(
        '-m-1.5 flex flex-none items-center justify-center p-1.5',
        canNavigate ? 'text-gray-400 hover:text-gray-500' : 'text-gray-200'
      )}
      onClick={navigateMonth(newMonth)}
      disabled={!canNavigate}
    >
      <span className="sr-only">
        {direction === 'previous' ? 'Previous' : 'Next'} month
      </span>
      <Icon className="size-5" aria-hidden="true" />
    </button>
  );
};
