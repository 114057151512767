import * as Headless from '@headlessui/react';
import clsx from 'clsx';
import { HiOutlineCheck, HiOutlineChevronUpDown } from 'react-icons/hi2';

type ComboboxProps = {
  options?: any[];
  onChange: (value: any) => void;
  onQueryChange: (query?: string) => void;
  value?: any;
  valueKey: string;
  labelKey: string;
  virtualized?: boolean;
  placeholder?: string;
};

/**
 * A combobox is used for selecting a single value from a list of options. Unlike
 * a standard select control, options are typically loaded dynamically, and they
 * are constrained as the user types in their query.
 *
 * @param options The options to display in the combobox. The caller can either
 * preload the entire collection or update the collection as the user types. If
 * undefined, the combobox will be empty.
 * @param onChange Invoked when the user changes their selection.
 * @param onQueryChange Invoked as the user types, changing their query.
 * @param value The initial value for the combobox or undefined.
 * @param valueKey The string property name on the object used in {@link options}
 * and {@link value} that represents the value.
 * @param labelKey The string property name on the object used in {@link options}
 * and {@link value} that represents the label to display in the combobox.
 * @param virtualized - Whether the options in the list are virtualized to improve performance
 */
export const Combobox = ({
  options = [],
  onChange,
  onQueryChange,
  value,
  valueKey,
  labelKey,
  placeholder
}: ComboboxProps) => {
  return (
    <Headless.Combobox
      as="div"
      value={value}
      onChange={(value) => {
        onChange(value);
        onQueryChange('');
      }}
      virtual={{ options }}
      onClose={() => onQueryChange('')}
    >
      <div className="relative">
        <Headless.ComboboxInput
          placeholder={placeholder}
          className={clsx(
            // Basic layout
            'relative block w-full appearance-none rounded-md px-[calc(theme(spacing[3.5])-1px)] py-[calc(theme(spacing[2.5])-1px)] sm:px-[calc(theme(spacing[3])-1px)] sm:py-[calc(theme(spacing[1.5])-1px)]',
            // Typography
            'text-base/6 text-zinc-950 placeholder:text-zinc-500 dark:text-white sm:text-sm/6',
            // Border
            'border border-zinc-950/10 data-[hover]:border-zinc-950/20 dark:border-white/10 dark:data-[hover]:border-white/20',
            // Background color
            'bg-transparent dark:bg-white/5',
            // Hide default focus styles
            'focus:outline-none',
            // Invalid state
            'data-[invalid]:border-red-500 data-[invalid]:data-[hover]:border-red-500 data-[invalid]:dark:border-red-500 data-[invalid]:data-[hover]:dark:border-red-500',
            // Disabled state
            'data-[disabled]:border-zinc-950/20 dark:data-[hover]:data-[disabled]:border-white/15 data-[disabled]:dark:border-white/15 data-[disabled]:dark:bg-white/[2.5%]',
            // System icons
            'dark:[color-scheme:dark]'
          )}
          displayValue={(item?: any) => (item && item[labelKey]) ?? ''}
          onChange={(event) => onQueryChange(event.target.value)}
          onBlur={() => onQueryChange('')}
        />
        <Headless.ComboboxButton className="group absolute inset-y-0 right-0 px-2.5">
          <HiOutlineChevronUpDown className="size-5 text-gray-400"/>
        </Headless.ComboboxButton>

        <Headless.ComboboxOptions
          anchor={{ to: 'bottom', gap: 4 }}
          transition
          className={clsx(
            'absolute z-10 max-h-60 w-[var(--input-width)] overflow-auto rounded-md border border-zinc-950/10 bg-white p-1 text-base shadow-lg empty:invisible',
            'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
          )}
        >
          {({ option }) => (
            <Headless.ComboboxOption
              key={option[valueKey]}
              value={option}
              className="group flex w-full cursor-default select-none items-center gap-2 rounded-md px-3 py-1.5 text-gray-900 data-[focus]:bg-gray-100 data-[focus]:text-zinc-900"
            >
              <HiOutlineCheck className="invisible size-4 text-zinc-600 group-data-[selected]:visible"/>
              <div className="text-sm/6">{option[labelKey]}</div>
            </Headless.ComboboxOption>
          )}
        </Headless.ComboboxOptions>
      </div>
    </Headless.Combobox>
  );
};
