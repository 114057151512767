import { isEqual } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * A custom hook that manages a value which can be either controlled or uncontrolled.
 */
export const useControllable = <TValue>(
  controlledValue: TValue | undefined,
  onChange?: (value: TValue | undefined) => void,
  defaultValue?: TValue,
) => {
  const [internalValue, setInternalValue] = useState<TValue | undefined>(defaultValue);

  const isControlledRef = useRef(controlledValue !== undefined);
  const isControlled = controlledValue !== undefined;

  const defaultValueRef = useRef(defaultValue);

  const value = isControlled ? controlledValue : internalValue;

  const setValue = useCallback((newValue: TValue | undefined) => {
    if (isControlled) {
      // If controlled, trigger onChange handler
      onChange?.(newValue);
    } else {
      // If uncontrolled, update internal state
      setInternalValue(newValue);
      onChange?.(newValue);
    }
  }, [isControlled, onChange]);

  // Effect to sync internal state when defaultValue changes
  useEffect(() => {
    if (!isControlledRef.current && defaultValue !== undefined && !isEqual(defaultValue, defaultValueRef.current)) {
      setInternalValue(defaultValue);
    }
  }, [defaultValue]);

  return [value, setValue] as const;
};


