import { useMutation, useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import { NotificationSetting } from '@/features/user-settings/types/notification-setting';
import { UserSettings } from '@/features/user-settings/types/user-settings';
import { artiaProtectedApi } from '@/lib/api-client';
import { MutationConfig } from '@/lib/react-query';
import useErrorHandler from '@/shared/errors/use-error-handler';
import { DraftCoverageSet } from '@/features/draft-coverage/api/types';

export const resetUserPassword = async (): Promise<void> => {
  await artiaProtectedApi.post('users/me/reset-password');
};

const getUserSettings = (): Promise<UserSettings> => {
  return artiaProtectedApi.get('user-settings');
};

export const useUserSettings = () => {
  return useSuspenseQuery({
    queryKey: ['user-settings'],
    queryFn: async () => getUserSettings()
  });
};

// USER SETTINGS
type UpdateUserSettingsRequest = {
  name?: string;
  priorityStates?: string[];
}

const updateUserSettings = async (userSettings: UpdateUserSettingsRequest): Promise<void> => {
  const response = await artiaProtectedApi.put('user-settings', {
    name: userSettings.name,
    priorityStates: userSettings.priorityStates
  });

  return response.data;
};

type UseUpdateUserSettingsOptions = {
  mutationConfig?: MutationConfig<typeof updateUserSettings>;
};

export const useUpdateUserSettings = ({ mutationConfig }: UseUpdateUserSettingsOptions = {}) => {
  const { handleError } = useErrorHandler();
  const queryClient = useQueryClient();
  const { onSuccess, onError, ...restConfig } = mutationConfig ?? {};

  return useMutation({
    mutationFn: updateUserSettings,
    ...restConfig,
    onSuccess: async (...args) => {
      onSuccess?.(...args);
      await queryClient.invalidateQueries({ queryKey: ['calendar'] });
      await queryClient.invalidateQueries({ queryKey: ['me'] });
      await queryClient.invalidateQueries({ queryKey: ['user-settings'] });
    },
    onError: (...args) => {
      const error = args[0];
      onError?.(...args);
      handleError(error, { title: 'Failed to update user settings' });
    },
  });
};

// NOTIFICATION SETTINGS
const getUserNotificationSettings = (): Promise<NotificationSetting[]> => {
  return artiaProtectedApi.get('user-settings/notifications');
};

export const useUserNotificationSettings = () => {
  return useQuery({
    queryKey: ['notification-settings'],
    queryFn: async () => await getUserNotificationSettings()
  });
};

export const useUpdateNotificationSettings = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (notificationSettings: NotificationSetting[]) => await artiaProtectedApi.put('user-settings/notifications', notificationSettings),
    onMutate: (notificationSettings) => {
      queryClient.setQueryData<NotificationSetting[]>(['notification-settings'], notificationSettings);
      return { notificationSettings };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['me'] });
      queryClient.invalidateQueries({ queryKey: ['notification-settings'] });
    },
    onError: () => {
      return useUserNotificationSettings;
    }
  }
  );
};
export const getDraftCoverageSet = async (id: number): Promise<DraftCoverageSet> => {
  return await artiaProtectedApi.get(`/draft-coverage-sets/${id}`);
};
