import { ProtectedRoute } from '@shared/auth';
import { isNil } from 'lodash';

import { AutoSignIn } from '@/components-new/auto-sign-in';
import { DashboardLayout } from '@/components-new/layouts';
import { Seo } from '@/components-new/seo';
import { ArtiaUserHome } from '@/features/home/artia-user-home';
import { ClientUserHome } from '@/features/home/client-user-home';
import { WelcomeMessage } from '@/features/home/welcome-message';
import { useCurrentUser } from '@/hooks/use-current-user';

const HomePage = () => {
  const { hasPolicies, user } = useCurrentUser();
  const isClientUser = !isNil(user.client?.id);

  return (
    <DashboardLayout user={user}>
      <Seo title="Home"/>
      <div className="flex flex-col gap-6">
        <WelcomeMessage user={user} isClientUser={isClientUser}/>
        {hasPolicies(['isAnyArtiaUser']) && <ArtiaUserHome/>}
        {isClientUser && <ClientUserHome user={user}/>}
      </div>
    </DashboardLayout>
  );
};

const HomePageContainer = () => {
  return (
    <AutoSignIn>
      <ProtectedRoute>
        <HomePage/>
      </ProtectedRoute>
    </AutoSignIn>
  );
};

export default HomePageContainer;
