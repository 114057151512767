import { flexRender } from '@tanstack/react-table';
import { isEmpty, isNil } from 'lodash';
import { useMemo, useState } from 'react';
import { FaFilter } from 'react-icons/fa6';
import { HiMagnifyingGlass } from 'react-icons/hi2';

import { useGetPoolsLookups } from '@/api/use-pools';
import { useGetStateLookups } from '@/api/use-states';
import { Button } from '@/components-new/button';
import { Checkbox, CheckboxField, CheckboxGroup } from '@/components-new/checkbox';
import { Divider } from '@/components-new/divider';
import { Fieldset, Label, Legend } from '@/components-new/fieldset';
import { ActiveFilter, AddFilterButton, Filter, FilterPanel, FilterPanelContent, FilterPanelContents, FilterPanelSection, FilterPanelSections, FiltersBar, ResetFiltersButton } from '@/components-new/filter';
import { FilterSectionContext } from '@/components-new/filter/use-filter';
import { IndeterminateToggleButton } from '@/components-new/indeterminate';
import { Input, InputGroup } from '@/components-new/input';
import { displayPdlStatus, PdlStatus } from '@/features/coverage/types/pdl-status';
import { DrugInfoFilterSchema, useDrugInfoFilters } from '@/features/drugs/hooks/use-drug-info-filters';
import { useDebounce } from '@/hooks/use-debounce';
import { CodeBasedLookup } from '@/types/lookup';

export const StateFilter = ({ value, setValue, removeValue }: FilterSectionContext<DrugInfoFilterSchema, 'state'>) => {
  const {
    data: stateLookups,
    isLoading: isLoadingStateLookups,
  } = useGetStateLookups();

  const [searchValue, setSearchValue] = useState('');
  const search = useDebounce(searchValue, 100);

  const options = useMemo(() => {
    if (isNil(stateLookups)) return [];
    if (isEmpty(search)) return stateLookups;

    return stateLookups.filter(option =>
      option.label.toLowerCase().includes(search.toLowerCase()));
  }, [search, stateLookups]);

  return (
    <div className="flex flex-col gap-2">
      <InputGroup>
        <HiMagnifyingGlass/>
        <Input
          name="search"
          placeholder="Search for state&hellip;"
          aria-label="Search"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </InputGroup>
      <Fieldset>
        <Legend className="flex items-center justify-between">
          State
          <IndeterminateToggleButton
            options={options}
            selectedOptions={value}
            onChange={(checked) => { isEmpty(checked) ? removeValue() : setValue(checked); }}
          />
        </Legend>
        <Divider/>
        <CheckboxGroup role="group" aria-label="Discoverability">
          {options.map((option) => (
            <CheckboxField key={option.code}>
              <Checkbox
                name="state"
                checked={!!value?.find(value => value.code === option.code)}
                onChange={(checked) => {
                  setValue(
                    checked
                      ? [...value ?? [], option]
                      : (value ?? []).filter((value) => value.code !== option.code)
                  );
                }}
              />
              <Label>{option.label}</Label>
            </CheckboxField>
          ))}
          {options.length === 0 && !isLoadingStateLookups && (
            <div className="text-gray-500">
              No states match your search.
            </div>
          )}
          {isLoadingStateLookups && (
            <div className="text-gray-500">
              Loading states&hellip;
            </div>
          )}
        </CheckboxGroup>
      </Fieldset>
    </div>
  );
};

export const PoolsFilter = ({ value, setValue, removeValue }: FilterSectionContext<DrugInfoFilterSchema, 'state'>) => {
  const {
    data: poolLookups,
    isLoading: isLoadingPoolLookups,
  } = useGetPoolsLookups();

  const [searchValue, setSearchValue] = useState('');
  const search = useDebounce(searchValue, 100);

  const options = useMemo(() => {
    if (isNil(poolLookups)) return [];
    if (isEmpty(search)) return poolLookups;

    return poolLookups.filter(option =>
      option.label.toLowerCase().includes(search.toLowerCase()));
  }, [search, poolLookups]);

  return (
    <div className="flex flex-col gap-2">
      <InputGroup>
        <HiMagnifyingGlass/>
        <Input
          name="search"
          placeholder="Search for Pools&hellip;"
          aria-label="Search"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </InputGroup>
      <Fieldset>
        <Legend className="flex items-center justify-between">
          Pool
          <IndeterminateToggleButton
            options={options}
            selectedOptions={value}
            onChange={(checked) => { isEmpty(checked) ? removeValue() : setValue(checked); }}
          />
        </Legend>
        <Divider/>
        <CheckboxGroup role="group" aria-label="Discoverability">
          {options.map((option) => (
            <CheckboxField key={option.code}>
              <Checkbox
                name="pool"
                checked={!!value?.find(value => value.code === option.code)}
                onChange={(checked) => {
                  setValue(
                    checked
                      ? [...value ?? [], option]
                      : (value ?? []).filter((value) => value.code !== option.code)
                  );
                }}
              />
              <Label>{option.label}</Label>
            </CheckboxField>
          ))}
          {options.length === 0 && !isLoadingPoolLookups && (
            <div className="text-gray-500">
              No pools match your search.
            </div>
          )}
          {isLoadingPoolLookups && (
            <div className="text-gray-500">
              Loading pools&hellip;
            </div>
          )}
        </CheckboxGroup>
      </Fieldset>
    </div>
  );
};
// TODO: Move to pull from API
const PDL_STATUS_OPTIONS: CodeBasedLookup[] = [
  { code: PdlStatus.BLANK, label: displayPdlStatus(PdlStatus.BLANK) },
  { code: PdlStatus.PREFERRED, label: displayPdlStatus(PdlStatus.PREFERRED) },
  { code: PdlStatus.NON_PREFERRED, label: displayPdlStatus(PdlStatus.NON_PREFERRED) },
  { code: PdlStatus.NON_PDL, label: displayPdlStatus(PdlStatus.NON_PDL) },
  { code: PdlStatus.NO_STATE_PDL, label: displayPdlStatus(PdlStatus.NO_STATE_PDL) },
  { code: PdlStatus.PREFERRED_NOT_LISTED, label: displayPdlStatus(PdlStatus.PREFERRED_NOT_LISTED) },
  { code: PdlStatus.NON_PREFERRED_NOT_LISTED, label: displayPdlStatus(PdlStatus.NON_PREFERRED_NOT_LISTED) },
];


export const PDLStatusFilter = ({ value, setValue, removeValue }: FilterSectionContext<DrugInfoFilterSchema, 'pdlStatus'>) => {
  return (
    <Fieldset>
      <Legend className="flex items-center justify-between">
        PDL Status
        <IndeterminateToggleButton
          options={PDL_STATUS_OPTIONS}
          selectedOptions={value}
          onChange={(checked) => { isEmpty(checked) ? removeValue() : setValue(checked); }}
        />
      </Legend>
      <Divider/>
      <CheckboxGroup role="group" aria-label="Discoverability">
        {PDL_STATUS_OPTIONS.map((option) => (
          <CheckboxField key={option.code}>
            <Checkbox
              name="pdlStatus"
              checked={!!value?.find(value => value.code === option.code)}
              onChange={(checked) => {
                setValue(
                  checked
                    ? [...value ?? [], option]
                    : (value ?? []).filter((value) => value.code !== option.code)
                );
              }}
            />
            <Label>{option.label}</Label>
          </CheckboxField>
        ))}
      </CheckboxGroup>
    </Fieldset>
  );
};

// TODO: Move to pull from API
const PA_TYPE_OPTIONS: CodeBasedLookup[] = [
  { code: 'GeneralPdl', label: 'General PDL' },
  { code: 'GeneralNonPdl', label: 'General Non-PDL' },
  { code: 'ClassSpecific', label: 'Class-Specific' },
  { code: 'DrugSpecific', label: 'Drug-Specific' },
  { code: 'Oncology', label: 'Oncology' },
];

export const PATypeFilter = ({ value, setValue, removeValue }: FilterSectionContext<DrugInfoFilterSchema, 'paType'>) => {
  return (
    <Fieldset>
      <Legend className="flex items-center justify-between">
        PA Type
        <IndeterminateToggleButton
          options={PA_TYPE_OPTIONS}
          selectedOptions={value}
          onChange={(checked) => { isEmpty(checked) ? removeValue() : setValue(checked); }}
        />
      </Legend>
      <Divider/>
      <CheckboxGroup role="group" aria-label="Discoverability">
        {PA_TYPE_OPTIONS.map((option) => (
          <CheckboxField key={option.code}>
            <Checkbox
              name="paType"
              checked={!!value?.find(value => value.code === option.code)}
              onChange={(checked) => {
                setValue(
                  checked
                    ? [...value ?? [], option]
                    : (value ?? []).filter((value) => value.code !== option.code)
                );
              }}
            />
            <Label>{option.label}</Label>
          </CheckboxField>
        ))}
      </CheckboxGroup>
    </Fieldset>
  );
};

export const DrugInfoFilters = ({
  filter,
}: { filter: ReturnType<typeof useDrugInfoFilters>['filter'] }) => {
  return (
    <Filter filter={filter}>
      <AddFilterButton as={Button} outline>
        <FaFilter/>
        Add Filter
      </AddFilterButton>
      <FilterPanel>
        <FilterPanelSections>
          {filter.resolvedFilterSections.map(section => (
            <FilterPanelSection key={section.id}>
              {flexRender(section.sectionLabel, section.getContext())}
            </FilterPanelSection>
          ))}
        </FilterPanelSections>
        <FilterPanelContents>
          {filter.resolvedFilterSections.map(section => (
            <FilterPanelContent key={section.id}>
              {flexRender(section.sectionContent, section.getContext())}
            </FilterPanelContent>
          ))}
        </FilterPanelContents>
      </FilterPanel>
      <FiltersBar>
        {filter.values.map(value => {
          const section = filter.getSection(value.key)!;

          return (
            <ActiveFilter
              key={value.key}
              onClick={() => filter.setSelectedSection(value.key)}
              onDismiss={() => filter.removeFilterValue(value.key)}
            >
              {flexRender(section.filterLabel, section.getContext())}
            </ActiveFilter>
          );
        })}
        {filter.values.length > 0 && (
          <ResetFiltersButton onClick={filter.clearFilterValue}>
            Reset Filters
          </ResetFiltersButton>
        )}
        {
          filter.values.length === 0 && (
            <>No active filters</>
          )
        }
      </FiltersBar>
    </Filter>
  );
};
