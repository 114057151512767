import { Box } from 'grommet';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useBoardMeetingsProvider } from './board-meetings-provider';
import { MeetingLocationSection } from './meeting-location-section';
import { MeetingPurposeSection } from './meeting-purpose-section';
import { MeetingTimeSection } from './meeting-time-section';
import { BoardMeetingFormData } from './types';

type BoardMeetingFormProps = {
  /**
   * ID of the <form> element. Required to associate a submit button with this form.
   */
  id: string;

  /**
   * Initial values for the form, optional. Will default to an empty form if not provided.
   */
  defaultValues?: BoardMeetingFormData;

  /**
   * Submit event handler
   * @param form the data in the form
   */
  onSubmit: (form: BoardMeetingFormData) => void;
};

/**
 * Form that allows you to add/edit a Board Meeting.
 */
export const BoardMeetingForm = (props: BoardMeetingFormProps) => {
  const {
    id,
    defaultValues = {
      state: null,
      meetingTypeId: null,
      startDate: '',
      startTime: '',
      endTime: '',
      ianaTimeZone: null,
      format: null,
      venueName: '',
      meetingRoom: '',
      locationStreetLine1: '',
      locationStreetLine2: '',
      locationCity: '',
      locationState: null,
      locationZipCode: '',
      locationNotes: '',
      locationInternalNotes: '',
      webinarLink: '',
      webinarPhone: '',
      webinarMeetingId: '',
      webinarPassword: '',
      agendaLink: '',
      meetingMinutesLink: '',
      members: '',
      recordingLink: '',
      speakerPoliciesLink: '',
      discussedClassifications: [],
      discussedDrugs: []
    },
    onSubmit
  } = props;

  const { states, meetingTypes } = useBoardMeetingsProvider();

  const {
    control,
    handleSubmit,
    watch,
    setValue
  } = useForm<BoardMeetingFormData>({ defaultValues });

  useEffect(() => {
    const subscription = watch(({ state, meetingTypeId }, info) => {
      if (info.type !== 'change') return;

      // if state changes at any point, update the timezone
      if (info.name === 'state') {
        const foundState = states.find(it => it.code === state);
        if (!foundState?.defaultTimezone) return;

        setValue('ianaTimeZone', foundState.defaultTimezone);
      }

      // if state OR board type change, update the meeting location fields
      if (info.name === 'state' || info.name === 'meetingTypeId') {
        const foundState = states.find(it => it.code === state);
        if (!foundState || !meetingTypeId) return;

        const {
          format = null,
          venueName = null,
          meetingRoom = null,
          locationStreetLine1 = null,
          locationStreetLine2 = null,
          locationCity = null,
          locationState = null,
          locationZipCode = null,
          locationNotes = null,
          locationInternalNotes = null,
          webinarLink = null,
          webinarPhone = null,
          webinarMeetingId = null,
          webinarPassword = null,
          agendaLink = null,
          meetingMinutesLink = null,
          members = null,
          recordingLink = null,
          speakerPoliciesLink = null,
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        } = foundState.boards[meetingTypeId] ?? {};

        setValue('format', format ?? null);
        setValue('venueName', venueName ?? '');
        setValue('meetingRoom', meetingRoom ?? '');
        setValue('locationStreetLine1', locationStreetLine1 ?? '');
        setValue('locationStreetLine2', locationStreetLine2 ?? '');
        setValue('locationCity', locationCity ?? '');
        setValue('locationState', locationState?.code ?? null);
        setValue('locationZipCode', locationZipCode ?? '');
        setValue('locationNotes', locationNotes ?? '');
        setValue('locationInternalNotes', locationInternalNotes ?? '');
        setValue('webinarLink', webinarLink ?? '');
        setValue('webinarPhone', webinarPhone ?? '');
        setValue('webinarMeetingId', webinarMeetingId ?? '');
        setValue('webinarPassword', webinarPassword ?? '');
        setValue('agendaLink', agendaLink ?? '');
        setValue('meetingMinutesLink', meetingMinutesLink ?? '');
        setValue('members', members ?? '');
        setValue('recordingLink', recordingLink ?? '');
        setValue('speakerPoliciesLink', speakerPoliciesLink ?? '');
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue, states, meetingTypes]);

  return (
    <Box id={id} as="form" fill="horizontal" gap="medium" onSubmit={handleSubmit(onSubmit)}>
      <MeetingPurposeSection id={id} control={control} />
      <MeetingTimeSection id={id} control={control} />
      <MeetingLocationSection id={id} control={control} />
    </Box>
  );
};
