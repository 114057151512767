import { Row } from '@tanstack/react-table';
import { isArray } from 'lodash';

import { IdBasedLookup } from '@/types/lookup';

type ValidUrlValue = string | number | boolean;

/**
 * Creates a query string from an object, which must only contain fields that are:
 * - a boolean
 * - a string (an empty string or just whitespace will not be added)
 * - a number (NaN will not be added)
 * - an array of one of the above types
 *
 * Any other values will NOT be added to the query string.
 *
 * It doesn't support nested objects.
 * This uses URLSearchParams internally.
 *
 * @param request the object to create a query string from
 * @returns if any query parameters should be added, a properly formatted query string that starts with a '?'.
 * Otherwise, an empty string
 */
export const buildQueryString = <T extends Record<string, ValidUrlValue | ValidUrlValue[] | null | undefined>>(request: T) => {
  const urlSearchParams = new URLSearchParams();

  for (const key in request) {
    const value = request[key];

    if (value === null || value === undefined) {
      continue;
    }

    if (Array.isArray(value)) {
      for (const it of value) {
        if (isValidQueryStringValue(it)) {
          urlSearchParams.append(key, `${it}`);
        }
      }
    } else if (isValidQueryStringValue(value)) {
      urlSearchParams.append(key, `${value}`);
    }
  }

  const result = urlSearchParams.toString();

  return result ? '?' + result : '';
};

const isValidQueryStringValue = (value: unknown): value is ValidUrlValue => {
  return (typeof value === 'string' && value.trim() !== '')
    || (typeof value === 'number' && !isNaN(value))
    || typeof value === 'boolean';
};

/**
 * Filter function for handling array-based filters where the value should match one of the filter values
 */
export const arrayFilterFn = <T>(row: Row<T>, columnId: string, filterValue: any) => {
  if (!isArray(filterValue)) return true;
  if (filterValue.length === 0) return true;

  const value = row.getValue(columnId);
  return filterValue.includes(value);
};

/**
 * Filter function for handling array of objects with labels where the label should match one of the filter values
 */
export const arrayOfLookupFilterFn = <T>(row: Row<T>, columnId: string, filterValue: string[]) => {
  const value = row.getValue(columnId) as IdBasedLookup[];

  return value
    .map(item => item.label)
    .some(label => filterValue.includes(label));
};
