import { useMemo } from 'react';

import { InMemoryMultiSelectList } from '@/components/form-controls';
import { MultiSelectOption } from '@/components/form-controls/multi-select-list';
import { State } from '@/types/state';

type StateFilterProps = {
  /**
   * The list of States to choose from, required.
   */
  states: State[];

  /**
   * The list of currently-selected options, required.
   */
  selected: MultiSelectOption<string>[];

  /**
   * Event handler for updating the current selection.
   * @param selected the new value of the multi-select
   */
  onSelectionChange: (selected: MultiSelectOption<string>[]) => void;
};

/**
 * Search filter component that lets the user select multiple states
 */
export const StateFilter = ({ states, selected, onSelectionChange }: StateFilterProps) => {
  const stateOptions = useMemo<MultiSelectOption<string>[]>(() => {
    return [
      ...(states?.map((state) => ({ label: state.name, id: state.code })) ?? [])
    ];
  }, [states]);

  return (
    <InMemoryMultiSelectList
      placeholder="Search by State"
      options={stateOptions}
      value={selected}
      onChange={onSelectionChange}
    />
  );
};
