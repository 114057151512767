import { Outlet } from 'react-router';

import { ProtectedRoute } from '@/shared/auth';

const DrugCoverageLayout = () => {
  return (
    <ProtectedRoute policies={['isAnyArtiaUser']}>
      <Outlet/>
    </ProtectedRoute>
  );
};

export default DrugCoverageLayout;
