import React, { startTransition, useEffect, useMemo, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

type Tab = (
  { label: React.ReactNode; disabled?: boolean } &
  ( | { href: string } ) &
  /**
   * If true, the tab will use React's startTransition to navigate to the tab's
   * href. Use this in cases where you are navigating to a page that must
   * suspend while loading.
   */
  ( | { transition?: boolean } )
)

export const useTabs = (tabs: Tab[]) => {
  const navigate = useNavigate();
  const location = useLocation();
  const tabByHref = useMemo(() => {
    return tabs.findIndex(tab => matchPath(location.pathname, tab.href));
  }, [location.pathname, tabs]);

  const [ activeTabIndex, setActiveTabIndex ] = useState(tabByHref);

  useEffect(() => {
    if (tabByHref !== -1) {
      setActiveTabIndex(tabByHref);
    }
  }, [tabByHref]);

  const handleOnChange = (index: number) => {
    const tab = tabs[index];
    setActiveTabIndex(index);

    if ('href' in tab) {
      if (tab.transition) {
        startTransition(() => {
          navigate(tab.href);
        });
      } else {
        navigate(tab.href);
      }
    }
  };

  return {
    tabs,
    activeTabIndex,
    onChange: handleOnChange
  };
};
